import { VisibilityOffTwoTone, VisibilityTwoTone } from '@mui/icons-material';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
import { ClipLoader } from 'react-spinners'



const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [PassbtnDisable, setPassbtnDisable] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [password, setpassword] = useState('')



    const [showPassword2, setShowPassword2] = useState(false);
    const [password2, setpassword2] = useState('')



    const [showPassword3, setShowPassword3] = useState(false);
    const [password3, setpassword3] = useState('')




    const SubmitPasswordData = async (e: any) => {
        setPassbtnDisable(true)
        e.preventDefault();
        const loginData = {
            "current_password": oldPassword,
            "new_password": newPassword,
            "confirm_new_password": confirmPassword
        }

        var d = await _fetch(`${api_url}user/changePassword/`, 'POST', loginData);
        if (d?.status === 'success') {
            toasted.success(d?.message);
            setPassbtnDisable(false)
        }
        else {
            toasted.error(d?.message);
            setPassbtnDisable(false)
        }
    }
    return (
        <>
            <div className='password-change'>
                <div className='password-section'>

                    <div className="container">
                        <div className='card border-0 form-change-pwd'>
                            <div className='heading-sec'>

                                <h2>Change Password</h2>

                            </div>
                            <Form>

                                <div className="form-step-fields form-step-fields-flex ">
                                    <div className="note w-100">
                                        <label htmlFor='fname'> Current Password</label>
                                        {/* <Form.Control type="text" placeholder="Current Password" id="fname" required /> */}
                                        <div className='MAIn__CONA'>
                                            <input type={showPassword ? "text" : "password"} className='form-control' placeholder='Current Password' name="Password" onChange={(e: any) => { setpassword(e.target.value); setOldPassword(e.target.value) }} value={oldPassword} required />
                                            <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                {showPassword ?
                                                    <VisibilityTwoTone />
                                                    :
                                                    <VisibilityOffTwoTone />
                                                }
                                            </button>
                                        </div>
                                        {/* <Form.Control type="text" placeholder="Current Password" id="fname" required onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword} /> */}
                                    </div>


                                </div>
                                <div className="form-step-fields form-step-fields-flex ">
                                    <div className="note w-100">
                                        <label htmlFor='fname'> New Password</label>
                                        <div className='MAIn__CONA'>
                                            <input type={showPassword2 ? "text" : "password"} className='form-control' placeholder='New Password' name="Password" onChange={(e: any) => { setpassword2(e.target.value); setNewPassword(e.target.value) }} value={newPassword} required />
                                            <button type="button" className='passwod_btn' onClick={() => { setShowPassword2(!showPassword2) }}>
                                                {showPassword2 ?
                                                    <VisibilityTwoTone />
                                                    :
                                                    <VisibilityOffTwoTone />
                                                }
                                            </button>
                                        </div>
                                        {/* <Form.Control type="text" placeholder="New Password" id="fname" required  onChange={(e) => { setNewPassword(e.target.value) }} value={newPassword}/> */}
                                    </div>


                                </div>

                                <div className="form-step-fields form-step-fields-flex ">
                                    <div className="note w-100">
                                        <label htmlFor='fname'> Confirm New Password</label>
                                        <div className='MAIn__CONA'>
                                            <input type={showPassword3 ? "text" : "password"} className='form-control' placeholder="Confirm New Password" name="Password" onChange={(e: any) => { setpassword(e.target.value); setConfirmPassword(e.target.value) }} value={confirmPassword} required />
                                            <button type="button" className='passwod_btn' onClick={() => { setShowPassword3(!showPassword3) }}>
                                                {showPassword3 ?
                                                    <VisibilityTwoTone />
                                                    :
                                                    <VisibilityOffTwoTone />
                                                }
                                            </button>
                                        </div>
                                        {/* <Form.Control type="text" placeholder="Confirm New Password" id="fname" required  onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} /> */}
                                    </div>


                                </div>
                                <div className='change-btn  mt-4 mb-4'>
                                    <div className='btn-pwd'>   <button className=' btn-success' type='button' onClick={SubmitPasswordData}>
                                        {PassbtnDisable === true &&
                                            <div className="color-ring">
                                                <ClipLoader color="#36d7b7" />

                                                Please Wait...
                                            </div>
                                        }
                                        {PassbtnDisable === false ? 'Change Password ' : ''}

                                    </button></div>
                                   

                                </div>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ChangePassword;