import React, { useEffect, useState } from "react";
import { ArrowBackIosNew, KeyboardArrowRight, TextSnippet, ContentCopy, SearchOff } from "@mui/icons-material";
import 'react-responsive-modal/styles.css';
import Select from 'react-select';
import { Button, } from "react-bootstrap";
import { Link } from "react-router-dom";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import toasted from "../../config/toast";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ClipLoader } from 'react-spinners'


const Withdraw = () => {
  // show all address details 
  const [btnDisable, setbtnDisable] = useState(false);
  const [withdrawbtnDisable, setwithdrawbtnDisable] = useState(false);

  const [open, setOpen] = useState(false);
  // const openModal = () => setOpen(true);
  const openModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false);

  // add new address modal 
  const [openForm, setOpenFrom] = useState(false);
  const openModalForm = () => setOpenFrom(true);
  const onCloseModalFrom = () => setOpenFrom(false);


  const [selectedOption, setSelectedOption] = useState({ total_coin: 0 });
  const [coinOption, setCoinOption] = useState([]);
  const [address, setAddress] = useState();
  const [network, setNetwork] = useState();
  const [allAddress, setallAddress] = useState([]);

  const [createAddress, setCreateAddress] = useState();
  const [createAddressChain, setCreateAddressChain] = useState();
  const [createLabel, setcreateLabel] = useState();
  const [createUniversalStatus, SetCreateUniversalStatus] = useState(false);

  const [remark, setRemark] = useState();
  const [getSecrityOtp, setgetSecrityOtp] = useState();

  const [timer, settimer] = useState('00:00')
  const [otpBtn, setotpBtn] = useState('')
  const [amount, setAmount] = useState();
  const [userInfo, setUserInfo] = useState();

  const auth = localStorage.getItem("auth");
  const SetLinkBg = () => {
    if (auth !== "true") {
      return (
        <>
          <Link to="/" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
        </>
      )
    }
    else {
      return (
        <>
          <Link to="/dashboard" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
        </>
      )
    }
  }

  async function getUserInfo() {
    var d = await _fetch(`${api_url}user/userInfo`, 'GET');
    if (d?.status === 'success') {
      setUserInfo(d?.data?.data);
    }
  }


  async function getphone2faOtp(value) {
    var d = await _fetch(`${api_url}/auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET');
    if (d?.status === 'success') {
      toasted.success(d?.message);
      setotpBtn('resend');
      if (timer === '00.00') {
        var seconds = '180';
        var intervalId = window.setInterval(() => {
          seconds--;
          let minutes = Math.floor(seconds / 60);
          let extraSeconds = seconds % 60;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
          var leftData = minutes + ":" + extraSeconds;

          settimer(leftData);
          if (seconds == 0) {
            stop();
          }
        }, 1000);
      }

      let stop = () => clearInterval(intervalId);
    }

  }



  const fixAmount = (e) => {
    if (e.target.value > 0) {
      const float = Number(parseFloat(e.target.value).toFixed(3));
      setAmount(float);
    }
    else {
      setAmount(e.target.value);
    }
  }

  const handleChange = (e) => {
    setSelectedOption(e);
    if (e.bep20.status === true) {
      setNetwork('BEP20');
      walletInfo('BEP20');
    }
    else if (e.trc20.status === true) {
      setNetwork('TRC20');
      walletInfo('TRC20');
    }
    else if (e.erc20.status === true) {
      setNetwork('ERC20');
      walletInfo('ERC20');
    }
  }

  async function walletInfo(data) {
    var wInfo = await _fetch(`${api_url}withdraw/getWallets?network=${data}`, 'GET', {}, {});
    if (wInfo?.status === 'success') {
      setallAddress(wInfo.data.data);
    }
  }

  const onSubmitAddAddress = async (e) => {
    setbtnDisable(true)
    e.preventDefault();
    var data = {
      'wallet_address': createAddress,
      'network': createAddressChain,
      'label': createLabel,
      'universal_status': createUniversalStatus
    };
    let d = await _fetch(`${api_url}withdraw/addWallet`, 'POST', data);
    if (d?.status === 'success') {
      toasted.success(d?.message);
      setOpen(false);
      walletInfo(data.network);
      setbtnDisable(false)
    }
    else {
      setbtnDisable(false)
      toasted.error(d?.message);
    }
  }

  const copyToClipboardId = (data) => {
    navigator.clipboard.writeText(data);
    toasted.success('Wallet Address Copied Successfully!');
  }


  async function getMarket() {
    setCoinOption([]);
    let data = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=main_account`, 'GET');
    if (data?.status === 'success') {
      const selectedValue = data?.market?.[0];
      if (selectedOption === undefined) {
        setSelectedOption({
          value: selectedValue?.symbol,
          text: <span className="new__a"> {selectedValue?.symbol.toUpperCase()}</span>,
          icon: <img src={selectedValue?.image} className="option-img-a__A" />,
          total_coin: selectedValue?.total_coin,
          coinID: selectedValue?.coin_id,
          bep20: selectedValue?.bep20,
          trc20: selectedValue?.trc20,
          erc20: selectedValue?.erc20
        });

        handleChange({
          value: selectedValue?.symbol,
          text: <span className="new__a"> {selectedValue?.symbol.toUpperCase()}</span>,
          icon: <img src={selectedValue?.image} className="option-img-a__A" />,
          total_coin: selectedValue?.total_coin,
          coinID: selectedValue?.coin_id,
          bep20: selectedValue?.bep20,
          trc20: selectedValue?.trc20,
          erc20: selectedValue?.erc20
        });

      }

      data?.market?.map((value) => {
        let newValue = {
          value: value?.symbol,
          text: <span className="new__a"> {value?.symbol.toUpperCase()}</span>,
          icon: <img src={value?.image} className="option-img-a__A" />,
          total_coin: value?.total_coin,
          coinID: value?.coin_id,
          bep20: value?.bep20,
          trc20: value?.trc20,
          erc20: value?.erc20
        };

        setCoinOption(elpMarket => [...elpMarket, newValue]);

      });
    }
  }
  useEffect(() => {
    getMarket();
    getUserInfo();
  }, []);

  const submitWithdrawRequest = async (e) => {
    setwithdrawbtnDisable(true)
    e.preventDefault();
    var data = {
      "amount": amount,
      "wallet_address": address,
      "network": network,
      "chain_id": selectedOption?.coinID,
      "symbol": selectedOption?.value,
      "user_remark": remark,
      "otp": getSecrityOtp
    };
    let d = await _fetch(`${api_url}withdraw/withdrawRequest`, 'POST', data);
    if (d?.status === 'success') {
      toasted.success(d?.message);
      setwithdrawbtnDisable(false)

    }
    else {
      setwithdrawbtnDisable(false)
      toasted.error(d?.message);
    }
  }

  return (
    <>
      <div className="main-deposit">
        <div className="inner-crum coustom_container">
          <div className="inner-crum_dc">
            <div className="inner-crum-left">
              <span> {SetLinkBg()} </span>
              <h3>Withdraw</h3>
            </div>
            <Link to="/dashboard/withdraw-history" className="More__links"> Withdraw History</Link>
          </div>
        </div>

        <div className="form-sec">
          <div className="inner-sec coustom_container">
            {/* <div className="left-sec d-none">
              <div className="coin-select">
                <h3>Select Coin</h3>
              </div>
              <div className="withdraw-sec">
                <h3>Send To</h3>
              </div>
              <div className="withdraw-amnt">
                <h3>Withdrawl Amount</h3>
              </div>
              <div className="recieve-sec">
                <h3>Receive Amount</h3>
              </div>
            </div> */}

            <div className="middle-sec">
              <div className="form-main">
                <div className="select-coin">
                  <span>Coin</span>
                  <div className="select-coin2">
                    <Select
                      placeholder="Select option"
                      value={selectedOption}
                      className="select-option-s"
                      options={coinOption}
                      onChange={handleChange}
                      getOptionLabel={(e) => (
                        <div style={{ display: 'flex', alignItems: 'center', background: 'transparent', }} className="mian-select-2p2p" >
                          {e.icon}
                          <span style={{ marginLeft: '5px' }}>{e.text}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="address-sec">
                  <div>Address</div>
                  <div className="address-form">
                    <div><input type="text" placeholder="Enter Address" onChange={(e) => { setAddress(e.target.value) }} value={address} />
                    </div>
                    <div><TextSnippet className="icon-mui" onClick={() => openModal()} />
                    </div>
                  </div>
                </div>

                <div className="select-network">
                  <span>Network</span>
                  <div className="select-network2">
                    <select placeholder="Select Network" onChange={(e) => { setNetwork(e.target.value); walletInfo(e.target.value) }} >

                      {selectedOption?.bep20?.status === false && selectedOption?.trc20?.status === false && selectedOption?.erc20?.status === false && <option>Network Closed</option>}
                      {selectedOption?.bep20?.status === true && <option value={'BEP20'}>BEP20</option>}
                      {selectedOption?.trc20?.status === true && <option value={'TRC20'}>TRC20</option>}
                      {selectedOption?.erc20?.status === true && <option value={'ERC20'}>ERC20</option>}
                    </select>
                    {/* <select placeholder="Select Network">
                          <option>TRX</option>
                          <option>BTC</option>
                        </select> */}
                    {/* <Select
                      placeholder="Select option"
                      value={selectedOption}
                      className="select-option-s"
                      options={data}
                      onChange={handleChange}
                      getOptionLabel={(e) => (
                          <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                              {e.icon}
                              <span style={{ marginLeft: '5px' }}>{e.text}</span>
                          </div>
                      )}
                  /> */}
                  </div>
                </div>

                <div className="withdrawl-amount">
                  <p>Balance: {selectedOption?.total_coin} </p>
                  <span>Amount</span>
                  <div className="withdraw-sec">
                    <div className="input-sec">
                      <input type="number" placeholder="Amount" onChange={fixAmount} value={amount} step="0.01" />
                    </div>
                    <div className="link1"><span><Link to="">{selectedOption?.value?.toUpperCase()}</Link></span></div>
                    <div><span>|</span></div>
                    <div className="link2"><span><Button className="link-btn2 btn-success" onClick={() => { Number(selectedOption.total_coin) > 0 ? setAmount(selectedOption?.total_coin > 0 ? selectedOption?.total_coin : 0) : setAmount(0) }}>MAX</Button></span></div>
                  </div>

                  {/* <div className="withdraw-sec mt-5 p-1">
                    <div className="input-sec">
                      <span>
                        <Tooltip placement="top" title="The amount of the chosen currency that can be withdrawn or transferred out without borrowing, excluding the assets in use. The transferrable amount may be lower than the available balance as transfers-out from an account with borrowed assets will increase the risk of liquidation" arrow><p style={{ borderBottom: "1px dashed #e4e4e4", color: "#a4a4a4" }}>Transferrable</p></Tooltip>
                      </span>
                     
                    </div>
                    <div className="link1"><span><Link to="">{selectedOption?.value?.toUpperCase()}</Link></span></div>
                    <div><span>|</span></div>
                    <div className="link2"><span><Button className="link-btn2" onClick={() => { setAmount(selectedOption.total_coin); console.log(' selectedOption.total_coin', selectedOption.total_coin); }}>MAX</Button></span></div>
                  </div> */}
                </div>

                <div>
                  <div className="address-sec">
                    <div> Remark </div>
                    <div className="address-form">
                      <div><input className="" type="text" placeholder="Enter remark" onChange={(e) => { setRemark(e.target.value) }} value={remark} />
                      </div>
                      <div>

                      </div>

                    </div>
                  </div>

                  {userInfo?.security_type === 'other' && <div className="address-sec">
                    <div>OTP</div>
                    <div className="address-form pt-0">
                      <div><input type="text" placeholder="Enter OTP" onChange={(e) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} /></div>
                      <Button onClick={() => { getphone2faOtp(false) }} disabled={timer !== '00:00' ? true : false} >{otpBtn === 'resend' ? 'Resend OTP' : 'Send OTP'}</Button>

                    </div>

                  </div>
                  }

                  {userInfo?.security_type === '2fa' && <div className="address-sec">
                    <div>OTP</div>
                    <div className="address-form">
                      <div ><input type="text" placeholder="Enter  OTP" onChange={(e) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} /></div>

                    </div>

                  </div>}

                </div>

                <div className="recieve-sec">

                  <div className="inner">
                    {/* <div className="inner-left">
                      <h3 >-- {selectedOption?.value?.toUpperCase()}</h3>
                      <p><span style={{ color: "#d0ab3a" }}>0.40468969 {selectedOption?.value?.toUpperCase()}</span> network fee included</p>
                    </div> */}
                    <div className="inner-right w-100">
                      <button type='button' className='btn submit-btn w-100' onClick={(e) => submitWithdrawRequest(e)} disabled={withdrawbtnDisable}>


                        {withdrawbtnDisable === true &&
                          <div className="color-ring">
                            <ClipLoader color="#36d7b7" />

                            Please Wait...
                          </div>
                        }
                        {withdrawbtnDisable === false ? 'Withdraw ' : ''}
                      </button>
                    </div>
                  </div>


                </div>

              </div>
            </div>


          </div>


          {/* <WithdrawHistory /> */}
        </div>
      </div>


      <Modal open={open} onClose={onCloseModal} center
        classNames={{
          overlay: 'withdralOverlay',
          modal: 'withdralModal',
        }}
      >
        <h4>Select Address</h4>
        <div className="card-address">
          <div className="new_address">
            <div className="saves-address-header">
              <p className="address-title">Saved address</p>
              <span className="Button-all-one" onClick={() => { openModalForm(); onCloseModal(); }}> ADD ADDRESS</span>
            </div>
            <form>
              {allAddress?.map((value) => {
                return (
                  <div className="secroll-data">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" id="sd" name="chekcbox-1" onChange={(e) => { setAddress(e.target.value); onCloseModal() }} value={value?.wallet_address} /><span> {value?.wallet_address}</span>
                      </label>
                      <div className="copy-btn-div">
                        <div className="copy-btn" title="copy" onClick={() => copyToClipboardId(value?.wallet_address)}> <ContentCopy /> </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </form>
          </div>
          <div className="card-search-icon">
            <SearchOff />
          </div>
          <p> No Saved <span>BSUD</span> Address found</p>
          <span className="Button-all-one active" onClick={() => { openModalForm(); onCloseModal() }}> ADD ADDRESS</span>

        </div>



      </Modal>

      <Modal open={openForm} onClose={onCloseModalFrom} center
        classNames={{
          overlay: 'withdralOverlay',
          modal: 'withdralModal'
        }}>
        <h4>Add BUSD Address</h4>
        <div className="card-form-add withdralModal-new-address">
          <h5 className="pra-tag">Please ensure to select the network that matches the destination address to  avoid loss of funds.</h5>
          <form>
            <div className="form-group">
              <label htmlFor="inputa"> Destination address</label>
              <input type="text" className="with-form-btn" placeholder="Paste Address here" onChange={(e) => setCreateAddress(e.target.value)} value={createAddress} />
            </div>
            <div className="form-group">
              <label htmlFor="inputa"> Network </label>
              <select className="form-control1 user_none" name="address" id="address" onChange={(e) => { setCreateAddressChain(e.target.value) }} >
                <option >SELECT NETWORK</option>

                {selectedOption?.bep20?.status === false && selectedOption?.trc20?.status === false && selectedOption?.erc20?.status === false && <option>Network Closed</option>}
                {selectedOption?.bep20?.status === true && <option value={'BEP20'}>BEP20</option>}
                {selectedOption?.trc20?.status === true && <option value={'TRC20'}>TRC20</option>}
                {selectedOption?.erc20?.status === true && <option value={'ERC20'}>ERC20</option>}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="inputa"> label</label>
              <input type="text" className="with-form" placeholder="e.g. john (4-20 characters)" onChange={(e) => { setcreateLabel(e.target.value) }} value={createLabel} />
            </div>
            <div className="form-group">
              <div className="form-group_s">
                <input type="checkbox" id="set-value1" onClick={(e) => { SetCreateUniversalStatus(e.target.checked) }} />
                <label className="label__d" htmlFor="set-value1">set as universal, not as coin-specific.</label>
              </div>
            </div>
            <button type="button" onClick={onSubmitAddAddress} className="trade_btn trade_btn_with" disabled={btnDisable}>
              {btnDisable === true &&
                <div className="color-ring">
                  <ClipLoader color="#36d7b7" />

                  Please Wait...
                </div>
              }
              {btnDisable === false ? 'Submit ' : ''} </button>
          </form>
        </div>

      </Modal>

    </>
  );
};

export default Withdraw;