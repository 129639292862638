import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Table } from "react-bootstrap";
import { ContentCopy } from "@mui/icons-material";
import { Person } from "@mui/icons-material";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import _fetch from "../../config/api";
import { api_url, title } from "../../config/config";
import toasted from "../../config/toast";
import { toast } from "react-toastify";
import PaymentDetail from "./PaymentDetail";
import { Link, useLocation } from "react-router-dom";
import Authentication from "./Authentication";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ClipLoader } from 'react-spinners'
import user_name from '../../Assets/Img__v2/profile.svg'
import phoneicon from '../../Assets/Img__v2/phoneicon.svg'
import emailicon from '../../Assets/Img__v2/emailicon.svg'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}



function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tabpanel-inner">
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {

  const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '' });
  const [updateUserData, setUpdateUserData] = useState('');
  const [timer, settimer] = useState('00:00');
  const [otp, setOTP] = useState();
  const [callFuntion, setcallFuntion] = useState(0);


  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userKycInfo, setUserKycInfo] = useState({ first_name: '', last_name: '' });
  const [btnDisable, setbtnDisable] = useState(false);
  const [PassbtnDisable, setPassbtnDisable] = useState(false);




  const location = useLocation()

  const { state } = location;

  useEffect(() => {
    (async () => {
      const userData = await _fetch(`${api_url}user/userInfo/`, 'GET');
      setUserInfo(userData?.data?.data);
      const kycData = await _fetch(`${api_url}kyc/userKycInfo/`, "GET", "", {});
      setUserKycInfo(kycData?.data?.data);
    })();

    if (state?.active === true) {
      setValue(2)
    }
  }, [callFuntion]);


  const handleCopy = (data: any) => {
    if (data) {
      navigator.clipboard.writeText(data);
      toasted.success("Username copied successfully");
    }
  };

  async function createOTP(val: any) {
    if (val === 'email') {
      var data: any = `&sendTo=${userInfo.phone}&check=phone`;
    }
    else {
      var data: any = `&sendTo=${userInfo.email}&check=email`;
    }

    var d = await _fetch(`${api_url}auth/generateAuthOTP?email_type=Update Profile${data}`, 'GET');
    if (d?.status === 'success') {
      toasted.success(d?.message);

      if (timer === "00.00") {
        var seconds: any = '180';
        var intervalId = window.setInterval(() => {
          seconds--;

          let minutes: any = Math.floor(seconds / 60);
          let exterseconds: any = seconds % 60;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          exterseconds = exterseconds < 10 ? "0" + exterseconds : exterseconds;
          var leftData = minutes + ':' + exterseconds;

          settimer(leftData);

          if (seconds == 0) {
            stop();
          }

        }, 1000);
      }
      let stop = () => clearInterval(intervalId);
    }
    else {
      localStorage.clear();
      toast.error(d?.message);
    }
  }


  // Submit button of updating the user profile

  const updateUserProfile = async (profileType: any) => {
    setbtnDisable(true)
    if (profileType === 'email') {
      var data: any = {
        "type": 'email',
        "phone": userInfo.phone,
        "update": updateUserData,
        "otp": otp
      }
    } else {
      var data: any = {
        "type": 'phone',
        "email": userInfo.email,
        "update": updateUserData,
        "otp": otp
      }
    }
    var d = await _fetch(`${api_url}user/updateProfile`, 'POST', data);
    if (d?.status === 'success') {
      toasted.success(d?.message);
      onCloseModal();
      setcallFuntion(callFuntion === 0 ? 1 : 0);
      setbtnDisable(false)
    }
    else {
      toasted.error(d?.message);
      setbtnDisable(false)
    }
  }



  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Submit function of password

  const SubmitPasswordData = async (e: any) => {
    setPassbtnDisable(true)
    e.preventDefault();
    const loginData = {
      "current_password": oldPassword,
      "new_password": newPassword,
      "confirm_new_password": confirmPassword
    }

    var d = await _fetch(`${api_url}user/changePassword/`, 'POST', loginData);
    if (d?.status === 'success') {
      toasted.success(d?.message);
      setOpen(false)
      setPassbtnDisable(false)
    }
    else {
      toasted.error(d?.message);
      setPassbtnDisable(false)

    }
  }


  async function getphone2faOtp() {
    var d = await _fetch(`${api_url}auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET', {}, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
    }
    
  }



  // states for modal

  const [open, setOpen] = useState(false);

  // change email modal

  const [openEmail, setOpenEmail] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const onOpenEmailModal = () => {
    setOpenEmail(true);
  }

  const onOPenMobileModal = () => {
    setOpenMobile(true);
  }

  // states for tab2 modal
  const [openModal, setOpenModal] = useState(false);

  const onOpenModalTab2 = () => {
    setOpenModal(true);
  };


  // states for UPI modal
  const [openModalUpi, setOpenModalUpi] = useState(false);

  const onOpenModalUpi = () => {
    setOpenModalUpi(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    setOpenModal(false);
    // setOpenModalBank(false);
    setOpenModalUpi(false);
    setOpenEmail(false);
    setOpenMobile(false);
  };
  // counter for modal2 for OTP

  // const OtpCounter: React.FC = () => {
  //   const [count, setCount] = useState(60);

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       setCount((prevCount) => prevCount - 1);
  //     }, 1000);

  //     if (count === 0) {
  //       clearInterval(intervalId);
  //     }

  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }, [count]);

  //   return (
  //     <div style={{ display: "inline-flex" }}>
  //       <h1 style={{ fontSize: "18px" }}>{count}</h1>
  //     </div>
  //   );
  // };

  //

  useEffect(() => {
    var className = "Profile_header";
    if (location.pathname === "/dashboard/profile") {
      document.getElementsByTagName("header")[0].classList.add(className);

    }

  });

  return (
    <div className="main-sec1">
      <div className="inner-sec1">
        <div className="w-100">

          <div className="header-fix">
            <div className="coustom_container">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example" >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Security" {...a11yProps(1)} />
                <Tab label="Payment" {...a11yProps(2)} />
                <Tab label="API Keys" {...a11yProps(3)} />
                <Tab label="Activity Logs" {...a11yProps(4)} />
              </Tabs>
            </div>
          </div>

          <div className="coustom_container tabpanel_outer">
            <TabPanel value={value} index={0}>
              <div className="tab__card__a">
                <div className="head">
                  <h2>Account Info </h2>
                </div>
                <div className="tab-body">

                  <div className="tab___user__info">
                    <div className="card__item_ad">
                      <div className="card__item_profile">
                        <div className="card__borders">
                          <Person className="icon_items" />
                        </div>

                        <div className="card__item_txt">
                          <div className="card_item_email">
                            {userInfo?.email.substring(0, 5) + '****' + userInfo?.email.substring(userInfo?.email.length - 11, userInfo?.email.length)}
                          </div>

                          <div className="card_item_uid">
                            <p className="mb-0">UID <span> {userInfo?.username} </span></p>
                            <ContentCopy className="copY__buttons" onClick={() => handleCopy(userInfo?.username)} />
                          </div>

                        </div>
                      </div>

                      <div className="security__ad">
                        <p>Security Level:</p>
                        <div className="progress" style={{ height: "15px" }}>
                          <div className="progress-bar" style={{ width: "90%" }}>90%</div>
                        </div>
                      </div>

                      <div className="Last__seen">
                        <p>Last login time: 2023-08-14 11:51</p>
                        <p className="second__links"> <Link to={''}>Login Records</Link> </p>
                      </div>

                    </div>

                    <div className="tab__user__guidelines">

                      {userKycInfo?.first_name != "" ?

                        <div className="guidelines__contain">
                          <div className="g__text">
                            <p> Dear User {userInfo?.username} </p>
                            <p> Get Increased Limits And Advanced Features By Providing A Bit More Profile Information.</p>
                            <p> <Link to="/dashboard" className="more__links"> Get Start</Link> </p>
                          </div>

                        </div>
                        :

                        <div className="guidelines__contain">
                          <div className="g__text">
                            <p> Dear User {userInfo?.username} </p>
                            <p>Your account kyc not completed Please set up the following as soon as possible.</p>
                            <p> <Link to="/dashboard/kyc" className="more__links"> please complete kyc </Link> </p>
                          </div>
                        </div>
                      }





                    </div>
                  </div>
                </div>
              </div>


              <div className="profile__settings mt-4">
                <div className="profile_head">
                  <h4>Advanced Settings</h4>
                </div>
                <div className="profile__body">

                  <div className="row">

                    {/* name sec___ */}
                    <div className="col-12">
                      <div className="card profile__card">
                        <div className="card_left_profile">
                          <img src={user_name} alt="user__icon" />

                          <div className="profile__left_txt">
                            <h5>Name</h5>
                            <p> Your {title} account full name </p>
                          </div>
                        </div>

                        <div className="card__right_profile">

                          {userKycInfo?.first_name != "" ?

                            <p>{userKycInfo?.first_name}  {userKycInfo?.last_name}  </p>
                            :

                            <Link to="/dashboard/kyc" className="btn btn-primary move__next_button"> kyc</Link>
                          }

                        </div>

                      </div>
                    </div>

                    {/* Email sec__ */}

                    <div className="col-12">
                      <div className="card profile__card">
                        <div className="card_left_profile">
                          <img src={emailicon} alt="user_email_icon" />

                          <div className="profile__left_txt">
                            <h5>Email</h5>
                            <p> Your {title} account Submit email address </p>
                          </div>
                        </div>

                        <div className="card__right_profile">

                          {userInfo?.email != "" ?

                            <p className="email__show"> {userInfo?.email.toLocaleLowerCase()}  </p>

                            :

                            <Button className="move__next_button" onClick={onOpenEmailModal}> Modify </Button>
                          }

                        </div>
                      </div>
                    </div>

                    {/* mobile sec... */}

                    <div className="col-12">
                      <div className="card profile__card">
                        <div className="card_left_profile">
                          <img src={phoneicon} alt="user_phone_icon" />

                          <div className="profile__left_txt">
                            <h5>mobile</h5>
                            <p> Your {title} account Mobile Number </p>
                          </div>
                        </div>

                        <div className="card__right_profile">

                          {userInfo?.phone != "" ?

                            <p className="email__show"> +{userInfo?.phone} </p>

                            :
                            <Button className="move__next_button" onClick={onOPenMobileModal}> Modify </Button>

                          }

                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </TabPanel>


            <TabPanel value={value} index={1}>
              <Authentication />
            </TabPanel>




            <TabPanel value={value} index={2}>
              <PaymentDetail />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <div className="profile__settings">
                <div className="profile_head">
                  <h4>API Access :Disabled</h4>
                </div>
                <div className="profile__body">
                  <div className="col-12">
                    <div className="card__list_api">
                      <div className="txt-sec">
                        <ol>
                          <li>Each account can create up to 30 API Keys.</li>
                          <li>
                            Do not disclose your API key to anyone to avoid asset
                            losses. It is recommended to bind IPs for API keys to
                            increase your account security.
                          </li>
                          <li>
                            Be aware that your API key may be disclosed if you
                            authorize it to a third-party platform.
                          </li>
                          <li>
                            Please see the API Documentation for more information on
                            how to use your API keys.
                          </li>
                        </ol>
                      </div>
                    </div>

                  </div>

                  <div className="col-12">
                    <div className="card__list_api">
                      <div className="table-data-sec">
                        <div className="table-data">
                          <Table style={{ marginBottom: "0px" }}>
                            <thead>
                              <tr>
                                <th>API Key</th>
                                <th>Created On</th>
                                <th>Last Accessed</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                          </Table>
                          <div className="demo-data">
                            <span>
                              Enable API access on your account to generate keys.
                            </span>
                            <br />
                            <Button variant="link">+ Enable API access</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab3">



              </div>
            </TabPanel>

            <TabPanel value={value} index={4}>

            </TabPanel>



          </div>

        </div>
      </div>

      <>
        {/* tab 1 Modal */}
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{
            modal: "New_modal",
          }}
          center
        >
          <div className="main-sec-modal">
            <h2>Change Password</h2>
            <div className="form">
              <form method="post">
                <div className="input-1">
                  <label>Current Password</label>
                  <input type="password" placeholder="Current Password" onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword} />
                </div>
                <div className="input-1">
                  <label>New Password</label>
                  <input type="password" placeholder="New Password" onChange={(e) => { setNewPassword(e.target.value) }} value={newPassword} />
                </div>
                <div className="input-1">
                  <label>Confirm New Password</label>
                  <input type="password" placeholder="Confirm New Password" onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className="form-btn" onClick={SubmitPasswordData} disabled={PassbtnDisable}>

                    {PassbtnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {PassbtnDisable === false ? 'Change Password ' : ''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        {/* Change Email Modal */}

        <Modal
          open={openEmail}
          onClose={onCloseModal}
          classNames={{
            modal: "New_modal",
          }}
          center
        >
          <div className="main-sec-modal">
            <h5>Edit Your Email</h5>
            <div className="form">
              <form>
                <div className="input-1">
                  <label>Email addresss</label>
                  <div className="inner-input">
                    <input type="email" placeholder="Enter email" onChange={(e) => { setUpdateUserData(e.target.value) }} value={updateUserData} />
                    <button type="button" className="form-btn otpfield" onClick={() => { createOTP('email') }}>
                      Send Code
                    </button></div>
                </div>
                <div className="input-1">
                  <label>OTP</label>
                  <input type="text" placeholder="OTP" onChange={(e: any) => { setOTP(e.target.value) }} value={otp} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className="form-btn" onClick={() => { updateUserProfile('email') }} disabled={btnDisable}>
                    {btnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Submit ' : ''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        {/* change mobile number Modal */}


        <Modal
          open={openMobile}
          onClose={onCloseModal}
          classNames={{
            modal: "New_modal",
          }}
          center
        >
          <div className="main-sec-modal">
            <h5>Edit Your Mobile Number</h5>
            <div className="form">
              <form>
                <div className="input-1">
                  <label>Mobile Number</label>
                  <div className="inner-input">
                    <PhoneInput
                      inputStyle={{ paddingLeft: "50px" }}
                      inputClass={"inputt-ph"}
                      containerStyle={{}}
                      searchClass="search-class"
                      disableSearchIcon={false}
                      enableTerritories
                      countryCodeEditable={true}
                      placeholder='Phone Number'
                      buttonStyle={{ width: '47px' }}
                      dropdownStyle={{ height: '180px' }}
                      country={"in"}
                      enableSearch={true}
                      onChange={(e) => { setUpdateUserData(e) }}
                    // onChange={(e) => { setUpdateUserData(e.target.value) }} value={updateUserData}
                    />
                    <button type="button" className="form-btn otpfield" onClick={() => { createOTP('phone') }}>
                      Send Code
                    </button></div>
                </div>
                <div className="input-1">
                  <label>OTP</label>
                  <input type="text" placeholder="OTP" onChange={(e: any) => { setOTP(e.target.value) }} value={otp} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className="form-btn" onClick={() => { updateUserProfile('phone') }} disabled={btnDisable}>
                    {btnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Submit ' : ''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>



      </>
    </div>
  );
}
