import "./Assets/scss/style.scss";
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Header from "./Public/Header";
import Footer from "./Public/Footer";
import Dashboard from "./Public/Templates/Dashboard";
import Profile from "./Public/Templates/Profile";
import Deposit from "./Public/Templates/Deposit";
import Login from "./Public/Templates/Login";
import SignUp from "./Public/Templates/SignUp";
import ForgotPass from "./Public/Templates/ForgotPass";
import Withdraw from "./Public/Templates/Withdraw.jsx";
import VerificationOtp from "./Public/Templates/VerificationOtp";
import Transfer from "./Public/Templates/Transfer";
import Kyc from "./Private/Templates/Kyc";
import MarketReport from "./Public/Templates/MarketReport";
import DepositHistory from "./Public/Templates/DepositHistory";
import PrivateRoutes from "./config/PrivateRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WithdrawHistory from "./Public/Templates/WithdrawHistory";
import ChangePassword from "./Public/Templates/ChangePassword";
import AccountDetails from "./Public/Templates/AccountDetails";
import KycDetails from "./Private/Templates/KycDetails";
import P2p from "./Public/p2pPages/P2p";
import MyAdsPost from "./Public/p2pPages/MyAdsPost";
import AddPost from "./Public/p2pPages/AddPost";
import TransactionCompleted from "./Public/p2pPages/TransactionCompleted";
import { title } from "./config/config";
import ViewAdsPostData from "./Public/p2pPages/ViewAdsPostData";
import MainTrading from "./Private/TradingComp/MainTrading";
import ManualTrading from "./Private/TradingCompManual/ManualTrading";
import PublicMainTrading from "./Public/TradingComp/MainTrading";
import PublicManualTrading from "./Public/TradingCompManual/ManualTrading";
import ChatP2p from "./Public/p2pPages/ChatP2p";
import CommingSoon from "./Public/CommingSoon/CommingSoon";
import Staking from "./Private/StakingPage/Staking";
import Swap from "./Private/Swap";
import StakeHistory from "./Private/StakingPage/StakeHistory";
import SwapHistory from "./Private/SwapHistory";
import Test from "./Assets/test";
import VerifyLogin from "./Public/Section/VerifyLogin";
import Markets from "./Public/FooterPages/Markets";

function App() {

  window.scrollTo(0, document.body.scrollTop);




  function LoginLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  function PublicLayout() {
    return (
      <>
        <div className="login__container">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </>
    );
  }
  function PrivateLayout() {
    return (
      <>
        <div className="login__container">
          {/* <PrivateHeader/> */}
          <Header />
          <Outlet />
          <Footer />
        </div>
      </>
    );
  }


  function MarketLayout() {
    return (
      <>
        <Header />
        <Outlet />
        {/* <MobBar /> */}
      </>
    );
  }

  function PublicMarketLayout() {
    return (
      <>
        <Header />
        <Outlet />
        {/* <MobBar /> */}
      </>
    );
  }


  function P2pLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  function CommingSoonlayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Router>
        <Routes>

          <Route element={<LoginLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-up/:sponser" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/VerificationID/:token" element={<VerificationOtp />} />
            <Route path="/verify-login" element={<VerifyLogin />} />
            <Route path="/dashboard/markets" element={<Markets />} />

          </Route>

          <Route element={<PublicLayout />}>
            <Route path="/test" element={<Test />} /> 
          </Route>

          <Route element={<PrivateLayout />}>
            {/* PVT Pages  */}
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              {/* <Route path="/dashboard/activitylogs1" element={<Activity />} /> */}
              <Route path="/dashboard/profile" element={<Profile />} />
              <Route path="/dashboard/change-password" element={<ChangePassword />} />
              <Route path="/dashboard/deposit" element={<Deposit />} />
              <Route path="/dashboard/withdraw" element={<Withdraw />} />
              <Route path="/dashboard/transfer" element={<Transfer />} />
              <Route path="/dashboard/:type" element={<MarketReport />} />
              <Route path="/dashboard/account-record/:type" element={<AccountDetails />} />
              <Route path="/dashboard/deposit-history" element={<DepositHistory />} />
              <Route path="/dashboard/withdraw-history" element={< WithdrawHistory />} />
              <Route path="/dashboard/kyc" element={<Kyc />} />
              {/* <Route path="/dashboard/activitylogs" element={<ActivityLogs />} /> */}
              <Route path="/dashboard/kycdetails" element={<KycDetails />} />
              <Route path="/dashboard/kyc/Approved" element={<KycDetails />} />
              <Route path="/dashboard/swap" element={<Swap />} />
              <Route path="/dashboard/staking" element={<Staking />} />
              <Route path="/dashboard/staking/:id" element={<Staking />} />
              <Route path="/dashboard/staking-history" element={<StakeHistory />} />
              <Route path="/dashboard/swap-history" element={<SwapHistory />} />
            </Route>

          </Route>

          <Route element={<P2pLayout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard/p2p" element={<P2p />} />
              <Route path="/dashboard/addpost" element={<AddPost />} />
              <Route path="/dashboard/myadspost" element={<MyAdsPost />} />
              <Route path='Dashbaord/view-adds-data/:order_type/:id' element={<ViewAdsPostData title={`ADD POST | ` + title + ` EXCHANGE`} />} />
              <Route path='/dashboard/p2p/transaction/:id' element={<TransactionCompleted title={`P2P | ` + title} />} />
              <Route path="/dashboard/ChatP2p/:type/:id" element={<ChatP2p />} />

            </Route>
          </Route>

          <Route element={<MarketLayout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard/trading/:id" element={<MainTrading />} />
              <Route path="/dashboard/tradings/:id" element={<ManualTrading />} />
            </Route>
          </Route>

          {/* Public Market ( Trading page......) */}
          <Route element={<PublicMarketLayout />}>
            <Route path="/trade/:id" element={<PublicMainTrading />} />
            <Route path="/trades/:id" element={<PublicManualTrading />} />
          </Route>

          <Route element={<CommingSoonlayout />}>
            <Route path="/coming-soon" element={<CommingSoon />} />
          </Route>

        </Routes>


      </Router>
      <ToastContainer />
    </>
  );
}

export default App;