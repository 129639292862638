import { AddPhotoAlternate, Close, Telegram } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _fetch from '../../config/api';
import { api_url, p2pchat_img, receipt_img } from '../../config/config';
import { useParams } from 'react-router-dom';


const ChatP2p = () => {

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [image, setImage] = useState(null);
    const [message, setmessage] = useState(null);
    const [SendImage, setSendImage] = useState();
    const [upload, setupload] = useState();
    const { id, type } = useParams();
    const [chatId, setchatId] = useState()

    const onImageChange = (event) => {
        setSendImage(event.target.files[0]);
        setupload(event.target.files[0])
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
        }
        else {

            setImage("")
        }
    };



    const [allData, setallData] = useState([{
        amount: 0,
        buy_currency: "",
        buyer_order_id: "",
        coin_id: "",
        image: "",
        order_status: "",
        order_type: "buy",
        payment_method: [{ payid: "", type: "" }],
        price: 0,
        sell_currency: "",
        seller_order_id: "",
        symbol: "",
        totalAmount: 0,
        trx_id: "",

    }])
    async function getAllData() {
        if (type === 'buy') {
            let d = await _fetch(`${api_url}trade/p2p-self-buy-Transactons?&search=buyer_order_id&value=${id}`, 'GET', {}, {});
            if (d?.status === 'success') {
                setallData(d?.data?.data?.[0]);
                viewData(d?.data?.data?.[0]?.buyer_order_id);
            }
        } else {
            let d = await _fetch(`${api_url}trade/p2p-self-view-Transactons?search=buyer_order_id&value=${id}`, 'GET', {}, {});
            if (d?.status === 'success') {
                setallData(d?.data.data?.[0]);
                viewData(d?.data?.data?.[0]?.buyer_order_id);
            }
        }
    }

    async function genrateChatID(buyer_order_id) {
        let d = await _fetch(`${api_url}trade/p2p-start-chat`, 'POST', { orderID: buyer_order_id }, {});
        if (d?.status === 'success') {
            viewData(buyer_order_id);
        }
    }

    const [getChat, setgetChat] = useState({ conversation: [] });
    async function viewData(buyer_order_id) {
        let d = await _fetch(`${api_url}trade/p2p-view-chat`, 'POST', { orderID: buyer_order_id }, {});
        if (d?.status === 'success') {
            setchatId(d?.chat?.chat_id);
            setgetChat(d.chat);
        } else {
            genrateChatID(buyer_order_id);
        }
    }

    const submitTask = async (e) => {
        e.preventDefault()

        const data = {
            chatId: chatId,
            message: message,
            image: SendImage
        }


        let formData = new FormData();
        formData.append('chatId', chatId);
        formData.append('message', `${message}`);
        formData.append('image', SendImage);
        let d = await _fetch(`${api_url}trade/p2p-chat`, 'ImagePost', formData, {});
        if (d?.status === 'success') {
            setmessage("");
            setSendImage("");
            setImage("")

            viewData(allData?.buyer_order_id)
        }
    }


    const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '' });


    useEffect(() => {
        getAllData();
        (async () => {
            const passData = {};
            const data1 = await _fetch(`${api_url}user/userInfo/`, 'GET', passData, {});
            setUserInfo(data1?.data?.data);

        })()
    }, []);


    const [countHit, setCountHit] = useState(0);

    useEffect(() => {
        if (countHit < 50) {
            const timer = setInterval(() => {
                (async () => {
                    
                    let d = await _fetch(`${api_url}trade/p2p-view-chat`, 'POST', { orderID: id }, {});
                    if (d?.status === 'success') {
                        setchatId(d?.chat?.chat_id);
                        setgetChat(d.chat);
                    } 
                if(countHit < 2){
                    clearInterval(timer);

                }



                })()
                var calHit = parseFloat(`${countHit + 1}`);
                setCountHit(calHit);
            }, 10000);
            return () => clearInterval(timer);
        }
    }, [countHit]);


    return (
        <>
            <div className='chat__p2p'>
                <div className='container-lg'>
                    <div className='row align-items-start'>
                        {/* Left sec... */}
                        <div className='col-xl-8 col-md-7'>
                            <div className='card card-trx_a'>
                                <div className='card-header bg-transparent chat-p2p-header'>
                                    <h3 className='chat-heading mb-0'> {allData?.order_type?.toUpperCase()} Details</h3>
                                </div>

                                <div className='card-body'>
                                    <div className='data-details'>
                                        <p>   Advertiser </p>
                                        <p> {allData?.buy_currency?.toUpperCase()}</p>
                                    </div>
                                    <div className='data-details'>
                                        <p>  Coin </p>
                                        <p> {allData?.amount?.toFixed(3)} {allData?.buy_currency?.toUpperCase()}	</p>
                                    </div>

                                    <div className='data-details'>
                                        <p>  Price	 </p>
                                        <p> {allData?.price?.toFixed(3)} {allData?.sell_currency?.toUpperCase()}	</p>
                                    </div>

                                    <div className='data-details'>
                                        <p>  Total Amount(INR)	 </p>
                                        <p>{(allData?.price * allData?.amount)?.toFixed(3)} <small>{allData?.sell_currency?.toUpperCase()}</small>	</p>
                                    </div>


                                    <div className='data-details'>
                                        <p>  Receivable	 </p>
                                        <p> {allData?.received_amount} {allData?.buy_currency?.toUpperCase()}</p>
                                    </div>
                                    <div className='data-details'>
                                        <p>  Payment Method	 </p>
                                        <p> {allData?.payment_method?.[0]?.type}	</p>
                                    </div>
                                    <div className='data-details'>
                                        <p>  Seller Order Number	 </p>
                                        <p> {allData?.seller_order_id}	</p>
                                    </div>
                                    <div className='data-details'>
                                        <p>  Transaction ID	 </p>
                                        <p> {allData?.trx_id}	</p>
                                    </div>
                                    <div className='data-details'>
                                        <p>  Transaction Image	 </p>
                                        <p> {allData?.image ? <img src={receipt_img + allData?.image} width={200} /> : 'No Image'}</p>
                                    </div>
                                    <div className='data-details'>
                                        <p>  Status	 </p>
                                        <p> {allData?.order_status === 'paired' ? 'Pending' : allData?.order_status}	</p>
                                    </div>


                                </div>

                            </div>
                        </div>

                        {/* Right sec... */}
                        <div className='col-xl-4 col-md-5 sticky_top'>
                            <div className='card card__chat_a'>

                                <div className='card-header bg-transparent '>
                                    <h3 className='chat-heading mb-0'>SpotChat</h3>
                                    <p className='chat-pragraph'>Your Simple Personal Information Required For Messages </p>
                                </div>
                                <div className='card-body'>
                                    <div className='chat_area'>

                                        {getChat?.conversation?.map((val) => {
                                            return (
                                                <>
                                                    {/* left side */}
                                                    {userInfo.username !== val.username ?
                                                        <>
                                                            {val.message !== 'null' && val.message !== "" && val.message !== 'undefined' && val.message ? <div className='admin-sms'>
                                                                <div className='admin-sms-area'>
                                                                    <p> {val.message}</p>
                                                                </div>
                                                            </div> : ''}
                                                            {val.image !== 'null' && val.image !== '' && val.image !== 'undefined' && val.image ?
                                                                <div className='user-sms'>
                                                                    <div className='admin-sms-area'>
                                                                        <img className='upload_data' src={p2pchat_img + val.image} alt='Upload_img' onClick={(even) => onOpenModal(p2pchat_img + val.image)} />
                                                                    </div>
                                                                </div> : ''}
                                                        </>
                                                        :
                                                        <>
                                                            {/* right Side DAta */}
                                                            {val.message !== 'null' && val.message !== "" && val.message !== 'undefined' && val.message ? <div className='user-sms'>
                                                                <div className='user-sms-area'>
                                                                    <p> {val.message}</p>
                                                                </div>
                                                            </div> : ''}

                                                            {val.image !== 'null' && val.image !== '' && val.image !== 'undefined' && val.image ? <div className='user-sms'>
                                                                <div className='user-sms-area'>
                                                                    <img className='upload_data' src={p2pchat_img + val.image} alt='Upload_img' onClick={(even) => onOpenModal(p2pchat_img + val.image)} />
                                                                </div>
                                                            </div> : ''}
                                                        </>
                                                    }



                                                </>
                                            )
                                        })}


                                    </div>
                                </div>


                                <div className='card-footer '>

                                    <div className='svg-div'>
                                        <label htmlFor='doc-front'>

                                            <AddPhotoAlternate />
                                            <input id='doc-front' type="file" className='temprary-input' accept="image/*" onChange={onImageChange} />
                                        </label>
                                    </div>

                                    <div className='card-input'>

                                        {image &&

                                            <div className='add-img'>
                                                <span className='close-none' onClick={() => { setImage(); setSendImage() }}> <Close /></span>
                                                <img src={image} alt="preview_image" className='w-100 h-100' />
                                            </div>
                                        }
                                        <input type='text' id='send_sms' className='form-control' value={message} onChange={(e) => setmessage(e.target.value)} />


                                        <div className='btn-send'>
                                            <button type='submit' className='btn' onClick={submitTask}> <Telegram /></button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal open={open} onClose={onCloseModal} classNames={{

                modal: "P2p__chat_show__",

            }} center>
                <p>Image</p>

                <div className='inner-modal'>
                    <div className='main-img'>

                        <img src={image} alt='img' />
                    </div>

                </div>
            </Modal>

        </>
    )
}

export default ChatP2p