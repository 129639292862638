import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useParams } from 'react-router-dom';
import _fetch from '../../config/api';
import BuyMarket from './BuySellAllmanual/BuyMarket';
import SellMarket from './BuySellAllmanual/SellMarket';
import BuyLimit from './BuySellAllmanual/BuyLimit';
import SellLimit from './BuySellAllmanual/SellLimit';

const BuySell = (props: any) => {


    const { id } = useParams()
   


    let [buymarketTab, setbuymarketTab] = useState('');
    let [buyStopLimitTab, setbuyStopLimitTab] = useState('');

    let [activeTab, setactiveTab] = useState('market');

    let tradingTab = (tab: string) => {
        // tab.currentTarget.classList.add("active");
        if (window.screen.width! <= 1200) {
            // window.location.reload();
            setactiveTab(tab)
            tab === 'market' ? setbuymarketTab('block') : setbuymarketTab('none');
            tab === 'stop-limit' ? setbuyStopLimitTab('block') : setbuyStopLimitTab('none');
        }
    };



    
    const [ActiveTab, setActiveTab] = useState(0);

    ///////set value  order book copy //////
    useEffect(() => {
        if (props?.copyData) {
            setActiveTab(1)
        }
    }, [props.copyData])

    return (
        <>
            <div className='main-buysell'>
                <div className='inner'>
                    <div className='tabs-main'>
                        <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>
                            <div className='header-but-sell'>
                                <TabList>
                                    <Tab>Market</Tab>
                                    <Tab className="sell react-tabs__tab" >Stop Limit</Tab>
                                </TabList>
                            </div>

                            <TabPanel>
                                <div className='inner-tabs'>
                                    <Tabs>
                                        {/* <TabList>
                                            <Tab>Market</Tab>
                                            <Tab>Stop Limit</Tab>
                                        </TabList> */}

                                        <div className='justify-content-left align-items-center inner_tab__buy_sell'>
                                            <div className={activeTab == 'market' ? "heading buy__btn" : "heading "} onClick={() => tradingTab('market')} ><p>Buy Market</p></div>
                                            <div className={activeTab == 'market' ? "heading" : "heading sell_btn"} onClick={() => tradingTab('stop-limit')}><p> Sell Market </p></div>
                                        </div>

                                        <TabPanel>
                                            <div className='main-market'>
                                                {/* BUY MARKET... */}
                                                <BuyMarket buymarketTab={buymarketTab} copyData={props.copyData} />

                                                {/* SELL MARKET */}
                                                <SellMarket buyStopLimitTab={buyStopLimitTab} copyData={props.copyData}/>
                                            </div>
                                        </TabPanel>

                                    </Tabs>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='inner-tabs'>
                                    <Tabs>


                                        <div className='justify-content-left align-items-center inner_tab__buy_sell'>
                                            <div className={activeTab == 'market' ? "heading buy__btn" : "heading"} onClick={() => tradingTab('market')} ><p> Buy Stop Limit</p></div>
                                            <div className={activeTab == 'market' ? "heading" : "heading sell_btn"} onClick={() => tradingTab('stop-limit')}><p> Sell Stop Limit</p></div>
                                        </div>

                                        <TabPanel>
                                            <div className='main-market2'>
                                                {/* BUY STOP LIMIT SEC...  */}
                                                <BuyLimit buymarketTab={buymarketTab} copyData={props.copyData}/>


                                                {/*  SELL STOP LIMIT SEC... */}
                                                <SellLimit buyStopLimitTab={buyStopLimitTab} copyData={props.copyData}/>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuySell