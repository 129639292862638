import React, { useEffect, useState } from 'react'
import LiveBalance from './LiveBalance';
import Chart from './Chart';
import Market from './Market';
import LiveChart from './LiveChart';
import OrderStatus from './OrderStatus';
import BuySell from './BuySell';
import { AutoGraph, CurrencyExchange, History, ListAlt, FormatListBulleted } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

const ManualTrading = (props: any) => {
  
  const { id } = useParams();

  // responsive state work 
  let [marketTab, setMarketTab] = useState('');
  let [CointTab, setCoinTab] = useState('');
  // let [chartTab, setChartTab] = useState('');
  let [orderTab, setOrderTab] = useState('');
  let [myorderTab, setmyorderTab] = useState('');
  let [BuySellTab, setsetBuySellTab] = useState('');

  let [LoadApi, setLoadApi] = useState(0);


  let tradingTab = (tab: string) => {
    if (window.screen.width! <= 768) {
      // window.location.reload();
      tab === 'live-chart' ? setMarketTab('block') : setMarketTab('none');
      tab === 'coin-chart' ? setCoinTab('block') : setCoinTab('none');
      tab === 'all-orders' ? setOrderTab('block') : setOrderTab('none');
      tab === 'order-sec' ? setmyorderTab('block') : setmyorderTab('none');
      tab === 'main-buy_sell' ? setsetBuySellTab('block') : setsetBuySellTab('none');
    }
  }

  const copyOrders = (data: any) => {

  }
  // setActiveTab(Number(tab))
  // console.log(data);


  const [orderBookCopy, setorderBookCopy] = useState()

  const navigate = useNavigate();

  useEffect(() => {
    if (id?.toLowerCase() === 'stacoin') {
      navigate('/dashboard');
    }

  }, [])

  return (
    <>

      {/* Responsive Data Icon  */}
      <div className='trading_responsive_tab'>
        <div className="nav Footer___tab">
          <div className='react-tab__1' onClick={() => tradingTab('coin-chart')} >
            <a className="btn">
              <FormatListBulleted />
              <span> Markets</span>
            </a>
          </div>

          <div className='react-tab__1' onClick={() => { tradingTab('live-chart'); setLoadApi(LoadApi === 1 ? 0 : 1) }} >
            <a className="btn">
              <AutoGraph />
              <span> Charts</span>
            </a>
          </div>

          <div className='react-tab__1' onClick={() => tradingTab('main-buy_sell')}  >
            <a className="btn logo__Main">
              <CurrencyExchange />
              <span> Buy/Sell</span>
            </a>
          </div>

          <div className='react-tab__1' onClick={() => tradingTab('order-sec')} >
            <a className="btn">
              <History />
              <span> Trades</span>
            </a>
          </div>

          <div className='react-tab__1' onClick={() => tradingTab('all-orders')} >
            <a className="btn logo__Main">
              <ListAlt />
              <span> My Orders</span>
            </a>
          </div>
        </div>
      </div>


      <div className='main-trading'>

        <div className='inner-sec'>
          <div className='first'>
            <div className='d-mobail-none live-chart' style={{ display: marketTab }}>
              <LiveChart symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} LoadApi={LoadApi} />
            </div>
            <div className='first-inner'>
              <div className='order-book1'>
                <LiveBalance symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} LoadApi={LoadApi} setVal={setorderBookCopy} />
              </div>
              <div className='chart2' >
                <div className='d-mobail-none' style={{ display: marketTab }}>
                  <Chart LoadApi={LoadApi} setLoadApi={setLoadApi} />
                </div>

                <div className='d-mobail-none main-buy_sell' style={{ display: BuySellTab }}>
                  <BuySell setLoadApi={setLoadApi} LoadApi={LoadApi} copyData={orderBookCopy} />
                </div>
              </div>
            </div>
          </div>
          <div className='second'>
            <div className=' all-orders' >
              <Market CointTab={CointTab} myorderTab={myorderTab} symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} LoadApi={LoadApi} />
            </div>

          </div>



        </div>

        <div className='d-mobail-none order-sec' style={{ display: orderTab }}>
          <OrderStatus copyOrders={copyOrders} LoadApi={LoadApi} />
        </div>

      </div>
    </>
  )
}

export default ManualTrading