import React,{useState} from 'react'
import {useNavigate, useParams } from 'react-router-dom'
import img from "../../Assets/Image/login-img.png"
import 'react-phone-input-2/lib/style.css'
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
import { VisibilityOffTwoTone, VisibilityTwoTone } from '@mui/icons-material';

const VerificationOtp = () => {
    const { token } = useParams()
    let navigate = useNavigate();

    const [passwordConfirm, setpasswordConfirm] = useState('');
    const [password, setpassword] = useState('');
    // const [showPassword, setShowPassword] = useState('password')
    // const [showConPassword, setShowConPassword] = useState('password')


    const forgotSubmit = async (e: any) => {
        e.preventDefault();
        const loginData = {
            password: password,
            passwordConfirm: passwordConfirm,

        }
        var d = await _fetch(`${api_url}auth/resetPassword/${token}`, 'POST', loginData, {});
        if (d?.status === 'success') {
            // localStorage.setItem("auth", 'true');
            // localStorage.setItem("accessToken", d?.token);
            // if (d.status == "success") {
            //     localStorage.setItem("user", JSON.stringify(d.data.user));
            // }
            // if (d?.data.kyc.kyc_status === 'Not Applied' || d?.data.kyc.kyc_status === 'Reject') {
                navigate("/login");

            // } else {
            //     navigate("/Dashboard");
            // }
            toasted.success(d?.message);
        } else {
            // localStorage.clear();
            toasted.error(d?.message);

        }

    }

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    return (
        <div>
            <div className="VerificationOtp-sec">
                <div className="Stater-sec">
                    <div className="right">

                        <div className="logo">
                            <div className="inner">
                                <div className="heading">
                                    <h3>Buy & Trade With Trust Brands</h3>

                                </div>
                                <div className="image">

                                    <img src={img} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="left">

                        <div className="inner">
                            <h1 className='text-center'>Verification Code</h1>

                            <form className="form" onSubmit={(e:any) => {forgotSubmit(e)}}>
                                        <div className="group">
                                            {/* <label>Enter the 6-digit code sent to ra***ni@gm***om</label> */}
                                            <div className="w-100 pass-word_button">
                                                <input type={showPassword ? "text" : "password"} className="form-control inputt w-100" placeholder='Password' value={password} onChange={(e: any) => { setpassword(e.target.value) }}  />

                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                    {showPassword ?
                                                        <VisibilityTwoTone />
                                                        :
                                                        <VisibilityOffTwoTone />
                                                    }
                                                </button>
                                                {/* <button type='button'>Send Code</button> */}
                                            </div>
                                            <div className=" w-100 mt-3 pass-word_button">
                                                <input type={showPassword2 ? "text" : "password"} className="form-control inputt w-100" placeholder='Confirm Password'   value={passwordConfirm} onChange={(e: any) => { setpasswordConfirm(e.target.value) }}  />
                                                {/* <button type='button'>Send Code</button> */}
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword2(!showPassword2) }}>
                                                    {showPassword2 ?
                                                        <VisibilityTwoTone />
                                                        :
                                                        <VisibilityOffTwoTone />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                    
                                        <button type='submit' className='main-button submit-button mt-2'  >Continue</button>
                                       
                                    </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default VerificationOtp

