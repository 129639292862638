import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const LiveBalance = (props: any) => {
    // New_____

    const { id } = useParams();
    const [sellData, setSellData] = useState([]);
    const [buyData, setBuyData] = useState([]);

    useEffect(() => {
        const nowStream = `${props.symbol.toLowerCase()}usdt@depth20`;
        const streams = ['@ticker', '@depth20', '@trade'];
        const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            try {
                if ((json)) {
                    if (json.stream === nowStream) {
                        if (json) {
                            var d = json.data;
                            if (d.asks) {
                                // console.log
                                setSellData(d.asks.reverse());
                            }

                            if (d.bids) {
                                setBuyData(d.bids);

                            }
                        }
                    }
                }
            } catch (err) {
                // console.log(err);
            }
        };



    }, [])

    return (
        <div className='main-order-book'>
            <div className='main-table-data'>
                <div className='main-table-order'>
                    <div className='tabs__card_dcs'>
                        <p className='main_order-heading'>Order Book </p>
                        <div className='scroll-y'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Price(USDT)</th>
                                    <th>Amount({props.symbol.toUpperCase()})</th>
                                    <th className='total'>Total</th>
                                </tr>
                            </thead>

                            <tbody>


                                {sellData.map((item, index) => (
                                    <tr data-index={index} style={{
                                        width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`,
                                        backgroundImage: `linear-gradient( to right, var(--sell) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`}, var(--sell) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`} ,  Transparent 0%, Transparent 0% )`,
                                    }}>
                                        <td className='pl'>{parseFloat(item[0]).toFixed(3)}</td>
                                        <td className='al'>{parseFloat(item[1]).toFixed(3)}</td>
                                        <td className='tl'>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(2)}</td>
                                    </tr>
                                ))}


                            </tbody>
                        </table>
                        </div>
                    </div>

                    <div className='tabs__card_dcs'>
                        <div className='scroll-y1'>
                            <table>
                                <thead>
                                    <tr>
                                        {/* <th className='numbers'>{Number(buyData?.[0]?.[0])?.toFixed(3)} </th>
                                        <th className='numbers2'>${Number(buyData?.[0]?.[1])?.toFixed(3)}</th>
                                        <th className='total'>More</th> */}
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                

                                    {buyData.map((item, index) => (
                                        <>
                                            {(item) ?
                                                <tr key={index} style={{
                                                    width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`,
                                                    backgroundImage: `linear-gradient( to right, var(--buy) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`}, var(--buy) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`} ,  Transparent 0%, Transparent 0% )`,
                                                }}>
                                                    <td className="status-dot-cell-green pl"> {parseFloat(item[0]).toFixed(3)} </td>
                                                    <td className='al'>{parseFloat(item[1]).toFixed(3)}</td>
                                                    <td className='tl'>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(2)}</td>
                                                </tr>
                                                : ''}
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveBalance