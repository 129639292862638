import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { api_url } from "../../config/config";
import _fetch from "../../config/api";
import ReactPaginate from "react-paginate";
import dateFormat from "dateformat";
import LoaderCom from "../../config/LoaderCom";
import { ArrowBackIosNew } from "@mui/icons-material";

const AccountDetails = (props: any) => {
  const { type } = useParams();
  const [allData, setAllData] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
  const [searchedType, setsearchedType] = useState("symbol");
  const [short, setshort] = useState('created_at');
  const [records, setrecords] = useState(0);
  // const [checked, setChecked] = useState(false);
  const [checked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenlangModal = () => setOpen(true);
  const onCloselangModal = () => setOpen(false);
  const [emptyData, setemptyData] = useState([]);


  const [itemOffset, setItemOffset] = useState(1);

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  // function Toggle() {

  //   const handleToggle = () => {
  //     setIsChecked(!checked);
  //   }

  //   return (
  //     <label className="toggle-switch">
  //       <input type="checkbox" checked={checked} onChange={handleToggle} />
  //       <span className="toggle-slider"> </span>
  //     </label>
  //   )
  // }




  async function getAccountTotalData() {
    setemptyData([])
    setAllData([])
    if (searchedVal != "") {
      var searchData = `&search=${searchedType}&value=${searchedVal}`;
    } else {
      var searchData = "";
    }
    let d = await _fetch(`${api_url}incomes/incomeHistory?type=${type}${searchData}&sort=${short}&limit=10&page=${itemOffset}`, 'GET', {}, {});
    if (d?.status === 'success') {
      setAllData(d?.data?.data);
      setrecords(d?.total)
      setemptyData(d)

    }

  }

  useEffect(() => {
    getAccountTotalData();

  }, [itemOffset, records, short, searchedVal, searchedType]);

  return (
    <>

      <div className="main-dashboard account_record">
        <div className="inner-crum coustom_container">
          <div className="inner-crum_dc">
            <div className="inner-crum-left">
              <span>  <Link to="/dashboard/deposit" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link> </span>
              <h3> History</h3>
            </div>
            <Link to="/dashboard" className="More__links"> Dashboard</Link>
          </div>
        </div>


        <div className="coustom_container pt-5">

          <div className="history___table w-100">
            <div className="tab-inner1 top_minus__contianer">

              <div className="tab-inner">
                <div className="history__txt">
                  <h5> {type?.toString().replace("_", ' ')} History</h5>
                </div>

                <div className="tab-right-sec">
                  <div className="drop-sec">
                    <select onChange={(e: any) => { setsearchedType(e.target.value) }} name="search" className="search-detail">
                      <option value="symbol">Symbol</option>
                      <option value="hash">Hash</option>

                    </select>
                  </div>

                  <div>
                    <Form.Control type="search" placeholder="Search" onChange={(e: any) => { setSearchedVal(e.target.value) }} />
                  </div>
                </div>
              </div>

              <div className="data-table">
                <Table hover>
                  <thead>
                    <tr>
                      <th onClick={() => { short == 'symbol' ? setshort('-symbol') : setshort('symbol') }} style={{ cursor: 'pointer' }}>SYMBOL </th>
                      <th onClick={() => { short == 'amount' ? setshort('-amount') : setshort('amount') }} style={{ cursor: 'pointer' }}>AMOUNT </th>
                      <th onClick={() => { short == 'remark' ? setshort('-remark') : setshort('remark') }} style={{ cursor: 'pointer', textAlign: 'center' }} >REMARK</th>
                      <th onClick={() => { short == 'created_at' ? setshort('-created_at') : setshort('created_at') }} style={{ cursor: 'pointer', textAlign: 'center' }}>CREATED AT</th>

                    </tr>
                  </thead>
                  <tbody>
                    <LoaderCom condition={emptyData} text={'Getting latest values...'} />

                    {allData?.map((val: any, index: any) => {

                      return (
                        <tr key={index} onClick={() => onOpenlangModal()}>
                          <td >
                            <p>{val.symbol.toUpperCase()}</p>
                          </td>
                          <td>
                            <p>{((val.amount.toFixed(3) > 0) ? <p style={{ color: 'green' }}>{val.amount.toFixed(3)} </p> : <p style={{ color: 'red' }}>{val.amount.toFixed(3)} </p>)}</p>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {val.remark}
                          </td>
                          <td style={{ textAlign: 'center' }}>{dateFormat(val.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                {records > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={records / 20}
                  previousLabel="<"
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default AccountDetails;