import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { api_url } from "../../config/config";
import _fetch from "../../config/api";
import toasted from "../../config/toast";

const AddPost = (props) => {
  const [InrPrice, setInrPrice] = useState(87)

  const [elpMarket, setElpMarket] = useState([])
  const [AvalableelpMarket, setAvalableElpMarket] = useState([])

  async function activityLog() {
    let d = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=funding_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      d?.market?.map((val) => {
        var newVal = {
          value: val?.symbol,
          text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
          icon: <img src={val?.image} className="option-img-a" />,
          current_price: val?.current_price,
          coinID: val?.coin_id,
        };
        setElpMarket(elpMarket => [...elpMarket, newVal]);
        if (val.total_coin > 0) {
          var newVal = {
            value: val?.symbol,
            text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
            icon: <img src={val?.image} className="option-img-a" />,
            total_coin: val?.total_coin,
            current_price: val?.current_price,
            coinID: val?.coin_id,

          };
          setAvalableElpMarket(AvalableelpMarket => [...AvalableelpMarket, newVal]);
        }
      })
    }
  }
  const [open, setOpen] = useState(false);


  /////////////////////////////////////////buy //////////////////////////////////////////////////
  const [buyPrice, setbuyPrice] = useState(0);
  const [buyQuantity, setbuyQuantity] = useState(0);
  const [selectedBuyOption, setSelectedBuyOption] = useState(null);
  const [buyChain, setbuyChain] = useState(null);

  const handleBuyChange = (e) => {
    var newPrice = InrPrice * e?.current_price;
    setbuyPrice(newPrice)
    setbuyChain(e.coinID)

    setSelectedBuyOption(e);
  }
  const buySubmit = async (e) => {
    e.preventDefault();
    var data = {
      "amount": buyQuantity,
      "price": buyPrice,
      "coinID": buyChain,
      "currency": selectedBuyOption.value,
      // "currency_type": 'buy_currency',

    }

    var d = await _fetch(`${api_url}trade/p2p-create-buy-trade`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
      //  setOpenQR(false);

    } else {
      toasted.error(d.message);

    }
  }
  const onCloseModal = () => setOpen(false);
  const third = (id) => {
    const a = document.getElementById(id);
    a.classList.toggle("show-option");
    // console.log("okay => show dropdown");
  };

  const [open3, setOpen3] = useState(false);
  const onOpenModal3 = () => setOpen3(true);

  ///////////////////////payment-options details/////////////////////////////////////////////////////
  const [allAccountdetail, setallAccountdetail] = useState([])
  async function getAccountDetails() {
    let d = await _fetch(`${api_url}payments/paymentDetails`, 'GET', {}, {});
    if (d?.status === 'success') {
      setallAccountdetail(d.data.data)
    }
  }

  ////////////////////////////////select multiple method ////////////////

  useEffect(() => {
    document.title = props.title;

    activityLog()
    getAccountDetails();
  }, [])


  return (
    <div className='add-post-main'>
     
      <div className='buy-inner buy-sell-comman'>
      <div className="buy-title"><h4 className="text-light">Buy</h4></div>
        <form onSubmit={buySubmit}>
          <div className='form-group price-input'>
            <label>Price</label>
            <input
              type="number"
              className="form-control"
              placeholder="10 -- 1000"
              id="receive"
              onChange={(e) => setbuyPrice(e.target.value)}
              value={buyPrice}
            />
            <div className="dropdown tab-dropdown">


              <Select
                placeholder="Coins"
                value={selectedBuyOption}
                className="select-option-s"
                options={elpMarket}
                onChange={handleBuyChange}
                getOptionLabel={(e) => (
                  <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                    {e.icon}
                    <span style={{ marginLeft: '5px' }}>{e.text}</span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className='form-group mt-3'>
            <label>QUANTITY</label>
            <input
              type="number"
              className="form-control"
              placeholder="10 -- 1000"
              id="spend-buy"
              onChange={(e) => { setbuyQuantity(e.target.value) }}
              value={buyQuantity}
            />          </div>
          <div className='totel mt-3'>
            <p>Total: {buyPrice * buyQuantity}</p>
          </div>

          <div className='form-group mt-3'>
            <button type="submit" className="sub-btn" >Add Cart</button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default AddPost;