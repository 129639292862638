import React from 'react'
import LiveChart from './LiveChart';
import TradingViewWidget,{ BarStyles, IntervalTypes, RangeTypes, Themes, } from './Chart/Chart'
import { useParams } from 'react-router-dom';

const Chart = () => {
  const {id} = useParams();

  return (

    <>

    <div className='main-chart'>
        <div className='live-chart'>
          <LiveChart />
        </div>
        <TradingViewWidget symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} />
        
    </div>
    {/* <div className='main-buy_sell'>
        <BuySell />
    </div> */}
    
    </>
  )
}

export default Chart