import React, { useEffect, useState } from 'react'
import { testFun } from '../config/config';

const Test = () => {
    const [inputValue, setInputValue] = useState('');
    const [secInput, setsecInput] = useState('');
    const [price, setprice] = useState(20);


    const testFun = (e,state) => {
        var second = (e); //
        const match = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                } else if (match[1]?.length === 2) {
                    state(second);
                } else if (match[1]?.length === 1) {
                    state(second);
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if(second <= '0' || second <= '0.'){
                    state(second);
                }else{
                    state(second.replace(/^0+/, '')); //
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }

    useEffect(() => {

    },[inputValue,secInput])

    const firstFun = (e) => {
        // testFun(e,);
        setInputValue(e)
        var newVar = (e * price);
        console.log('newVar',newVar);
        const match = ('' + newVar).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (match[1]?.length > 0) {

        setsecInput(newVar?.toFixed(3))
        }else{
            setsecInput(newVar)

        }
        // testFun(newVar,setsecInput)



    }
    


    return (
        <div className='p-5 m-5'>
            <form>
                <input type="text" className='number-only' value={inputValue} onChange={(e) => testFun(e.target.value,firstFun)} />
                <br />
                <br />
                <input type="text" className='number-only' value={secInput} onChange={(e) => testFun(e.target.value,setsecInput)} />

                {/* Render the rest of your component... */}
            </form>
        </div>
    );
}

export default Test

// _.isFloat( value );

// (12.3456).getDecimals() // 4
