import React from 'react'
import { useParams } from 'react-router-dom';
import TradingManualChart from './Chart/TradingManualChart';

const Chart = (props:any) => {
  const {id} = useParams();

  return (

    <>

    <div className='main-chart Manual__Chart'>
        {/* <div className='live-chart'>
          <LiveChart />
        </div> */}
        <TradingManualChart symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} LoadApi={props?.LoadApi}  setLoadApi={props.setLoadApi}/>
        
    </div>
    {/* <div className='main-buy_sell'>
        <BuySell />
    </div> */}
    
    </>
  )
}

export default Chart