import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const LiveChart = (props:any) => {
    const { id } = useParams();

    // const [livePrice, setLivePrice] = useState([]);
    const [currentLivePrice, setcurrentLivePrice] = useState(0);
    const [oldLivePrice, setoldLivePrice] = useState(0);
    const [previewPrice, setpreviewPrice] = useState(0);
    const [countPreviewPrice, setcountPreviewPrice] = useState(1);
    const [currentVolumn, setcurrentVolumn] = useState(0);
    const [hourlyDownUp, sethourlyDownUp] = useState([{ nowHigh: 0, nowLow: 0 }]);
    const [changePercentage, setchangePercentage] = useState([{ p: 0, P: 0 }]);
    const [textClas, settextClas] = useState('text-green');




    useEffect(() => {
        // const nowStream = `${props.symbol.toLowerCase()}@ticker`;
        const nowStream = `${props?.symbol?.toLowerCase()}usdt@ticker`;

        const streams = ['@ticker', '@depth20', '@trade'];
        const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            try {
                if ((json)) {
                    // console.log(json.stream)

                    if (json.stream === nowStream) {
                        if (json) {
                            var d = json.data;
                            if (d.e === '24hrTicker') {
                                let priceN = parseFloat(d.c);
                                let priceL = parseFloat(d.b);
                                let pricenowHigh = parseFloat(d.h);
                                let pricenowLow = parseFloat(d.l);
                                let currentVolumn = parseFloat(d.v);
                                let currentp = parseFloat(d.p);
                                let currentP = parseFloat(d.P);
                                if (priceN > 0 && currentLivePrice !== priceN) {
                                    if (currentLivePrice == 0) {
                                        setcurrentLivePrice(priceN);
                                        setpreviewPrice(priceN);
                                        setoldLivePrice(priceL);
                                        sethourlyDownUp([{ nowHigh: pricenowHigh, nowLow: pricenowLow }]);
                                        setchangePercentage([{ p: currentp, P: currentP }]);
                                        setcurrentVolumn(currentVolumn);
                                    } else {
                                        setTimeout(() => {
                                            if (countPreviewPrice === 1) {
                                                if (priceN > currentLivePrice && Number(currentLivePrice) !== Number(priceN)) {
                                                    setpreviewPrice(priceN);
                                                }
                                            } else {
                                                setcountPreviewPrice(0);
                                            }
                                            // setTimeout(() => {

                                            // }, 10000)
                                            if (Number(currentLivePrice) != Number(priceN)) {
                                                setcurrentLivePrice(priceN);
                                            }
                                            setoldLivePrice(priceL);
                                            setcurrentVolumn(currentVolumn);
                                            setchangePercentage([{ p: currentp, P: currentP }]);
                                            sethourlyDownUp([{ nowHigh: pricenowHigh, nowLow: pricenowLow }]);
                                            if (currentLivePrice >= previewPrice) {
                                                settextClas('text-green');
                                            } else {
                                                settextClas('text-danger');
                                            }

                                        }, 10000)
                                    }

                                }
                            }

                        }
                    }
                }
            } catch (err) {
                // console.log(err);
            }
        };



    }, [])



    const [RightSIdebar, setRightSIdebar] = useState(false);
    const onOpenRightSIdebar = () => setRightSIdebar(true);
    const onCloseRightSIdebar = () => setRightSIdebar(false);

    const togglerBarRemove = () => {
        const aRemove: HTMLElement | any = document.body;
        aRemove.classList.remove("show_header__mobail");
    };
    return (
        <div className='main-livechart'>
            <div className='inner'>
                <div className='busd'>
                    <h2>{id?.toUpperCase()}/USDT</h2>
                </div>
                <div className='n1'>
                    <span className='grn'>{currentLivePrice} </span>
                    <span className='sz'>${oldLivePrice}</span>
                </div>
                <div className='n2'>
                    <span>24h Change</span>
                    <span className='grn sz'>{`${(changePercentage[0].p).toFixed(1)} +${(changePercentage[0].P).toFixed(2)}%`}</span>
                </div>
                <div className='n3'>
                    <span>24h High</span>
                    <span className='grn sz' style={{ textAlign: "center" }}>{hourlyDownUp[0].nowHigh}</span>
                </div>
                <div className='n4'>
                    <span>24h Low</span>
                    <span className='rd sz'>{hourlyDownUp[0].nowLow}</span>
                </div>
                <div className='n5'>
                    <span>Volume</span>
                    <span className='sz'>{currentVolumn.toFixed(1)}</span>
                </div>
            </div>

            {/* <div className='inner2'>
            <div className='tut'>
                <p><PlayCircle /> Spot Tutorial</p>
            </div>
            <div className='spot'>
                <p><PlaylistAddCheckCircle /> Spot Guidance</p>
            </div> */}
            {/* </div> */}

        </div>
    )
}

export default LiveChart;