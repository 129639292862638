import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _fetch from '../../config/api';
import { api_url, receipt_img } from '../../config/config';
import ReactPaginate from 'react-paginate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import toasted from '../../config/toast';
import { MoreVert } from '@mui/icons-material';

const MyAdsPost = (props) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");

    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allData, setallData] = useState([])
    async function getAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }



        let d = await _fetch(`${api_url}trade/p2p-self-buy-Transactons?page=${BuyItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallData(d?.data.data);
            setbuyRecords(d.total);
        }
    }


    const [sellRecords, setsellRecords] = useState(0);
    const [sellPageCount, setsellPageCount] = useState(0);
    const [sellItemOffset, setsellItemOffset] = useState(1);
    const sellhandlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setsellItemOffset(newOffset);
    };
    const [sellallData, setsellallData] = useState([])


    async function getsellAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }



        let d = await _fetch(`${api_url}trade/p2p-self-sell-Transactons?page=${sellItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setsellallData(d?.data.data);
            setsellRecords(d.total);
        }
    }
    const [ActiveTab, setActiveTab] = useState(0);
    const openPaymetRequest = async (val) => {
        var formData = {
            sellerOrderId: val?.order_id,
            coinID: val?.coin_id,
            symbol: val?.sell_currency,
        }
        var d = await _fetch(`${api_url}trade/p2p-allow-make-payment`, 'POST', formData, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
        } else {
            toasted.error(d.message);
        }
    }

    useEffect(() => {
        // document.title = props.title;
        getsellAllData()
        getAllData()
        if (props?.tab > 0) {
            setActiveTab(1)
        }
    }, [sellItemOffset, BuyItemOffset])


    const [OrderName, setOrderName] = useState("All Order");

    return (
        <>
            <div className='myads-main mt-4 mY__ads_New_dc'>
                <div className='container-lg myads__main__ad'>
                    <span className='left__top__border'></span>
                    <span className='right__top__border'></span>
                    <span className='left__bottom__border'></span>
                    <span className='right__bottom__border'></span>
                    <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>

                        <div className="tab-header-new__tabs">
                            <TabList>
                                <Tab> <div className="buy_tab">Buy</div> </Tab>
                                <Tab> <div className="sell_tab">Sell</div></Tab>
                            </TabList>
                        </div>

                        <TabPanel>
                            <div className='Inner__all__tabs_12'>
                                <Tabs>
                                    <div className="Inner__tabs_ads Inner__tabs_ads__new">
                                        <h3 className='Heading_inner__ads_new'> {OrderName} </h3>
                                        <div className='tab_bropdown'>
                                            <div className='dropdown_tab'>
                                                <button className='dropbtn'>{OrderName} <MoreVert /></button>
                                                <div className='tab_all_order'>
                                                    <TabList>
                                                        <Tab onClick={() => setOrderName("All Order")}>
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            All Order </Tab>
                                                        <Tab onClick={() => setOrderName("Pending Order")} >
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            Pending Order </Tab>
                                                        <Tab onClick={() => setOrderName("Completed Order")} className="react-tabs__tab completed_tabs">
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            Completed order </Tab>
                                                        <Tab onClick={() => setOrderName("Cancel Order")} className="react-tabs__tab cancle_tabs">
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            Cancel Order </Tab>
                                                    </TabList>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <TabList>
                                            <Tab onClick={() => setOrderName("All Order")}>
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                All Order </Tab>
                                            <Tab onClick={() => setOrderName("Pending Order")} >
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Pending Order </Tab>
                                            <Tab onClick={() => setOrderName("Completed Order")} className="react-tabs__tab completed_tabs">
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Completed order </Tab>
                                            <Tab onClick={() => setOrderName("Cancel Order")} className="react-tabs__tab cancle_tabs">
                                                <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span>
                                                Cancel Order </Tab>
                                        </TabList> */}
                                    </div>
                                    <TabPanel>
                                        <div className='my-ads-post'>
                                            <table className="table table-border table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th>Advertiser</th>
                                                        <th>Coin</th>

                                                        <th>Price</th>
                                                        <th>Total Amount(INR)</th>

                                                        <th>Receivable
                                                            {/* <span>|</span> Limits */}
                                                        </th>
                                                        <th>Payment Method</th>
                                                        <th>Seller Order Number</th>

                                                        <th>Transaction ID</th>
                                                        <th>Transaction Image</th>

                                                        <th>Status</th>
                                                        <th>Type</th>

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData?.map((val) => {


                                                        return (
                                                            <>
                                                                {val.order_type === 'buy' &&

                                                                    <tr>
                                                                        <td>
                                                                            <p>{val.buy_currency?.toUpperCase()}</p>

                                                                        </td>
                                                                        <td>{val.amount?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>

                                                                        <td>{val.price?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                                        <td>{(val.price * val.amount)?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>

                                                                        <td>
                                                                            {/* <p>Receivable</p> */}
                                                                            <p>{val.received_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                            {/* <p>Pending</p>
                                                                            <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p> */}
                                                                        </td>
                                                                        <td>
                                                                            <div > {val?.payment_method?.[0]?.type} </div>
                                                                        </td>
                                                                        <td>{val.seller_order_id}</td>
                                                                        <td>{val.trx_id}</td>
                                                                        <td>{val.image ? <img src={receipt_img + val.image} width={200} /> : 'No Image'}</td>

                                                                        <td>{val.order_status === 'paired' ? 'Pending' : val.order_status}</td>

                                                                        <td>
                                                                            {val.order_type}
                                                                        </td>
                                                                        <td>
                                                                            <Link className="btn table-buy-btn" to={`/dashboard/ChatP2p/buy/${val.buyer_order_id}`}> Chat</Link>
                                                                        </td>
                                                                    </tr>
                                                                }


                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>

                                            {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={BuyhandlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={buyRecords / 10}
                                                previousLabel="<"

                                            // renderOnZeroPageCount={null}
                                            />}
                                        </div>
                                    </TabPanel>
                                    <TabPanel> 2 </TabPanel>
                                    <TabPanel> 3 </TabPanel>
                                    <TabPanel> 4 </TabPanel>

                                </Tabs>
                            </div>

                        </TabPanel>

                        <TabPanel>

                            <div className='Inner__all__tabs_12'>
                                <Tabs>
                                    <div className="Inner__tabs_ads Inner__tabs_ads__new">
                                        <h3 className='Heading_inner__ads_new'> {OrderName} </h3>
                                        <div className='tab_bropdown'>
                                            <div className='dropdown_tab'>
                                                <button className='dropbtn'>{OrderName} <MoreVert /></button>
                                                <div className='tab_all_order'>
                                                    <TabList>
                                                        <Tab onClick={() => setOrderName("All Order")}>
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            All Order </Tab>
                                                        <Tab onClick={() => setOrderName("Pending Order")} >
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            Pending Order </Tab>
                                                        <Tab onClick={() => setOrderName("Completed Order")} className="react-tabs__tab completed_tabs">
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            Completed order </Tab>
                                                        <Tab onClick={() => setOrderName("Cancel Order")} className="react-tabs__tab cancle_tabs">
                                                            {/* <span className='left__bottom__border'></span>
                                                <span className='right__bottom__border'></span> */}
                                                            Cancel Order </Tab>
                                                    </TabList>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <TabPanel>
                                        <div className='my-ads-post'>
                                            <table className="table table-border">
                                                <thead>
                                                    <tr>
                                                        <th>Advertiser</th>
                                                        <th>Coin</th>
                                                        <th>Price</th>
                                                        <th>Total Amount(INR)</th>

                                                        <th>Available <span>|</span> Limits</th>
                                                        <th>Payment Method</th>
                                                        <th>Status</th>
                                                        <th>Type</th>
                                                        <th>Order Number</th>

                                                        <th className='text-center'>Payment request</th>

                                                        <th className='text-center'>Action <span>0 Fees</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sellallData?.map((val) => {
                                                        return (
                                                            <>


                                                                {val.order_type !== 'buy' &&
                                                                    <tr>
                                                                        <td>
                                                                            <p>{val.sell_currency?.toUpperCase()}</p>
                                                                        </td>
                                                                        <td>{val.amount?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>

                                                                        <td>{val.price?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>
                                                                        <td>{(val.price * val.amount)?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>

                                                                        <td>
                                                                            <p>Available</p>
                                                                            <p>{val.pending_amount?.toFixed(3)} {val.sell_currency?.toUpperCase()}</p>
                                                                            <p>Limits</p>
                                                                            <p>{val.minimum_limit} {val.sell_currency?.toUpperCase()}</p>
                                                                        </td>
                                                                        <td>
                                                                            {val.payment_method_type?.map((method, key) => {
                                                                                var removeLine = val.payment_method_type?.length;
                                                                                return (
                                                                                    <> {method}{key < removeLine - 2 && "|"}</>
                                                                                );
                                                                            })}
                                                                        </td>
                                                                        <td>{val.order_status}</td>
                                                                        <td>
                                                                            {val.order_type}
                                                                        </td>
                                                                        <td>
                                                                            {val.seller_order_id}
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <button className="btn table-buy-btn" onClick={() => { openPaymetRequest(val) }}> Allow payment</button>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Link className="btn table-buy-btn" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`} state={{ allCoin: val.amount }} > View</Link>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>

                                            {sellRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={sellhandlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={sellRecords / 10}
                                                previousLabel="<"

                                            // renderOnZeroPageCount={null}
                                            />}
                                        </div>
                                    </TabPanel>

                                    <TabPanel> 2 </TabPanel>
                                    <TabPanel> 3 </TabPanel>
                                    <TabPanel> 4 </TabPanel>

                                </Tabs>
                            </div>


                        </TabPanel>
                    </Tabs>

                </div>
            </div>
        </>
    )
}

export default MyAdsPost


// Buy, Sell ko remove karke 3 alag box banane h 1.  2.  3.  [ sabhi me buy & sell ke total order ek sath dikhne chahiye ]