import React, { useEffect, useState } from "react";
import { Table, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import dateFormat from "dateformat";
import ReactPaginate from 'react-paginate';
import { ArrowBackIosNew } from "@mui/icons-material";
import { Link } from "react-router-dom";

function DepositHistory() {

    const [searchedValue, setSearchedValue] = useState("");
    const [searchedType, setSearchedType] = useState("symbol");
    const [tableRecords, settableRecords] = useState([]);
    const [itemOffset, setItemOffset] = useState(1);
    const [dataValues, setDataValues] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [sort, setSort] = useState('created_at');

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setItemOffset(newOffset);
    };

    async function getDepositData() {
        settableRecords([]);
        if (searchedValue !== "") {
            var searchData = `&search=${searchedType}&value=${searchedValue.toString().toLowerCase()}`;
        }
        else {
            var searchData = "";
        }

        const Data = await _fetch(`${api_url}incomes/incomeHistory?sort=${sort}&page=${itemOffset}&type=main_account&limit=10${searchData}`, 'GET', {});
        setDataValues(Data?.data.data);
        setTotalData(Data.total);
    }

    useEffect(() => {
        getDepositData();
    }, [searchedType, searchedValue, sort, totalData, itemOffset])
    return (
        <>

            <div className="main-dashboard">
                <div className="inner-crum coustom_container">
                    <div className="inner-crum_dc">
                        <div className="inner-crum-left">
                            <span>  <Link to="/dashboard/deposit" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link> </span>
                            <h3> History</h3>
                        </div>
                        <Link to="/dashboard" className="More__links"> Dashboard</Link>
                    </div>
                </div>


                <div className="coustom_container pt-5">

                    <div className="history___table w-100">
                        <div className="tab-inner1 top_minus__contianer">

                            <div className="tab-inner">
                                <div className="history__txt">
                                    <h5> Deposit History</h5>
                                </div>

                                <div className="tab-right-sec">
                                    <div className="drop-sec">
                                        <select onChange={(e: any) => { setSearchedType(e.target.value) }} name="search" className="search-detail">
                                            <option value="symbol">Symbol</option>
                                            <option value="hash">Hash</option>
                                        </select>
                                    </div>
                                    <div>
                                        <Form.Control type="search" placeholder="Search" onChange={(e: any) => { setSearchedValue(e.target.value) }} />
                                    </div>
                                </div>
                            </div>

                            <div className="data-table">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th onClick={() => { sort === 'symbol' ? setSort('-symbol') : setSort('symbol') }} style={{ cursor: 'pointer' }} >SYMBOL</th>
                                            <th onClick={() => { sort === 'amount' ? setSort('-amount') : setSort('amount') }} style={{ cursor: 'pointer' }} >VOLUME</th>
                                            <th onClick={() => { sort === 'hash' ? setSort('-hash') : setSort('hash') }} style={{ cursor: 'pointer' }} className="text-center" >HASH</th>
                                            <th onClick={() => { sort === 'remark' ? setSort('-remark') : setSort('remark') }} style={{ cursor: 'pointer' }} className="text-end" >REMARK</th>
                                            <th onClick={() => { sort === 'created_at' ? setSort('-created_at') : setSort('created_at') }} style={{ cursor: 'pointer' }} className="text-end" >CREATED AT</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataValues?.map((val: any, index: any) => {
                                            return (
                                                <>
                                                    <tr key={index} >
                                                        <td >
                                                            <p>{val.symbol.toUpperCase()}</p>
                                                        </td>
                                                        <td>
                                                            <p>{val.amount.toFixed(3)}</p>
                                                        </td>
                                                        <td className="text-center">

                                                            <OverlayTrigger
                                                                placement={"top"}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {val.hash}
                                                                    </Tooltip>
                                                                } >
                                                                <a href={`https://bscscan.com/tx/${val.hash}`} className="inner__links">  <p> {val.hash.substring(0, 10) + '****' + val.hash.substring(val.hash.length - 10, val.hash.length)}</p> </a>
                                                            </OverlayTrigger>
                                                        </td>

                                                        <td className="text-end">
                                                            <p>{val.remark}</p>
                                                        </td>
                                                        <td className="text-end" >{dateFormat(val.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                                    </tr>

                                                </>
                                            )

                                        })
                                        }
                                       
                                        
                                    </tbody>
                                </Table>
                                {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={1}
                                    pageCount={totalData / 10}
                                    previousLabel="<"
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DepositHistory;