import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { Link } from "react-router-dom";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import ReactPaginate from "react-paginate";








const P2pTab = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");
    const [buyFormSearch, setbuyFormSearch] = useState(props.props.search);
    const [ActiveTab, setActiveTab] = useState(0);

    console.log('props', props.props)
    // P2p coins slect 
    const data = [
        {
            value: `bnb`,
            text: <span className="new__a"> BNB</span>,
            // icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `usdt`,
            text: <span className="new__a"> USDT</span>,
            // icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `eth`,
            text: <span className="new__a"> ETH</span>,
            // icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `xrp`,
            text: <span className="new__a"> XRP</span>,
            // icon: <img src={img_icons} className="option-img-a" />
        },
    ];

    // handle onChange event of the dropdown/////////////////////////////////////////////////
    const handleChange = (e) => {
        setSelectedOption(e);
        setSearchedType('sell_currency');
        setsellCoinType(e.value)
    }

    // handle onChange event of the dropdown////////////////////////////////////////////////
    const handleChange2 = (e) => {
        setSelectedOptionA(e);
    }

    ////////////////////////////buy api////////////////////////////////////////////////////
    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allBuyData, setallBuyData] = useState([])
    // async function getAllBuyData() {
    //     if (searchedVal != "") {
    //         var searchData = `&search=${searchedType}&value=${searchedVal}`;
    //     } else {
    //         var searchData = "";
    //     }
    //     if (sellCoinType != "") {
    //         var typeData = `&sell_currency=${sellCoinType}`;
    //     } else {
    //         var typeData = "";
    //     }


    //     let d = await _fetch(`${api_url}trade/p2p-pending-buy-requests?page=${BuyItemOffset}&limit=10${searchData}${typeData}`, 'GET', {}, {});
    //     if (d?.status === 'success') {
    //         setallBuyData(d?.data.data);
    //         setbuyRecords(d.total);
    //     }
    // }
    ////////////////////////////////////////////sell api /////////////////////////////
    const [records, setrecords] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const handlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setItemOffset(newOffset);
    };
    const [allSellData, setallSellData] = useState([])
    async function getAllSellData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }
        if (sellCoinType != "") {
            var typeData = `&sell_currency=${sellCoinType}`;
        } else {
            var typeData = "";
        }
        if (props.props.searchType === true) {
            var formType = props.props.search;
            setActiveTab(0)

        } else {
            var formType = "";
        }
        let d = await _fetch(`${api_url}trade/p2p-pending-sell-requests?page=${itemOffset}&limit=10${searchData}${typeData}${formType}`, 'GET', {}, {});

        if (d?.status === 'success') {
            setrecords(d.total);
            setallSellData(d?.data.data);
        }
    }
    useEffect(() => {
        // getAllBuyData();
        getAllSellData();
    }, [searchedVal, itemOffset, sellCoinType, BuyItemOffset])

    return (
        <div>
            <div className='p2p-div'>
                <div className="p2p-scroll-div">
                    <div className="INNer___Table">
                        <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>
                            <div className="p2p-header">
                                <div className='tab-list'>
                                    <TabList>
                                        <Tab><div className="buy_tab">Buy</div></Tab>
                                        <Tab><div className="sell_tab">Sell</div></Tab>
                                    </TabList>
                                </div>
                                {/* <div className='coin-search'>
                                    <Select
                                        placeholder="Coins"
                                        value={selectedOption}
                                        className="select-option-s"
                                        options={props.market}
                                        onChange={handleChange}
                                        getOptionLabel={(e) => (
                                            <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                                {e.icon}
                                                <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                            </div>
                                        )}
                                    />
                                </div> */}

                                {/* <div className="enter-amount-form">
                                    <form>
                                        <div className="form-control-1 ">
                                            <input type="number" placeholder="Enter Amount" />
                                            <div className="select-drop">
                                                <Select
                                                    placeholder="Coins"
                                                    value={selectedOptionA}
                                                    className="select-option-s"
                                                    options={props.market}
                                                    onChange={handleChange2}
                                                    getOptionLabel={(e) => (
                                                        <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                                            {e.icon}
                                                            <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                                        </div>
                                                    )}
                                                />
                                            </div>

                                        </div>
                                    </form>
                                </div> */}
                                

                                <div className="all-payment-method">
                                    <select onChange={(e) => { setSearchedType('payment_method'); setSearchedVal(e.target.value) }}>
                                        <option value="">All Payment Methods</option>
                                        <option value="bank">Bank</option>
                                        <option value="gpay">Google Pay</option>
                                    </select>
                                </div>

                                {/* <div className="all-payment-method">
                                    <select onChange={(e) => { setSearchedType('payment_method'); setSearchedVal(e.target.value) }}>
                                        <option value="">All Payment Methods</option>
                                        <option value="bank">Bank</option>
                                        <option value="gpay">Google Pay</option>
                                    </select>
                                </div>

                                <div className="all-payment-method">
                                    <select onChange={(e) => { setSearchedType('payment_method'); setSearchedVal(e.target.value) }} className="Select__area">
                                        <option value="">All Payment Methods</option>
                                        <option value="bank">Bank</option>
                                        <option value="gpay">Google Pay</option>
                                    </select>
                                </div> */}

                                <div class="refresh-btn"><button type="button" class="btn" onClick={() => window.location.reload()} ><RefreshOutlinedIcon />Refresh</button></div>
                            </div>
                            <TabPanel className="tab-inner">

                                <table className="table table-border">
                                    <thead>
                                        <tr>
                                            {/* <th>Advertiser  </th> */}
                                            {/* <th className="text-end">Action <span class="fee-btn">0 Fees</span></th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {allSellData?.map((val) => {
                                            return (
                                                <tr>
                                                    <td>

                                                        <div className="N__12 N__D_Value">
                                                           <h6 className="mb-0"> {val.sell_currency?.toUpperCase()}</h6>
                                                           <div className="btn_hit_p2p text-center w-100 ">
                                                                    <Link to={`/Dashboard/p2p/transaction/${val._id}`} className="trade_btn active"> Buy </Link>
                                                                </div>                                                        </div>

                                                        <div className="N__13">
                                                            <div className=" N__D_Value"> 
                                                                <h6 className="mb-0"> Total</h6>
                                                                <span className="ms-2">{val.amount?.toFixed(3)} </span>
                                                            </div>

                                                            <div className=" N__D_Value"> 
                                                                <h6 className="mb-0"> Price</h6>
                                                                <span className="ms-2">{val.price?.toFixed(3)} </span>
                                                            </div>

                                                            <div className="N__D_Value"> 
                                                                <h6 className="mb-0"> Available</h6>
                                                                <span className="ms-2">{val.pending_amount?.toFixed(3)} </span>
                                                            </div>

                                                            <div className="N__D_Value"> 
                                                                <h6 className="mb-0"> Limits</h6>
                                                                <span className="ms-2">{val.minimum_limit?.toFixed(3)} {val.sell_currency?.toUpperCase()}</span>
                                                            </div>

                                                            <div className="N__D_Value"> 
                                                                <h6 className="mb-0"> Payment Method</h6>
                                                                    <div className="p_method">
                                                                        {val?.payment_method_type?.map((method_val,key) => {
                                                                            var removeLine = val?.payment_method_type?.length;
                                                                            return (<div className="mad-btn"> {method_val}{key > removeLine - 2?"" : '|'}</div>)
                                                                        })}

                                                                    </div>
                                                            </div>

                                                            <div className="N__D_Value__s"> 
                                                               
                                                            </div>

                                                        </div>

                                                       
                                                    </td>
                                                    {/* <td> */}
                                                        {/* <span className="me-2">Received</span> */}
                                                       
                                                        {/* <div className="History___p2p__ad">
                                                            <div className="History___p2p__ad-data">
                                                                <p>Available</p>
                                                                <p>{val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                            </div>
                                                            <div className="History___p2p__ad-data">
                                                                <p>Limits</p>
                                                                <p>{val?.minimum_limit} ~ {val?.pending_amount}</p>
                                                            </div>

                                                        {/* </div> */}
                                                    {/* </td> */}
                                                    
                                                    {/* <td>
                                                        <div className="btn_hit_p2p">
                                                            <Link to={`/Dashboard/p2p/transaction/${val._id}`} className="trade_btn active"> Buy </Link>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {records > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={1}
                                    pageCount={records / 10}
                                    previousLabel="<"

                                // renderOnZeroPageCount={null}
                                />}
                            </TabPanel>
                            <TabPanel className="tab-inner">

                                <table className="table table-border mt-3">
                                    <thead>
                                        <tr>
                                            <th>Advertiser</th>
                                            <th>Price</th>
                                            <th>Available <span>|</span> Limits</th>
                                            <th>Action <span>0 Fees</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allBuyData?.map((val) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="p2p-TD_1">
                                                            <div className="user__info__p2p">
                                                                <p>{val.buy_currency?.toUpperCase()}</p>
                                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{val.price} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                    <td>
                                                        <div className="History___p2p__ad">
                                                            <div className="History___p2p__ad-data">
                                                                <p>Received</p>
                                                                <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p>
                                                            </div>
                                                            

                                                        </div>
                                                    </td>
                                                   
                                                    <td>
                                                        <div className="btn_hit_p2p">
                                                            <Link className="trade_btn active" to={`/Dashboard/p2p/transaction/${val._id}`}> Sell</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={BuyhandlePageClick}
                                    pageRangeDisplayed={1}
                                    pageCount={buyRecords / 10}
                                    previousLabel="<"

                                />}
                            </TabPanel>




                        </Tabs>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default P2pTab
