import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useParams } from 'react-router-dom';
import _fetch from '../../config/api';
import BuyMarket from './BuySellAll/BuyMarket';
import SellMarket from './BuySellAll/SellMarket';
import BuyLimit from './BuySellAll/BuyLimit';
import SellLimit from './BuySellAll/SellLimit';

const BuySell = (props: any) => {


    const { id } = useParams()
    let [buymarketTab, setbuymarketTab] = useState('');
    let [buyStopLimitTab, setbuyStopLimitTab] = useState('');

    let [activeInnerTab, setactiveInnerTab] = useState('market');
    // let [activeTabLimit, setactiveTabLimit] = useState('stop-limit');


    let tradingTab = (tab: string) => {
        // tab.currentTarget.classList.add("active");
        if (window.screen.width! <= 1200) {
            // window.location.reload();
            setactiveInnerTab(tab)
            tab === 'market' ? setbuymarketTab('block') : setbuymarketTab('none');
            tab === 'stop-limit' ? setbuyStopLimitTab('block') : setbuyStopLimitTab('none');
        }
    };


  


    return (
        <>
            <div className='main-buysell'>
                <div className='inner'>
                    <div className='tabs-main'>
                        <Tabs>
                            <div className='header-but-sell'>
                                <TabList>
                                    <Tab>Market</Tab>
                                    <Tab className="sell react-tabs__tab" >Stop Limit</Tab>
                                </TabList>
                            </div>

                            <TabPanel>
                                <div className='inner-tabs'>
                                    <Tabs>
                                        {/* <TabList>
                                            <Tab>Market</Tab>
                                            <Tab>Stop Limit</Tab>
                                        </TabList> */}

                                        <div className='justify-content-left align-items-center inner_tab__buy_sell'>
                                            <div className={activeInnerTab == 'market' ? "heading buy__btn" : "heading "} onClick={() => tradingTab('market')} ><p>Buy Market</p></div>
                                            <div className={activeInnerTab == 'market' ? "heading" : "heading sell_btn"} onClick={() => tradingTab('stop-limit')}><p> Sell Market </p></div>
                                        </div>

                                        <TabPanel>

                                            <div className='main-market'>
                                                {/* BUY MARKET.. */}
                                                <BuyMarket buymarketTab={buymarketTab} />


                                                {/* SELL MARKET... FILE... */}
                                                <SellMarket buyStopLimitTab={buyStopLimitTab} />
                                            </div>
                                        </TabPanel>

                                    </Tabs>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='inner-tabs'>
                                    <Tabs>


                                        <div className='justify-content-left align-items-center inner_tab__buy_sell'>
                                            <div className={activeInnerTab == 'market' ? "heading buy__btn" : "heading "} onClick={() => tradingTab('market')} ><p> Buy Stop Limit</p></div>
                                            <div className={activeInnerTab == 'market' ? "heading" : "heading sell_btn"} onClick={() => tradingTab('stop-limit')}><p> Sell Stop Limit</p></div>
                                        </div>

                                        <TabPanel>
                                            <div className='main-market2'>

                                                {/* BUY STOP LIMIT SEC... */}
                                                <BuyLimit buymarketTab={buymarketTab} />


                                                {/* SELL STOP LIMIT SEC.. */}
                                                <SellLimit buyStopLimitTab={buyStopLimitTab}/>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuySell