import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import CancelIcon from '@mui/icons-material/CancelRounded';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from "../../config/toast";
import { ClipLoader } from 'react-spinners'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { Fade } from "react-awesome-reveal";
import LoginSide from '../Templates/LoginSide';

const VerifyLogin = () => {

    return (
        <div>
            <div className="Login-sec only-margin">
                <div className="Stater-sec align-items-center">
                    <LoginSide />

                    <div className="left">

                        <div className="inner">
                            <h1>Verify</h1>
                            <div className="inner-sec">
                                <form className="form">
                                    <div className="inputs">
                                        <input className='inputt' type="tel" maxLength={1} />
                                        <input className='inputt' type="tel" maxLength={1} />
                                        <input className='inputt' type="tel" maxLength={1} />
                                        <input className='inputt' type="tel" maxLength={1} />
                                        <input className='inputt' type="tel" maxLength={1} />
                                        <input className='inputt' type="tel" maxLength={1} />
                                    </div>
                                    <div className="login__button">

                                        <button className='button' type='button'>
                                            <div className="service service1">
                                                <div className="front">
                                                    <h4>Verify</h4>
                                                </div>
                                                <div className="back">
                                                    <h4>Submit</h4>
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                </form>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default VerifyLogin
