import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import _fetch from '../../../config/api';
import { api_url } from '../../../config/config';
import toasted from '../../../config/toast';
import { useParams } from 'react-router-dom';


const SellLimit = (props: any) => {

    const { id } = useParams()
    const [buyStopPriceAt, setbuyStopPriceAt] = useState(0);
    const [coinCurrentPrice, setcoinCurrentPrice] = useState(0);
    const [amount, setamount] = useState(0);
    const [usdAmount, setusdAmount] = useState(0);
    const [currentId, setCurrentId] = useState('')
    const [btnDisable, setbtnDisable] = useState(false);
    const [SellbtnDisable, setSellbtnDisable] = useState(false);

   

    async function SellCoin(e: any) {
        setSellbtnDisable(true);

        e.preventDefault();
        const limit = {
            amount: amount,
            sellCurrency: id?.toLowerCase(),
            sell_coin_id: currentId,
            limit_price: buyStopPriceAt,
            trade_type: (buyStopPriceAt > 0) ? 'limit' : 'market',
            symbol: id?.toLowerCase()
            // chain: 'bep20'
        }

        let sell = await _fetch(`${api_url}trade/sell-trade`, 'POST', limit, {});
        if (sell?.status === 'success') {
            toasted.success(sell?.message);
            props.setLoadApi(props.LoadApi === 0 ? 1 : 0)
            // CoinInfo()
            // SaleCoinInfo()
            // onCloseSellModal()
            // onCloseSellStopModal();
            setSellbtnDisable(false);

        } else {
            toasted.error(sell?.message);
            setSellbtnDisable(false);

        }
    }

    // const [oderCurrentPrice, setOrderCurrentPrice] = useState()
    // const [currentId, setCurrentId] = useState('')
    const [USDTBallanceShow, setUSDTBallanceShow] = useState(0)
    const [coinBallanceShow, setCoinBallanceShow] = useState(0)

    async function CoinInfo() {
        var d = await _fetch(`${api_url}markets/getSingleTradeMarket?type=trade_account&symbol=${id?.toLowerCase()}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setcoinCurrentPrice(d?.market[0].current_price);
            // props.settradeCoinPrice(d?.market[0].current_price)
            // setCoinBallanceShow(d?.market[0].usdt_income);
            let f = Number(d?.market[0].total_coin).toFixed(3)
            setCoinBallanceShow(parseFloat(f));

            setCurrentId(d?.market[0].coin_id);
            // setOrderCurrentPrice(d?.market[0].current_price)
        }
    }
   
    async function SaleCoinInfo() {
        var d = await _fetch(`${api_url}markets/getElpSingleMarketByType?type=trade_account&symbol=usdt`, 'GET', {}, {});
        if (d?.status === 'success') {
            // setSaleId(d?.market[0].coin_id);
            // setUSDTBallanceShow(d?.market[0].usdt_income);
            let f = Number(d?.market[0].usdt_income).toFixed(3)
            setUSDTBallanceShow(parseFloat(f));
            // setSaleSymbol(d?.market[0].symbol);
        }
    }




    useEffect(() => {
        CoinInfo()
        SaleCoinInfo()
    }, [id])




    ///////////// LIMIT calculation///////////////////
    const buyLimitPercentage = (buyPercentage: any) => {
        const float = Number(parseFloat(buyPercentage).toFixed(3));
        const totalUSDPercentage = (Number(coinBallanceShow) * float / 100).toFixed(3);
        const main2 = Number(parseFloat(totalUSDPercentage) * buyStopPriceAt).toFixed(3);

        setamount(parseFloat(totalUSDPercentage));
        setusdAmount(parseFloat(main2));
    }

   

    const changePrice = (e: any, state: any) => {
        var second = (e); //
        const match: any = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                } else if (match[1]?.length === 2) {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                } else if (match[1]?.length === 1) {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if (second <= '0' || second <= '0.') {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                } else {
                    state(second.replace(/^0+/, '')); //
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }


    const changeAmount = (e: any, state: any) => {
        var second = (e); //
        const match: any = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                } else if (match[1]?.length === 2) {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                } else if (match[1]?.length === 1) {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if (second <= '0' || second <= '0.') {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                } else {
                    state(second.replace(/^0+/, '')); //
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }
    const totalAmount = (e: any, state: any) => {
        var second = (e); //
        const match: any = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                } else if (match[1]?.length === 2) {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                } else if (match[1]?.length === 1) {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if (second <= '0' || second <= '0.') {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                } else {
                    state(second.replace(/^0+/, '')); //
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }


    return (
        <>
            <div className="d-mobail-none-buy  data_right" style={{ display: props.buyStopLimitTab }}>
                {/* <div className="heading only-for-lg"><p>Stop Limit</p></div> */}
                <div className='balance'>
                    <p>Available <span>{coinBallanceShow}</span> {id?.toUpperCase()} </p>
                    <div className='input_price'>
                        <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                        <input  type='text' onChange={(e: any) => { changePrice(e.target.value, setbuyStopPriceAt) }} value={buyStopPriceAt} />
                        <span>STOP PRICE</span>
                        <span>USDT</span>
                    </div>
                    {/* <div className='input_price'>
                                                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                                                            <input type='number' onChange={(e: any) => { onCurrentPrice(e) }} value={coinCurrentPrice} />
                                                            <span>USDT</span>
                                                        </div> */}

                    <div className='input_price'>
                        <span style={{ color: "rgb(132, 142, 156)" }}>Amount</span>
                        <input type='text' onChange={(e: any) => { changeAmount(e.target.value, setamount) }} value={amount}/>
                        <span>{id?.toUpperCase()}</span>
                    </div>

                    <div className='range'>
                        <div className='percentage'>
                            {/* <div className='items'>0%</div> */}
                            <div className='items' onClick={() => { buyLimitPercentage(25) }}>25%</div>
                            <div className='items' onClick={() => { buyLimitPercentage(50) }}>50%</div>
                            <div className='items' onClick={() => { buyLimitPercentage(75) }}>75%</div>
                            <div className='items' onClick={() => { buyLimitPercentage(100) }}>100%</div>
                        </div>
                        <hr></hr>
                    </div>

                    <div className='input_price'>
                        <span style={{ color: "rgb(132, 142, 156)" }}>Total</span>
                        <input type='text' value={usdAmount} onChange={(e: any) => { totalAmount(e.target.value, setusdAmount) }}  />
                        <span>USDT</span>
                    </div>

                    <div className='buy-btn'>
                        <Button variant='primary' onClick={SellCoin} disabled={SellbtnDisable}>

                            {SellbtnDisable === true &&
                                <div className="color-ring">
                                    <ClipLoader color="#36d7b7" />

                                    Please Wait...
                                </div>
                            }
                            {SellbtnDisable === false ? `SELL` : ''}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellLimit