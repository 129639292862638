import React, { useEffect, useState } from "react";
import { ArrowBackIosNew, KeyboardArrowRight, FilePresent, TextSnippet, SwapVert } from "@mui/icons-material";
import Select from 'react-select';
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { ClipLoader } from 'react-spinners'
import { api_url, shortTitle } from "../config/config";
import _fetch from "../config/api";
import toasted from "../config/toast";




const Swap = () => {

  const [coinDetail, setcoinDetail] = useState([]);
  const [tokenDetail, settokenDetail] = useState([]);

  const [amount, setAmount] = useState(0);
  const [btnDisable, setbtnDisable] = useState(false);

  const auth = localStorage.getItem("auth");
  const SetLinkBg = () => {
    if (auth !== "true") {
      return (
        <>
          <Link to="/" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
        </>
      )
    }
    else {
      return (
        <>
          <Link to="/dashboard" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
        </>
      )
    }
  }




  async function getMarketdata() {
    var d = await _fetch(`${api_url}markets/getSingleTradeMarket?type=main_account&symbol=sta`, 'GET', {}, {});
    if (d?.status === 'success') {
      settokenDetail(d?.market[0]);

    }

  }
  const [tokenbalance, settokenbalance] = useState(0)

  ////1 mean in api is main account / 2 mean in api is funding accout / 3 mean in api treading ACCOUNT//////
  async function getCoinBalance() {
    var d = await _fetch(`${api_url}stake/token-balance`, 'POST', { coinID: 'elp_market', symbol: 'sta', type: 1 }, {});
    if (d?.status === 'success') {
      settokenbalance(d?.data?.balance);

    }

  }


  async function getCoinMarketdata() {

    var d = await _fetch(`${api_url}markets/getSingleTradeMarket?type=main_account&symbol=stacoin`, 'GET', {}, {});
    if (d?.status === 'success') {
      setcoinDetail(d?.market[0]);

    }
  }

  useEffect(() => {
    getMarketdata();
    getCoinBalance()
    getCoinMarketdata()
  }, []);



  async function SubmitForm() {
    setbtnDisable(true)

    let submitData = {
      amount: amount,
      coinID: tokenDetail?.coin_id,
      symbol: tokenDetail?.symbol,
    }

    let data = await _fetch(`${api_url}stake/swap-token`, 'POST', submitData);
    if (data?.status === 'success') {
      toasted.success(data?.message);
      getCoinBalance();
      setbtnDisable(false)
    }
    else {
      toasted.error(data?.message);
      setbtnDisable(false)


    }
  }





  // function switch input fields



  return (
    <>
      <div className="main-deposit New__add_swap">
        <div className="inner-crum coustom_container">
          <div className="inner-crum_dc">
            <div className="inner-crum-left">
              <span> {SetLinkBg()} </span>
              <h3>Swap</h3>
            </div>
            <Link to="/dashboard/swap-history" className="More__links"> Swap History</Link>
          </div>
        </div>

        <div className="form-sec">
          <div className="inner-sec coustom_container">
           
            <div className="middle-sec">
              <div className="form-main">
                <form>
                  <div className="spot-account withdrawl-amount">
                    <div className="tooltip-sec">
                      <p>Your Balance is: {tokenbalance?.toFixed(3)} STA</p>
                      <div className="tooltip-sec-space">
                        <span>Amount</span>
                      </div>
                    </div>
                    <div className="withdraw-sec">
                      <div className="input-sec">
                        <input type="number" placeholder="Enter Swap amount.." onChange={(e) => { setAmount(e.target.value) }} value={amount} />
                      </div>
                      <div><span>|</span></div>
                      <div className="link2 "><span><Button onClick={() => { setAmount(tokenbalance) }} className="max btn-success">MAX</Button></span></div>
                    </div>
                    <span><p className="mt-3 mb-0">Total Receive: {coinDetail?.current_price > 0 ? ((tokenDetail?.current_price * amount) / coinDetail?.current_price) : ''}</p></span>

                  </div>

                  {/* <div className="auto-bor">
                    <span><Toggle /></span>
                    <span><h5>Auto Borrow</h5></span>
                  </div> */}
                  {/* <Button className="confirm-btn" variant="success" onClick={SubmitForm} disabled={btnDisable}>

                    {btnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Submit' : ''}
                  </Button> */}


                  <Button className="confirm-btn" variant="success" disabled={true}>

                    Coming soon
                  </Button>
                </form>

              </div>
            </div>

          </div>
        </div>


      </div>

    </>
  );
};

export default Swap;
