import React, { useEffect, useState } from "react";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import DashboardDataTabs from "./DashboardDataTabs";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import { Link } from "react-router-dom";

const Dashboard = () => {

  const [getSpotBalance, setGetSpotBalance] = useState({ main_account: 0, funding: 0, trading: 0 });

  async function getSpotTotalBalance() {
    let spotBalanceData = await _fetch(`${api_url}incomes/portfolio`, 'GET', {}, {});
    setGetSpotBalance({ main_account: spotBalanceData.data[1].total_amount, funding: spotBalanceData.data[2].total_amount, trading: spotBalanceData.data[0].total_amount });
  }

  const [userInfo,setuserInfo] = useState({phone:'',email:''})

  useEffect(() => {
    getSpotTotalBalance();


      (async () => {
        const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", "", {});
        if (data1?.data?.data) {
          console.log(data1?.data?.data);
          setuserInfo(data1?.data?.data)
        }
      })();
    
  }, [])

  const [showAssets, setShowAssets] = useState(false);


  return (
    <>
      <div className="main-dashboard">
        <div className="main-balance  coustom_container">

          <div className="card__bal__txt__card">
            <div className="bal-txt">
              <div className="bal__common bal_txt_left">
                <div className="height__70">
                  <div className="user_name_1">
                    <p className="user_name">Hello,<span> {userInfo?.email ? userInfo.email:userInfo?.phone}</span></p>
                    <div className="eye__icon">
                      {showAssets ?
                        <VisibilityTwoTone onClick={() => { setShowAssets(!showAssets) }} className="pointer" />
                        :
                        <VisibilityOffTwoTone onClick={() => { setShowAssets(!showAssets) }} className="pointer" />
                      }
                    </div>
                  </div>
                  <h5>Estimated Balance</h5>

                  <div className="main-data">
                    {showAssets ?
                      <>
                        <h3>{(getSpotBalance?.main_account + getSpotBalance?.trading + getSpotBalance?.funding)?.toFixed(4)} USDT</h3>
                      </>
                      :
                      <>
                        <h3><span className="star__span">****</span> USDT</h3>
                      </>
                    }
                  </div>
                </div>


                <div className="btn-sec">
                  <Link className="btn2 accounthistory_btn" to={'/dashboard/deposit'}> Deposit </Link>
                  <Link className="btn2 accounthistory_btn" to={'/dashboard/withdraw'}>Withdraw</Link>
                  <Link className="btn2 accounthistory_btn" to={'/dashboard/transfer'}>Transfer</Link>
                  <Link className="btn2 accounthistory_btn" to={'/dashboard/swap'}>  Swap</Link>
                </div>

              </div>

              <div className="bal__common bal_txt_right">
                <h5>My Assets</h5>

                <div className="card__assets">

                  <div className="card__assets__left">
                    <p>Main Account </p>
                  </div>

                  {/* {showAssets ?
                    <> */}
                      <h5>{getSpotBalance.main_account?.toFixed(4)} USDT</h5>
                    {/* </>
                    :
                    <>
                      <h5><span className="star__span"> ****</span> USDT</h5>
                    </>
                  } */}

                  <Link to="/dashboard/account-record/main_account" className="report__button"> Report</Link>
                </div>


                <div className="card__assets">

                  <div className="card__assets__left">
                    <p>Funding Account </p>
                  </div>


                  {/* {showAssets ?
                    <> */}
                       <h5>{getSpotBalance.funding?.toFixed(4)} USDT</h5>
                    {/* </>
                    :
                    <>
                      <h5><span className="star__span" > ****</span> USDT</h5>
                    </>
                  } */}
                  <Link to="/dashboard/account-record/funding_account" className="report__button"> Report</Link>
                </div>


                <div className="card__assets">

                  <div className="card__assets__left">
                    <p>Trading Account </p>
                  </div>
                  {/* {showAssets ?
                    <> */}
                      <h5> {getSpotBalance.trading?.toFixed(4)}  USDT</h5>
                    {/* </>
                    :
                    <>
                      <h5> <span className="star__span" > ****</span> USDT</h5>
                    </>
                  } */}

                  <Link to="/dashboard/account-record/trade_account" className="report__button"> Report</Link>
                </div>

              </div>







            </div>
          </div>


          <DashboardDataTabs />
        </div>

      </div>
    </>
  );
};

export default Dashboard;
