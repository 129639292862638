import { KeyboardArrowRight, PlaylistAdd, SpeakerNotes, VisibilityOff, VisibilityOffTwoTone, VisibilityTwoTone } from '@mui/icons-material'
import { Button, Card, Col, Row, Table, Form } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useEffect, useState } from 'react';
import { api_url, logo, title } from '../../config/config';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import staking__img from "../../Assets/Img__v2/staking__s.png"
import buyCrypto_img from "../../Assets/Img__v2/buy_crypto.png"

const Staking = () => {

    const { id } = useParams();

    const [balanceDataArray, setBalanceDataArray] = useState([
        {
            year: "1",
            month: "12",
            durationPeriod: "5",
            min: "10",
            max: "5000",
            percentage: "3",
        },
        {
            year: '1.5',
            month: "18",
            durationPeriod: "6",
            min: "10",
            max: "10000",
            percentage: "4",
        },
        {
            year: "2",
            month: "24",
            durationPeriod: "7",
            min: "10",
            max: "25000",
            percentage: "5",
        },
        {
            year: "3",
            month: "36",
            durationPeriod: "8",
            min: "10",
            max: "50000",
            percentage: "6",
        },


    ]);

    const [balanceDataKey, setBalanceDataKey] = useState(0);
    const [modalData, setModalData] = useState({
        year: 0,
        durationPeriod: "",
        min: "",
        max: "",
        month: 0,
        percentage: 0,
    });

    const [open, setOpen] = useState(false);
    const [amount, setAmount] = useState(0);

    const onOpenModal = (item: any) => {
        setOpen(true);
        setModalData(item);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const [showAssets, setShowAssets] = useState(false);
    const [balance, setbalance] = useState(0);
    const [load, setload] = useState(0);
    const [coinId, setcoinId] = useState('');

    useEffect(() => {
        CoinInfo();
    }, [load]);

    const StakeBalance = async (coinID: any, symbol: any) => {
        let data = await _fetch(`${api_url}stake/stake-balance`, 'POST', { coinID: coinID, symbol: symbol }, {});
        if (data?.status === 'success') {
            setbalance(data?.data?.currency);
        }
    }

    const CoinInfo = async () => {
        var d = await _fetch(`${api_url}markets/getSingleTradeMarket?type=trade_account&symbol=${id?.toLowerCase()}`, 'GET', {}, {});
        if (d?.status === 'success') {
            const val = d.market?.[0];
            StakeBalance(val.coin_id, val.symbol);
            setcoinId(val.coin_id);
        }
    }

    const submitStake = async () => {
        let data = await _fetch(`${api_url}stake/stake-amount`, 'POST', { stakeAmount: amount, coinID: coinId, symbol: id?.toLowerCase(), peroid: balanceDataKey }, {});
        if (data?.status === 'success') {
            setload(load === 0 ? 1 : 0);
            toasted.success(data.message);
            setTimeout(() => {
                onCloseModal();
            }, 5000);

        } else {
            toasted.error(data.message);
        }
    }

    return (
        <>
            <div className='Staking__sec__adfa'>
                <div className='staking_banner'>
                    <div className="coustom_container">
                        <Row>
                            <Col md={6} >
                                <div className='Main__continer'>
                                    <div className='card_left_cards'>
                                        <h1 className='banner_heading_staking'>Accrue rewards with {title?.toLocaleLowerCase()} Staking</h1>
                                        <p className='banner_subHeading_staking'> With {title?.toLocaleLowerCase()} Staking, you can participate in the blockchain ecosystem <br/> and receive staking rewards on your crypto.</p>
                                    </div>

                                    <Card className='staking_card_right'>
                                        <div className='card_top_staking'>
                                            <div className='staking_banrer'>
                                                <div className=''>
                                                    <div className='Assets__side__staking'>
                                                        Assets ({id?.toUpperCase()})
                                                        <span>  <button type="button" className='passwod_btn' onClick={() => { setShowAssets(!showAssets) }}>
                                                            {showAssets ?
                                                                <VisibilityOffTwoTone />
                                                                :
                                                                <VisibilityTwoTone />
                                                            }
                                                        </button></span>
                                                    </div>

                                                    {showAssets ?
                                                        <>
                                                            <h5> * <br /> </h5>
                                                        </>

                                                        :
                                                        <>
                                                            <h5> {balance} {id?.toUpperCase()}<br /> </h5>
                                                        </>
                                                    }

                                                </div>
                                                <div >
                                                    <a href="#Overview_staking" className='more_link'> View More</a>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='card_second_staking'>
                                            <div className='card__adf'>
                                                <h5> Yesterday's Profit ({id?.toUpperCase()})</h5>

                                                {showAssets ?
                                                    <>
                                                        <p>*</p>
                                                    </>

                                                    :
                                                    <>
                                                        <p>0</p>
                                                    </>
                                                }
                                            </div>

                                            <div className='card__adf'>
                                                <h5> Total Profit ({id?.toUpperCase()})</h5>
                                                {showAssets ?
                                                    <>
                                                        <p>*</p>
                                                    </>

                                                    :
                                                    <>
                                                        <p>0</p>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </Card>
                                </div>

                            </Col>

                            <Col md={6}>
                                <div className='p-5'>
                                    <img src={staking__img} alt="staking__img" width="90%" className='staking__banner__img m-auto' />
                                </div>
                            </Col>

                        </Row>
                    </div>
                </div>


                <div className='staking__card__1'>
                    <div className='coustom_container '>
                        <div className='staking__card__card'>
                            <Row className='align-items-start'>
                                <Col md={4}>
                                    <img src={buyCrypto_img} alt="buyCrypto_img" width="100%" className='buyCrypto_img' />
                                </Col>
                                <Col md={8}>
                                    <div className='New___ryt'>
                                        <h3>How to accrue staking rewards on crypto</h3>
                                        <p>Once you open a {title?.toLocaleLowerCase()} account, you can purchase any amount* of crypto1 assets supported by {title?.toLocaleLowerCase()} Staking (which you can review here) and immediately transfer them to {title?.toLocaleLowerCase()} Staking to start accruing staking rewards on your holdings.</p>
                                        <p>There is no minimum amount* of crypto required to stake on {title?.toLocaleLowerCase()}, and no transfer or redemption fees for staking. {title?.toLocaleLowerCase()}'s' fee will be deducted from the staking rewards you accrue.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>

                <Tabs>
                    <div className='Overview_staking' id='Overview_staking'>
                        <div className='staking__tabls'>
                            <TabList>
                                <div className="coustom_container d-flex justify-content-between align-items-center">
                                    <Tab>Overview Staking</Tab>

                                    <Link to="/dashboard/staking-history" className='history__link'> Staking History <KeyboardArrowRight /> </Link>
                                </div>

                            </TabList>

                            <TabPanel>
                                <div className="coustom_container">
                                    <div className='card___table'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th> Years</th>
                                                    <th className='text-center'> APY </th>
                                                    <th> Min</th>
                                                    <th> Max</th>
                                                    <th className='text-center'> status</th> 
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {balanceDataArray?.map((item, key) => {
                                                    return (

                                                        <>
                                                            <tr key={key}>
                                                                <td> {item.year} years</td>
                                                                <td className='text-center bold_txt'> {Number(item.percentage) * 12}%</td>
                                                                <td>{item.min}</td>
                                                                <td> {item.max}</td>
                                                                <td className='text-center'>
                                                                    {/* <Button type='button' className='btn Subscribe_button' onClick={() => { onOpenModal(item); setBalanceDataKey(key + 1) }}>Stack Allow</Button> */}
                                                                    <Button type='button' className='btn Subscribe_button'>Coming Soon</Button>

                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>


            {/* ===================================  */}



            <Modal open={open} onClose={onCloseModal}
                classNames={{
                    modal: 'staking__modal'

                }}
                center>
                <h4>Staking</h4>
                <div className='modal__staking__a'>
                    <div className='card__modal_a'>
                        <div className='coin__staking'>
                            <img src={logo} alt="logo" className='coin__logo_staking' />
                            <div className=''>
                                <h6 className='staking_name'> STA</h6>
                                <h5 className='full_name_staking'>STA</h5>
                            </div>
                        </div>

                        <div className='reference_staking'>
                            <p className='staking_modal_card-title'>Percentage</p>
                            <h4 className='staking_Modal_value'>{modalData.percentage}</h4>
                        </div>

                        <div className='reference_staking text-end'>
                            <p className='staking_modal_card-title'> Month</p>
                            <h4 className='staking_Modal_value'>{modalData.month}</h4>
                        </div>

                    </div>
                    <div className='card__modal_Amount'>
                        <p className='card__modal__acs'>Amount</p>

                        <div className='select__options'>

                            <div className='select_options_area'>
                                <p> Main Accounts</p>

                                {/* <Form.Select aria-label="Default select example">
                            <option> Accounts</option>
                            <option value="Funding Account">Funding Account 0</option>
                            <option value="Trading Account">Trading Account 0</option>
                        </Form.Select> */}

                                <p className='staking_active'> {balance} {id?.toUpperCase()}</p>
                            </div>
                            <div className='INput__area_staking'>
                                <input placeholder=" Min amount: 2 HYDRA" type='number' onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                                <button type='button' className='btn all_button' onClick={() => setAmount(balance)}> All</button>
                            </div>
                        </div>

                        <p className='card__modal__acs'>Min Subscription: {modalData.min} {id?.toUpperCase()}</p>
                        <p className='card__modal__acs'>Max Subscription: {modalData.max} {id?.toUpperCase()}</p>

                    </div>
                    <div className='deatisl__adsf'>
                        <div className=''>
                            <p> <span> <PlaylistAdd /> </span>  Total Profit  </p>
                        </div>
                        <p> {((amount * (modalData?.percentage / 100)) * modalData.month)?.toFixed(3)}</p>
                    </div>
                    <div className='deatisl__adsf'>
                        <div className=''>
                            <p> <span> <PlaylistAdd /> </span>  Total Return  </p>
                        </div>
                        <p> {Number(((amount * (modalData?.percentage / 100)) * modalData.month)?.toFixed(3)) + Number(amount)}</p>
                    </div>
                    {/* <div className='deatisl__adsf'>
                        <div className=''>
                            <p> <span> <PlaylistAdd /> </span>  Projected Profit  </p>
                        </div>
                        <p> - STA / D</p>
                    </div>

                    <div className='deatisl__adsf'>
                        <div className=''>
                            <p> <span> <SpeakerNotes /> </span>  Projected Profit  </p>
                        </div>
                        <p className='cursor-none'> None Available</p>
                    </div> */}

                    <p className='heading___rulus'>Rules</p>

                    <div className='heading___rulus__area'>

                        <label htmlFor='CheckBOx__afds'>  <input type='checkbox' id='CheckBOx__afds' /> I have read and agree to theCryptocurrency Mining AgreementSubscribe</label>
                    </div>

                    <div className='btn__area_staking'>
                        <Button type='submit' className='btn Subscribe__btn_modal' onClick={() => submitStake()}>Stack </Button>
                    </div>


                </div>
            </Modal>


        </>
    )
}

export default Staking