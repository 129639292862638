import React from 'react'
import img from "../../Assets/Img__v2/austronaut.png"
import { Fade, Zoom } from "react-awesome-reveal";
import { title } from '../../config/config';

const LoginSide = () => {
    return (
        <>
            <div className="right">
                <div className="logo">
                    <div className="inner">
                        <Fade direction='left'>
                            <div className="heading">
                                <h3>Secure and Seamless Cryptocurrency Trading</h3>
                                <p>Access your {title} account for seamless crypto trading. Enjoy secure login and fast transactions in just a few clicks. Trade with confidence now!</p>

                            </div>
                        </Fade>

                        <div className="image">
                            <img src={img} alt="img" />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginSide
