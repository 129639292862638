// import React from 'react';
import React, { useEffect, useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Background from '../../Assets/Image/kycBG.svg'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import _fetch from "../../config/api";
import { api_url, kycImage_path } from "../../config/config";
import toasted from "../../config/toast";
import dateFormat from "dateformat";
import { ContentCopy, Person } from "@mui/icons-material";
import { Link } from "react-router-dom";

function KycDetails() {
    const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '' });

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    const [open1, setOpen1] = useState(false);
    const onOpenModal1 = () => setOpen1(true);
    const onCloseModal1 = () => setOpen1(false);
    const [userKycInfo, setUserKycInfo] = useState({
        city: "", comment: "", country: "", created_at: "", dob: "", document_back_image: "", document_front_image: "", document_number: "", document_type: "", first_name: "", kyc_status: "", last_name: "", pan_image: "", pan_number: "", pin_code: "", selfie_image: "", state: ""
    });

    const handleCopy = (data) => {
        if (data) {
            navigator.clipboard.writeText(data);
            toasted.success("Username copied successfully");
        }
    };


    useEffect(() => {
        (async () => {
            const kycData = await _fetch(`${api_url}kyc/userKycInfo/`, "GET", "", {});
            setUserKycInfo(kycData?.data?.data);
        })();
    }, [])
    return (
        <>
            <section className='kyc-details-main'>
                <div className='container-fluid'>
                    <div className="tab__card__a">
                        <div className="d-flex align-items-center justify-content-between kyc-heading">
                            <div className="head">
                                <h2>Kyc Details </h2>
                            </div>
                            <p>
                                Status : <span style={{ color: userKycInfo.kyc_status === 'Approved' ? '#95d395' : 'blue' }}> {userKycInfo.kyc_status} </span>

                            </p>
                        </div>
                        <div className="tab-body">

                            <div className="tab___user__info">
                                <div className="card__item_ad">
                                    <div className="card__item_profile">
                                        <div className="card__borders">
                                            <Person className="icon_items" />
                                        </div>

                                        <div className="card__item_txt">
                                            <div className="card_item_email">
                                                {userKycInfo.first_name + ' ' + userKycInfo.last_name}
                                            </div>

                                            {/* <div className="card_item_uid">
                                                <p className="mb-0">UID <span> {userInfo?.username} </span></p>
                                                <ContentCopy className="copY__buttons" onClick={() => handleCopy(userInfo?.username)} />
                                            </div> */}

                                        </div>
                                    </div>



                                    <div className="Last__seen mt-3">
                                        <p>Last login time: 2023-08-14 11:51</p>
                                        <p className="second__links"> <Link to="/">Login Records</Link> </p>
                                    </div>

                                </div>
                            </div>
                            <div className="main-li">

                                <li>
                                  
                                    <span className='title'>Name</span>
                                    <span className='detail'>{userKycInfo.first_name + ' ' + userKycInfo.last_name}</span>
                                </li>

                                <li onClick={onOpenModal} className="list-1">
                                   
                                    <span className='title'>Pan Detail</span>
                                    <div className='detail detail-1'>{userKycInfo.pan_number} <span><AddPhotoAlternateOutlinedIcon className="icon-open" /></span></div>
                                </li>
                                <Modal open={open} onClose={onCloseModal} center>
                                    <div className="modal-content">
                                        <div className="image">
                                            <img className="front-image" src={kycImage_path + userKycInfo.pan_image} />

                                        </div>
                                    </div>
                                </Modal>

                                <li
                                    onClick={onOpenModal1} className="list-1">
                                 
                                    <span className='title'>{userKycInfo.document_type} Detail</span>
                                    <div className='detail detail-1'>{userKycInfo.document_number} <span><AddPhotoAlternateOutlinedIcon className="icon-open" /></span></div>

                                </li>
                                <Modal open={open1} onClose={onCloseModal1} center>
                                    <div className="modal-content">
                                        <div className="image">
                                            <img className="front-image" src={kycImage_path + userKycInfo.document_front_image} />
                                            <img className="back-images" src={kycImage_path + userKycInfo.document_back_image} />
                                        </div>
                                    </div>
                                </Modal>



                                <li>
                              
                                    <span className='title'>DOB </span>
                                    <span className='detail'>
                                        {dateFormat(userKycInfo.dob, "UTC:dd-mm-yyyy ")}
                                    </span>
                                </li>

                                <li>
                                 
                                    <span className='title'>Address </span>
                                    <span className='detail'>{userKycInfo?.comment} </span>
                                </li>
                                <li>
                                  
                                    <span className='title'>Pin Code </span>
                                    <span className='detail'>{userKycInfo.pin_code} </span>
                                </li>

                                <li>
                                   
                                    <span className='title'>State </span>
                                    <span className='detail'>{userKycInfo.state}</span>
                                </li>

                                <li>
                                   
                                    <span className='title'>Country</span>
                                    <span className='detail'>{userKycInfo.country}</span>
                                </li>
                            </div>

                        </div>
                    </div>
                   
                </div>
            </section>

        </>
    )
}

export default KycDetails
