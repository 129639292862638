import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { South, North } from "@mui/icons-material";
import { useParams } from 'react-router-dom';
import { api_url } from '../../config/config';
import _fetch from '../../config/api';

const LiveBalance = (props: any) => {


    const { id } = useParams();
    const [sellData, setSellData] = useState([{coin_price:0,amount_total:0}]);
    const [buyData, setBuyData] = useState([{coin_price:0,amount_total:0}]);
    const [emptyData, setemptyData] = useState([]);
    const [sellemptyData, setsellemptyData] = useState([]);

    async function buyDataFetch() {
        var d = await _fetch(`${api_url}trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&market_trade_type=buy&order_status=pending`, 'GET', {}, {});
        if (d?.status === 'success') {
            setBuyData(d.data.data);
            // console.log('buy data',d.data.data)
            setemptyData(d)
        }
    }
    async function sellDataFetch() {
        var d = await _fetch(`${api_url}trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&market_trade_type=sell&order_status=pending`, 'GET', {}, {});
        if (d?.status === 'success') {
            setSellData(d.data.data);
            setsellemptyData(d);
            // console.log('buy data',d.data.data)

        }
    }
    useEffect(() => {
        buyDataFetch()
        sellDataFetch()

    }, [props.LoadApi])

    return (
        <div className='main-order-book'>
            <div className='main-table-data'>
                <div className='main-table-order'>
                    <div className='tabs__card_dcs'>
                        <p className='main_order-heading'>Order Book </p>
                        <div className='scroll-y'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {sellData.map((item, index) => (
                                        <tr data-index={index} >
                                            <td className='pl'>{item.coin_price?.toFixed(3)}</td>
                                            <td className='al'>{item.amount_total?.toFixed(3)}</td>
                                            <td className='tl'>{item.coin_price * item.amount_total}</td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='tabs__card_dcs'>
                        <div className='scroll-y1'>
                            <table>
                                <thead>
                                    <tr>
                                        {/* <th className='numbers'>{Number(buyData?.[0]?.coin_price?.toFixed(3)??0)}</th>
                                        <th className='numbers2'>${Number(buyData?.[0]?.amount_total?.toFixed(3)??0)}</th>
                                        <th className='total'>More</th> */}

                                            <th>Price(USDT)</th>
                                            <th>Amount({props.symbol.toUpperCase()})</th>
                                            <th className='total'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {buyData.map((item, index) => (
                                        <>
                                            {(item) ?
                                                <tr key={index} >
                                                    <td className="status-dot-cell-green pl"> {item.coin_price.toFixed(3)} </td>
                                                    <td className='al'>{item.amount_total?.toFixed(3)}</td>
                                                    <td className='tl'>{item.coin_price * item.amount_total }</td>
                                                </tr>
                                                : ''}
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveBalance