import React, { useEffect, useState } from "react";
import { ArrowBackIosNew, KeyboardArrowRight, ContentCopy } from "@mui/icons-material";
import Select from 'react-select';
import { Button, Table } from "react-bootstrap";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import QRCode from "qrcode.react";
import toasted from "../../config/toast";
import { Link } from "react-router-dom";




const Deposit = () => {

  const [selectedOption, setSelectedOption] = useState(null);
  const [network, setNetwork] = useState(null);
  const [market, setMarket] = useState([]);
  const [amount, setAmount] = useState(1);
  const [depositData, setDepositData] = useState({ trc20: '', bep20: '', erc20: '', sta20: '' });
  const [countHit, setCountHit] = useState(0);

  

  const auth = localStorage.getItem("auth");
  const SetLinkBg = () => {
    if (auth !== "true") {
      return (
        <>
          <Link to="/" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
        </>
      )
    }
    else {
      return (
        <>
          <Link to="/dashboard" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link>
        </>
      )
    }
  }

  const copyToClipboard = (address, name) => {
    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success(name + 'Address copied successfull!');
  }

  async function getMarket() {
    setNetwork()
    let d = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=main_account`, 'GET');
    if (d?.status === 'success') {
      const selectedValue = d?.market?.[0];
      if (selectedOption === undefined) {
        setSelectedOption({
          value: selectedValue?.symbol,
          text: <span className="new__a"> {selectedValue?.symbol.toUpperCase()} </span>,
          icon: <img src={selectedValue?.image} className="option-img-a__A" alt="selectedvalue"/>,
          total_coin: selectedValue?.total_coin,
          coinID: selectedValue?.coin_id,
          bep20: selectedValue?.bep20,
          trc20: selectedValue?.trc20,
          erc20: selectedValue?.erc20,
        });
        handleCoinChange({
          value: selectedValue?.symbol,
          text: <span className="new__a"> {selectedValue?.symbol.toUpperCase()} </span>,
          icon: <img src={selectedValue?.image} className="option-img-a__A" alt="selectedvalue"/>,
          total_coin: selectedValue?.total_coin,
          coinID: selectedValue?.coin_id,
          bep20: selectedValue?.bep20,
          trc20: selectedValue?.trc20,
          erc20: selectedValue?.erc20,
        });
      }

      d?.market?.map((value) => {
        var newVal = {
          value: value?.symbol,
          text: <span className="new__a"> {value?.symbol.toUpperCase()} </span>,
          icon: <img src={value?.image} className="option-img-a__A" alt="selectedvalue"/>,
          total_coin: value?.total_coin,
          coinID: value?.coin_id,
          bep20: value?.bep20,
          trc20: value?.trc20,
          erc20: value?.erc20,
        };
        setMarket(elpMarket => [...elpMarket, newVal]);
      })

    }
  }

  const handleCoinChange = (e) => {
    setNetwork()
    setSelectedOption(e);
    if (e.bep20.status === true) {
      setNetwork('BEP20');
    }
    else if (e.trc20.status === true) {
      setNetwork('TRC20');
    }
    else if (e.erc20.status === true) {
      setNetwork('ERC20');
    }
  }

  useEffect(() => {
    getMarket();
    (async () => {
      const data = await _fetch(`${api_url}wallet/wallets`, 'GET', {
      });

      setDepositData({
        trc20: data.trc20,
        bep20: data.bep20,
        erc20: data.erc20
      });

    })();
  }, []);

  useEffect(() => {
    if (countHit < 50) {
      const timer = setInterval(() => {
        (async () => {
          var res = await _fetch(`${api_url}deposit/checkTransaction`, 'POST', { chain: network, coin_symbol: selectedOption.value });
          if (res[0]?.success === 'success') {
            toasted.success(res[0].message);
            clearInterval(timer);
          }

        })()
        var calHit = parseFloat(`${countHit + 1}`);
        setCountHit(calHit);
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [countHit]);


  return (
    <>
      <div className="main-deposit">
        <div className="inner-crum coustom_container">
          <div className="inner-crum_dc">
            <div className="inner-crum-left">
              <span> {SetLinkBg()} </span>
                <h3>Deposit</h3>
            </div>
            <Link to="/dashboard/deposit-history" className="More__links"> Deposit History</Link>
          </div>
        </div>

        

        <div className="form-sec">
          <div className="inner-sec coustom_container">

            <div className="middle-sec">
              <div className="form-main">
                <div className="select-coin">
                  <span>Coin</span>
                  <div className="select-coin2">
                    <Select
                      placeholder="Select option"
                      value={selectedOption}
                      className="select-option-s"
                      options={market}
                      onChange={handleCoinChange}
                      getOptionLabel={(e) => (
                        <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                          {e.icon}
                          <span style={{ marginLeft: '5px' }}>{e.text}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
               

                <div className="select-network">
                  <span>Network</span>
                  <div className="select-network2">

                    <select placeholder="Select Network" onChange={(e) => { setNetwork(e.target.value) }}>
                      {selectedOption?.bep20?.status === false && selectedOption?.trc20?.status === false && selectedOption?.erc20?.status === false && <option>Network Closed</option>}
                      {selectedOption?.bep20?.status === true && <option value={'BEP20'}>BEP20</option>}
                      {selectedOption?.trc20?.status === true && <option value={'TRC20'}>TRC20</option>}
                      {selectedOption?.erc20?.status === true && <option value={'ERC20'}>ERC20</option>}

                    </select>
                  </div>
                </div>

                <div className="select-amountmain">
                  <div className="select-amount">
                    <div><span>Amount</span></div>
                    <div><input type="number" step="0.01" value={amount} onChange={(e) => { setAmount(e.target.value) }}>
                    </input></div>
                  </div>
                </div>

              </div>

              {network === 'BEP20' &&
                <div className="address-sec__qr">
                  <div className="add-inner">
                    <div className="inner-left">
                      <p>Address</p>
                      <div className="copy-ad">
                        <span> {(depositData?.bep20.length > 15) && depositData?.bep20.substring(0, 7) + '....' + depositData?.bep20.substring(depositData?.bep20.length - 7, depositData?.bep20.length)}</span>
                        <span className="d-flex" onClick={() => { copyToClipboard(depositData?.bep20, 'BEP20') }}> | <ContentCopy sx={{ fontSize: "18px", marginLeft: "10px" }} /></span>
                      </div>
                      <p>Expected arrival <strong>12</strong></p>
                    </div>
                    <div className="inner-right">
                      <div className="qrCode-sec">
                        <div>
                          <Button variant="link">Address Book<KeyboardArrowRight /></Button>
                          {/* <img src={QR} alt="" /> */}
                          <QRCode value={`${depositData?.bep20}`} style={{ border: '1px solid #fff' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {network == 'ERC20' &&
                <div className="address-sec__qr">
                  <div className="add-inner">
                    <div className="inner-left">
                      <p>Address</p>
                      <div className="copy-ad">
                        <span> {(depositData?.erc20.length > 15) && depositData?.erc20.substring(0, 7) + '....' + depositData?.erc20.substring(depositData?.erc20.length - 7, depositData?.erc20.length)}</span>
                        <span onClick={() => { copyToClipboard(depositData?.erc20, 'ERC20') }}> | <ContentCopy sx={{ fontSize: "18px", marginLeft: "10px" }} /></span>
                      </div>
                      <p>Expected arrival <strong>12</strong></p>
                    </div>
                    <div className="inner-right">
                      <div className="qrCode-sec">
                        <div>
                          <Button variant="link">Address Book<KeyboardArrowRight /></Button>
                          {/* <img src={QR} alt="" /> */}
                          <QRCode value={`${depositData?.erc20}`} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {network == 'TRC20' &&
                <div className="address-sec__qr">
                  <div className="add-inner">
                    <div className="inner-left">
                      <p>Address</p>
                      <div className="copy-ad">
                        <span> {(depositData?.trc20.length > 15) && depositData?.trc20.substring(0, 7) + '....' + depositData?.trc20.substring(depositData?.trc20.length - 7, depositData?.trc20.length)}</span>
                        <span onClick={() => { copyToClipboard(depositData?.trc20, 'TRC20') }}> | <ContentCopy sx={{ fontSize: "18px", marginLeft: "10px" }} /></span>
                      </div>
                      <p>Expected arrival <strong>12</strong></p>
                    </div>
                    <div className="inner-right">
                      <div className="qrCode-sec">
                        <div>
                          <Button variant="link">Address Book<KeyboardArrowRight /></Button>
                          {/* <img src={QR} alt="" /> */}
                          <QRCode value={`${depositData?.trc20}`} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }


              <div className="list-sec">
                <ul>
                  <li>Only send {selectedOption?.value?.toUpperCase()} to this address. Sending any other asset to this address may result in the loss of your deposit!</li>
                  <li>Ensure the network is <span style={{ color: "#f3ce5e" }}>{network}</span></li>
                  <li>Please be sure that the contract address is related to the tokens that you are depositing.{/* <br /> <span style={{color: "#f3ce5e"}}>View Contract Address</span><Launch sx={{fontSize: "14px", marginLeft: "5px", color: "#f3ce5e"}}/> */}</li>
                </ul>
              </div>
            </div>

          </div>

        </div>



        {/* <DepositHistory/> */}
      </div>

    </>
  );
};

export default Deposit;
