import React from 'react'
import {  GridLoader } from 'react-spinners'
// import { ScaleLoader } from 'react-spinners'

const LoaderCom = (props:any) => {
    return (
        <tr className="d-value only-open-orders">
            
            {/* {props.condition != ' ' && */}
            {props.condition == '' &&
                    <td colSpan={8} className="color-ring-market " style={{whiteSpace: 'inherit', lineHeight:'1rem' }} >
                        <div className='color__ring_data'>
                           <div className=''>
                                <GridLoader color="var(--primary-color)" />
                                <span className='d-block text__ring__card'>{ props.text }</span>
                           </div>
                        </div>
                    </td>
                }
            </tr>
    )
}

export default LoaderCom