import React, { useEffect, useState } from 'react'
// import { createChart, CrosshairMode } from 'lightweight-charts';
import { createChart, CrosshairMode} from 'lightweight-charts';
// import { ChartComponent } from './ChartComponent';
// import Chart from '@qognicafinance/react-lightweight-charts'
import { useParams } from 'react-router';
// import img__bulr from '../../Assets/Images/blur_trading_2.png'
import { api_url, logo } from '../../../config/config';
import _fetch from '../../../config/api';




const TradingManualChart = (props: any) => {
	const [ChartData, setChartData] = useState({area:[],candel:[{time: '', open: '', high: '', low: '', close: ''}]});
	const [ChartType, setChartType] = useState('candel');
	const [tradePerformance, setTradePerformance] = useState({ open: '', close: '', high: '', low: '' });
	const [tradePerformanceArea, setTradePerformanceArea] = useState({ value:'',time:''});

	const [areaPerformance, setareaPerformance] = useState({value: 0, time: 1685356595863});

	const { id } = useParams();
	async function chartInfo() {
		var d = await _fetch(`${api_url}trade/getChartMannual?symbol=${id?.toLocaleLowerCase()}&sort=created_at`, 'GET', {}, {});
		if (d?.status === 'success') {
			setChartData(d.data)
			// console.log('chart',d.data)
		}
	}
	// console.log('ChartData', ChartType, ChartData.candel[0]);

	useEffect(() => {
		chartInfo();


		if (ChartData.area.length > 0) {


			const container: HTMLElement | any = document.getElementById('TradingManualChart');

			let mark = "USDT \ ELP\ Exchange";

			const chart = createChart(container,
				{
					layout: {
						// backgroundColor: '#161a25',
						textColor: '#a6a9b2',

					},

					leftPriceScale: {
						borderVisible: true,
						autoScale: true
					},
					rightPriceScale: {
						borderVisible: true,
						autoScale: true
					},


					crosshair: {
						mode: CrosshairMode.Magnet,
					},

					timeScale: {
						minBarSpacing: 0.001,
						borderVisible: true,
						fixLeftEdge: true,
						fixRightEdge: true,
						// lockVisibleTimeRangeOnResize:true,
						timeVisible: true,

					},
					grid: {
						vertLines: {
							color: "#334158"
						},
						horzLines: {
							color: "#334158"
						}
					},

					watermark: {
						text: mark,
						fontSize: 60,
						color: "rgba(256, 256, 256, 0.1)",
						visible: false

					},
					handleScale: {
						axisPressedMouseMove: true
					},
					localization: {
						priceFormatter: (price:any) => {
						  return price?.toFixed(4);
						},
					  },
					handleScroll: true,
					height: 330,
				});



			// const container = document.getElementById('container');
			const background: HTMLElement | any = document.createElement('section');
			// place below the chart
			background.style.zIndex = -1;
			background.style.position = 'absolute';
			// set size and position to match container
			background.style.inset = '0px';
			background.style.backgroundImage = `url(${logo})`;
			background.style.backgroundRepeat = 'no-repeat';
			background.style.backgroundPosition = 'center';
			background.style.backgroundSize = '22%';
			background.style.opacity = '0.3';
			background.style.filter = 'contrast(80%)';
			// background.style.display = 'none';
			
			// container.remove()

			
			// container.removeChild(container.firstElementChild);

			// container.appendChild(background);
			
			
			chart.applyOptions({
				
				layout: {
					// set chart background color to transparent so we can see the elements below
					background: { color: 'transparent' },
				},
			}
			);




			const areaSeries = chart.addAreaSeries();
			const candlestickSeries = chart.addCandlestickSeries();


			if (ChartType === 'area') {


				areaSeries.setData(ChartData.area
				// 	[
				// 	{ time: '2018-12-22', value: 32.51 },
				// 	{ time: '2018-12-23', value: 31.11 },
				// 	{ time: '2018-12-24', value: 27.02 },
				// 	{ time: '2018-12-25', value: 27.32 },
				// 	{ time: '2018-12-26', value: 25.17 },
				// 	{ time: '2018-12-27', value: 28.89 },
				// 	{ time: '2018-12-28', value: 25.46 },
				// 	{ time: '2018-12-29', value: 23.92 },
				// 	{ time: '2018-12-30', value: 22.68 },
				// 	{ time: '2018-12-31', value: 22.67 },

				// ]
				);
				// candlestickSeries.setData([]);

			} else if (ChartType === 'candel') {
				const data: any = [{ open: 10, high: 10.63, low: 9.49, close: 9.55, "time": 1642427770 }, { open: 9.55, high: 10.30, low: 9.42, close: 9.94, time: 1643528800 }, { open: 9.94, high: 10.17, low: 9.92, close: 9.78, time: 1644629830 }, { open: 9.78, high: 10.59, low: 9.18, close: 9.51, time: 1645731880 }, { open: 9.51, high: 10.46, low: 9.10, close: 10.17, time: 1646832920 }, { open: 10.17, high: 10.96, low: 10.16, close: 10.47, time: 1647933970 }];
				// candlestickSeries.setData(data);
				// console.log('ChartData.candel',ChartData.candel);
				
				candlestickSeries.setData(ChartData.candel);

				
					
				// 	);
				// areaSeries.setData([])
			}
			// candlestickSeries.setData(
			//   data
			// ChartData
			//   [
			// //   { time: 'Tue, 03 Jan 2023 ', open: 75.16, high: 82.84, low: 36.16, close: 45.72 },
			// //   { time: 'wed, 04 Jan 2023 ', open: 45.12, high: 53.90, low: 45.12, close: 48.09 },
			// //   { time: 'Thr, 05 Jan 2023 ', open: 60.71, high: 60.71, low: 53.39, close: 59.29 },
			// //   { time: 'fri, 06 Jan 2023 ', open: 68.26, high: 68.26, low: 59.04, close: 60.50 },
			//   // { time: 'Tue, 03 Jan 2023 10:24:27 GMT', open: 67.71, high: 105.85, low: 66.67, close: 91.04 },
			//   // { time: 'Tue, 03 Jan 2023 10:24:27 GMT', open: 91.04, high: 121.40, low: 82.70, close: 111.40 },
			//   // { time: '2018-12-28', open: 111.51, high: 142.83, low: 103.34, close: 131.25 },
			//   // { time: '2018-12-29', open: 131.33, high: 151.17, low: 77.68, close: 96.43 },
			//   // { time: '2018-12-30', open: 106.33, high: 110.20, low: 90.39, close: 98.10 },
			//   // { time: '2018-12-31', open: 109.87, high: 114.69, low: 85.66, close: 111.26 },
			// ]

			// );

			//   candlestickSeries.applyOptions({
			//     topColor: 'rgba(70, 130, 180, 0.5)',
			//     bottomColor: 'rgba(70, 130, 180, 0.1)',
			//     lineColor: '#4682B4',
			//     lineWidth: 2
			// });
			//   chart.applyOptions({
			//     timeScale: {
			//         // Adds hours and minutes to the chart.
			//         timeVisible: true,
			//         secondsVisible: true
			//     }
			// });
			// const container2: HTMLElement | any = document.getElementById('TradingManualChart2');

			var toolTipWidth = 125;
			var width = 600;
			var toolTip: HTMLElement | any = document.createElement('div');
			toolTip.className = '';
			// container2.appendChild(toolTip);

			chart.subscribeCrosshairMove(function (param: any) {
				if (!param.time || param.point.x < 0 || param.point.x > width || param.point.y < 0) {
					// toolTip.style.display = 'none';
					// console.log('param', param);

					return;
				}


				// console.log('param',Array.from(param?.seriesData).flat()[1]);
				
				// let price = param.seriesPrices.get(candlestickSeries);    
				let date = new Date(param.time * 1000).toLocaleDateString();
				const oplh: any = Array.from(param?.seriesData).flat()[1];
				// console.log(Array.from(param?.seriesData).flat()[1]);
				
				if (ChartType !== 'area') {
					setTradePerformance(oplh);
				} else {
					setareaPerformance(oplh);
				}
				let left = param.point.x - toolTipWidth / 2;
				left = Math.max(0, Math.min(width - toolTipWidth, left));

				toolTip.style.left = left + 'px';
			});
		// 	const visibleTimeRange:any = {};
		// 	visibleTimeRange.from = new Date(data[0].time).getTime() - 75000 * 6; 
		//   //7.5 minutes offset
		  
		// 	visibleTimeRange.to = new Date(data[data.length - 1].time).getTime() + 75000 * 6;
		//    //7.5 minutes offset
		  
		// 	chart.timeScale().setVisibleLogicalRange(visibleTimeRange);
		  
	// 	const visibleTimeRange:any = {};
	// 	visibleTimeRange.from = new Date(ChartData.candel[0].time).getTime()-75000*6; 
	//   //7.5 minutes offset
	  
	// 	visibleTimeRange.to =  (new Date(ChartData.candel[ChartData.candel.length - 1].time)).getTime()+75000*6 ;
	//    //7.5 minutes offset
	  
	// 	// chart.timeScale().setVisibleLogicalRange(visibleTimeRange);
	// 	chart.timeScale().setVisibleLogicalRange(visibleTimeRange);


			// console.log('visibleTimeRange',visibleTimeRange,ChartData.candel[ChartData.candel.length - 3].time,chart.timeScale().setVisibleLogicalRange({ from: 1672291467, to: new Date(ChartData.candel[ChartData.candel.length - 3].time).getTime() + 75000 * 6 }));
			
			chart.timeScale().fitContent();

			return () => {
				// window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		}

	}, [ChartData.area.length, ChartType,props.LoadApi,id])

	//   const downImg = () => {
	//     var content :HTMLElement | any = document.getElementById("divcontents");
	// var pri : HTMLElement | any = document.getElementById("TradingManualChart");
	// pri.document.open();
	// pri.document.write(content.innerHTML);
	// pri.document.close();
	// pri.focus();
	// pri.print();
	//   }

	return (
		<div className="custom___section custom___section__chart_se">
			<div className="custom___section__main">
				<div className='custom___section__left'>
					<div className='custom___section__left__ids'>
						<h5 className='chart___custom__name'>{id?.toUpperCase()}</h5>
						<div className=''>
							{ChartType === 'candel' && <h5 className='custom___section__value' style={{ color:tradePerformance?.close >= tradePerformance?.open  ? 'green' : 'red' }}>O${tradePerformance?.open} H${tradePerformance?.high} L${tradePerformance?.low}  C${tradePerformance?.close}</h5>}
							{ChartType === 'area' && <h5 className='custom___section__value' >P{areaPerformance.value}</h5>}
						</div>

						


					</div>

					<select name="chart" id="chart" onChange={(e: any) => setChartType(e.target.value)} className="custom___section__select">
						<option value="area"  >Area chart</option>
						<option value="candel" selected>Candel Chart</option>
					</select>

					
				</div>



				<div className='Right___mode'>
					<div id="TradingManualChart">
						<div className='TradingManualChart__prices' style={{fontSize:'16px'}}>
						</div>
					</div>

					
				</div>

				{/* <div id='TradingManualChart2' ></div> */}
			</div>
			
		</div>
	)
}

export default TradingManualChart