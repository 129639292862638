import React, { useEffect, useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import icon1 from '../../Assets/Icon/icon2.png';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { api_url } from "../../config/config";
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import P2pTab from "./P2pTab";
import phonepe from "../../Assets/Image/phonepe.png";
import g_pay from "../../Assets/Image/g_pay.png";
import wallet from "../../Assets/Image/wallet.png";
import upi from "../../Assets/Image/upi.png";
import { Link, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import MyAdsPost from "./MyAdsPost";


const P2p = () => {

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [open1, setOpen1] = useState(false);
  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);

  const [usdtPrice, setusdtPrice] = useState(85)

  const [elpMarket, setElpMarket] = useState([])
  const [AvalableelpMarket, setAvalableElpMarket] = useState([])
  const [coinBalance, setcoinBalance] = useState()
  const [payment_methods, setpayment_methods] = useState();

  const [InrPrice, setInrPrice] = useState(82)
  const [BuyMethodView, setBuyMethodView] = useState('Payment Options')
  const [BuyMethodImage, setBuyMethodImage] = useState(icon1)
  const [pairPending, setpairPending] = useState(0)

  async function activityLog() {
    let d = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=funding_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      d?.market?.map((val) => {
        var newVal = {
          value: val?.symbol,
          text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
          icon: <img src={val?.image} className="option-img-a" alt="image" />,
          current_price: val?.current_price,
          coinID: val?.coin_id,
        };
        setElpMarket(elpMarket => [...elpMarket, newVal]);
        if (val.total_coin > 0) {
          var newVal = {
            value: val?.symbol,
            text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
            icon: <img src={val?.image} className="option-img-a" alt="image" />,
            total_coin: val?.total_coin,
            current_price: val?.current_price,
            coinID: val?.coin_id,

          };
          setAvalableElpMarket(AvalableelpMarket => [...AvalableelpMarket, newVal]);
        }
      })
    }
  }


  async function getPendingPair() {
    let d = await _fetch(`${api_url}trade/p2p-paired-requests`, 'GET', {}, {});

    setpairPending(d?.data?.data?.length);
    // if (d?.status === 'success') {

    // }
  }

  //////////////////////////////////////sell/////////////////////////////////////////////////////
  const [sellQuantity, setsellQuantity] = useState(0)
  const [sellPrice, setsellPrice] = useState(0);


  const [selectedOption, setSelectedOption] = useState(null);
  const [sellChainId, setsellChainId] = useState(null);
  const [sellMinLimit, setsellMinLimit] = useState(null);
  const [PassbtnDisable, setPassbtnDisable] = useState(false);

  const handleChange = (e) => {
    var total_coin = e?.total_coin?.toFixed(2);
    // console.log('handleChangeasd', e);
    setcoinBalance(total_coin);
    var newPrice = usdtPrice * e?.current_price;
    setsellPrice(newPrice)
    setsellUSDTPrice(e?.current_price);
    setsellChainId(e.coinID)
    setSelectedOption(e);
    setSelectedBuyOption(e)

  }

  const sellSubmit = async (e) => {
    setPassbtnDisable(true);
    e.preventDefault();
    var data = {
      "amount": sellQuantity,
      "price": sellPrice,
      "coinID": sellChainId,
      "currency": selectedOption.value,
      "payment_method": payment_methods,
      "min_limit": sellMinLimit,
      'symbol': selectedOption.value,
    }

    var d = await _fetch(`${api_url}trade/p2p-create-sell-trade`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
      //  setOpenQR(false);
      setPassbtnDisable(false)

    } else {
      setPassbtnDisable(false)

      toasted.error(d.message);
    }
  }

  ///////////////////////////CLOSE SELL /////////////////

  const [allAccountdetail, setallAccountdetail] = useState([])
  async function getAccountDetails() {
    let d = await _fetch(`${api_url}payments/paymentDetails`, 'GET', {}, {});
    if (d?.status === 'success') {
      setallAccountdetail(d.data.data)
    }
  }
  const location = useLocation()
  const { state } = location
  const [formTab, setformTab] = useState(0)

  useEffect(() => {

    activityLog()
    getAccountDetails();
    getPendingPair()


  }, [payment_methods])

  useEffect(() => {
    if (state?.active === true) {
      setformTab(1)
    } else {
      setformTab(0)
    }
  }, [state?.active])

  const [buyPrice, setbuyPrice] = useState(0);
  const [buyQuantity, setbuyQuantity] = useState(0);
  const [selectedBuyOption, setSelectedBuyOption] = useState(null);
  const [buyChain, setbuyChain] = useState(null);
  const [getPeymentMethod, setgetPeymentMethod] = useState(null);
  const [searchType, setsearchType] = useState(false);
  const [ActiveTab, setActiveTab] = useState(0);

  // const [selectedOption, setSelectedOption] = useState(null);

  // const handleChange = (e) => {
  //   setSelectedOption(e);

  // }
  var serchBuyData = {
    "searchType": searchType,
    "amount": buyQuantity,
    "price": buyPrice,
    "coinID": buyChain,
    "currency": selectedBuyOption?.value,
    'search': `&search=payment_method_type&value=${getPeymentMethod}`,
    // &sell_currency=${selectedBuyOption?.value}
    "tab": '0',

  }
  ////////////////////////////////select multiple method ////////////////

  const addMethod = (val) => {

    if (payment_methods == undefined) {
      setpayment_methods([val]);
    } else {
      var check = payment_methods.includes(val);
      if (check != true) {
        payment_methods.push(val);
        // console.log(payment_methods)

      } else {
        var removeIndex = payment_methods.indexOf(val);
        //remove  array
        payment_methods.splice(removeIndex, 1); // 

      }
      // setpayment_methods([...payment_methods,val])
    }
    // console.log('payment_methods', payment_methods);

  }

  const [currencyType, setcurrencyType] = useState('INR');

  const [sellUSDTPrice, setsellUSDTPrice] = useState(0);

  const usdtP2pPrice = (e) => {
    setsellUSDTPrice(e.target.value);
    var CoinPrice = usdtPrice * e.target.value;
    setsellPrice(CoinPrice);
  }
  useEffect(() => {
    var className = "Profile_header";
    if (location.pathname === "/dashboard/p2p") {
      document.getElementsByTagName("header")[0].classList.add(className);

    }

  });



  return (
    <>
      <section className='section-main-p2p'>
        <div className='coustom_container'>
          <div className="tab-top">
            <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>
              <div className="tab-header-fix">
                <div className='coustom_container'>
                  <TabList>
                    <Tab onClick={() => { setActiveTab(0); setformTab(0) }}>
                      One click By</Tab>
                    <Tab>
                      P2P</Tab>
                    <button onClick={() => { setActiveTab(0); setformTab(1) }} className="react-tabs__tab">

                      Add Post</button>
                    <Tab className="my_ads_last">My ADS Post <sup>({pairPending})</sup></Tab>

                    {/* <Tab>Fait Deposit</Tab> */}
                  </TabList>
                </div>
              </div>

              <TabPanel>
                <div className='one-click-div'>
                  <Tabs selectedIndex={formTab} onSelect={(index) => setformTab(index)}>
                    <div className='buy_sell_tabs'>
                      <TabList>
                        <Tab><div className="buy_tab">Buy</div></Tab>
                        <Tab><div className="sell_tab">Sell</div></Tab>
                      </TabList>
                    </div>
                    <div className="tabpanel_buy_sell">
                      <TabPanel>
                        <div className='buy-inner buy-sell-comman'>
                          <form>
                            <div className='form-group price-input currency__select'>
                              <label htmlFor="currency_name">Price (
                                <div className="form-group">

                                  <select className="coin_select" id="currency_name" onChange={(e) => { setcurrencyType(e.target.value) }} value={currencyType}>
                                    <option value="INR" > INR </option>
                                    <option value="USD"> USD </option>
                                    {/* <option value="AUSD"> AUSD </option>
                                  <option value="DAE"> DAE </option>
                                  <option value="CAD"> CAD </option> */}
                                  </select>
                                </div>
                                )
                                {/* <ArrowDropDown/> */}
                              </label>

                              <div className="main-form">
                                <input type='number' placeholder='Price' className='form-control mt-1 inp_1'
                                  onChange={(e) => setbuyPrice(e.target.value)}
                                  value={buyPrice}
                                />
                                <div className="dropdown tab-dropdown">


                                  <Select
                                    placeholder="Coins"
                                    value={selectedOption}
                                    className="select-option-s"
                                    options={elpMarket}
                                    onChange={handleChange}
                                    getOptionLabel={(e) => (
                                      <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                        {e.icon}
                                        <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                      </div>
                                    )}
                                  />

                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                    <li><span className="dropdown-item"><img src={icon1} alt="icon1" />MET</span></li>
                                  </ul>
                                </div>
                              </div>


                            </div>



                            <div className='form-group mt-3 quantity'>
                              <label>Quantity</label>
                              <input type='number' placeholder='Quantity' className='form-control mt-1'
                                onChange={(e) => { setbuyQuantity(e.target.value) }}
                                value={buyQuantity}
                              />
                            </div>
                            <div className='totel mt-3'>
                              <p>Total INR:  {buyPrice * buyQuantity}</p>
                            </div>
                            <div className='form-group payment-opt mt-2'>
                              <label>Payment Methods</label><br />
                              <span onClick={onOpenModal} className="b-btn form-control mt-1">
                                <img src={BuyMethodImage} alt="icon1" />{BuyMethodView}
                              </span>
                            </div>

                            <div className='form-group mt-3'>
                              <button type="button" className="sub-btn" onClick={() => { setActiveTab(1); setsearchType(true) }}>Buy</button>
                            </div>

                          </form>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className='sell-inner buy-sell-comman'>
                          <form onSubmit={sellSubmit}>
                            <h4 className="title">Balance: {coinBalance}</h4>
                            {currencyType === 'INR' &&
                              <div className='form-group price-input currency__select'>
                                <label htmlFor="currency_buy">Price (
                                  <div className="form-group">

                                    <select className="" id="currency_buy" onChange={(e) => { setcurrencyType(e.target.value) }} value={currencyType}>
                                      <option value="INR"> INR </option>
                                      <option value="USD"> USD </option>
                                      {/* <option value="AUSD"> AUSD </option>
                                  <option value="DAE"> DAE </option>
                                  <option value="CAD"> CAD </option> */}
                                    </select>
                                  </div>
                                  )
                                  {/* <ArrowDropDown/> */}
                                </label>

                                <div className="main-form">
                                  <input type='number' placeholder='Price' className='form-control mt-1 inp_1'
                                    onChange={(e) => { setsellPrice(e.target.value); setsellUSDTPrice(e.target.value / usdtPrice) }}
                                    value={sellPrice}
                                  />
                                  <div className="dropdown tab-dropdown">
                                    <Select
                                      placeholder="Coins"
                                      value={selectedOption}
                                      className="select-option-s"
                                      options={elpMarket}
                                      onChange={handleChange}
                                      getOptionLabel={(e) => (
                                        <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                          {e.icon}
                                          <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            }



                            {currencyType === 'USD' &&
                              <div className='form-group price-input currency__select'>
                                <label htmlFor="currency_buy">Price (
                                  <div className="form-group">

                                    <select className="" id="currency_buy" onChange={(e) => { setcurrencyType(e.target.value) }} value={currencyType}>
                                      <option value="INR"> INR </option>
                                      <option value="USD"> USD </option>
                                      {/* <option value="AUSD"> AUSD </option>
                                  <option value="DAE"> DAE </option>
                                  <option value="CAD"> CAD </option> */}
                                    </select>
                                  </div>
                                  )
                                  {/* <ArrowDropDown/> */}
                                </label>

                                <div className="main-form">
                                  <input type='number' placeholder='Price' className='form-control mt-1 inp_1'
                                    onChange={(e) => usdtP2pPrice(e)}
                                    value={sellUSDTPrice}
                                  />
                                  <div className="dropdown tab-dropdown">
                                    <Select
                                      placeholder="Coins"
                                      value={selectedOption}
                                      className="select-option-s"
                                      options={elpMarket}
                                      onChange={handleChange}
                                      getOptionLabel={(e) => (
                                        <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                          {e.icon}
                                          <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            }
                            <div className='form-group mt-3 quantity'>
                              <label>Quantity</label>
                              <input type='number' placeholder='Quantity' className='form-control mt-1 inp_max_btn'
                                onChange={(e) => { setsellQuantity(e.target.value) }}
                                value={sellQuantity} />
                              <button type="button" className="btn max-btn" onClick={() => setsellQuantity(coinBalance)}>
                                MAX
                              </button>

                            </div>
                            <div className='form-group mt-3'>
                              <label>Minimum Limit</label>
                              <input type='number' placeholder='Minimum Limit' className='form-control mt-1'
                                onChange={(e) => setsellMinLimit(e.target.value)}
                                value={sellMinLimit}
                              />
                            </div>
                            <div className='totel mt-3'>
                              <p>Total INR:{sellPrice * sellQuantity} </p>
                            </div>
                            <div className='form-group payment-opt mt-3'>
                              <label>Payment Methods</label><br />
                              <span onClick={onOpenModal1} className="b-btn form-control mt-1">
                                <img src={icon1} alt="icon1" /><spam>Payment</spam>
                              </span>
                            </div>

                            <div className='form-group mt-3'>
                              <button type="submit" className="sub-btn active__tab_red" disabled={PassbtnDisable}>
                                {PassbtnDisable === true &&
                                  <div className="color-ring">
                                    <ClipLoader color="#36d7b7" />

                                    Please Wait...
                                  </div>
                                }
                                {PassbtnDisable === false ? 'Sell' : ''}
                              </button>
                            </div>

                          </form>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </TabPanel>


              <TabPanel>
                <P2pTab props={serchBuyData} market={elpMarket} />
              </TabPanel>

              <TabPanel>
                <div className="New__P2p__tabs___ad">
                  <MyAdsPost tab={pairPending} />
                </div>
              </TabPanel>

            </Tabs>
          </div>
        </div>
      </section>


      <Modal open={open} onClose={onCloseModal} center
        classNames={{
          modal: "New_modal_p2p",
        }}>

        <div className="main-head">
          <h4>Select Payment Method</h4>
        </div>

        {/* <li onClick={() => { setgetPeymentMethod('gpay'); setOpen(false); setBuyMethodView('Google Pay') }}>gpay</li>
          <li onClick={() => { setgetPeymentMethod('phonepe'); setOpen(false);; setBuyMethodView('Phone Pay') }}>Phone pay</li> */}

        <div className="recm-sec">
          <p>Recommended</p>
          <p className="disc">P2P Match With Sellers in Just One Click</p>

          <div className="cur-sec">
            <div className="g_pay" onClick={() => { setBuyMethodImage(g_pay); setgetPeymentMethod('gpay'); setOpen(false); setBuyMethodView('Google Pay') }}>
              <div className="icn_cur">
                <img src={g_pay} alt="coin" />
              </div>
              <div className="cur_title">
                <p>Google Pay</p>
              </div>
            </div>

            <div className="g_pay" onClick={() => { setBuyMethodImage(phonepe); setgetPeymentMethod('phonepe'); setOpen(false);; setBuyMethodView('Phone Pay') }}>
              <div className="icn_cur">
                <img src={phonepe} alt="coin" />
              </div>
              <div className="cur_title">
                <p>Phone Pay</p>
              </div>
            </div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  More third-party service providers
                </Accordion.Header>
                <Accordion.Body>
                  <div className="g_pay" onClick={() => { setBuyMethodImage(wallet); setgetPeymentMethod('bank'); setOpen(false);; setBuyMethodView('Bank') }}>
                    <div className="icn_cur">
                      <img src={wallet} alt="coin" />
                    </div>
                    <div className="cur_title">
                      <p>Bank Transfer</p>
                    </div>
                  </div>

                  <div className="g_pay" onClick={() => { setBuyMethodImage(upi); setgetPeymentMethod('upi'); setOpen(false);; setBuyMethodView('UPI') }}>
                    <div className="icn_cur">
                      <img src={upi} alt="coin" />
                    </div>
                    <div className="cur_title">
                      <p>UPI</p>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>


      </Modal>


      {/* sell model */}
      <Modal open={open1} onClose={onCloseModal1} center
        classNames={{
          modal: "sell-data-modal",
        }}>
        <div className="main-head">
          <h3>Payment Methods</h3>
        </div>
        <div className="recm-sec">
          <div className="accordion" id="accordionExample">

            {allAccountdetail?.map((method_val) => {
              let target_id = '#sk' + method_val._id;

              return (
                <>
                  <div className="cur-sec">
                    <div className="accordion">
                      <div className="accordion-item">
                        <div className="accordion-item___data">
                          <input type="checkbox" id={method_val?._id} checked={payment_methods?.includes(method_val?._id)} onClick={() => { addMethod(method_val?._id); getAccountDetails() }} className="form___check___AD" />
                          <label htmlFor={method_val?._id} className="check-box___aadfa ">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={target_id} aria-expanded="false" aria-controls="collapseTwo">
                                {method_val.type?.toUpperCase()} Details
                              </button>
                              <p className='status-btn'>Active</p>
                            </h2>
                          </label>
                        </div>

                        <div id={'sk' + method_val._id} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            {method_val.type === "bank" &&
                              <div className='holder-detail'>
                                <h4>Your bank account details for IMPS payments</h4>
                                <li><label>ACCOUNT Holder Name</label> <span className="ac-number"> {method_val.account_holder_name}</span></li>
                                <li><label>ACCOUNT NUMBER</label> <span className="ac-number">{method_val.account_number}</span></li>
                                {/* <li><label>IFSE CODE</label> <span className="ac-number">{method_val.ifcce}</span></li> */}
                                <li><label>ACCOUNT TYPE</label> <span className="ac-number">{method_val.account_type}</span></li>
                                {/* <div className='button'><button className="account-remove">Remove</button></div> */}
                              </div>
                            }
                            {method_val.type !== "bank" &&
                              <div className='holder-detail'>
                                <h4>Your UPI account details for  payments</h4>
                                <li><label> Name</label> <span className="ac-number"> {method_val.label}</span></li>
                                <li><label>UPI/VPA ID</label> <span className="ac-number">{method_val.upi_id}</span></li>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="ALl__buttons">
          <button type="button" className="btn___adds" onClick={() => onCloseModal1()}> Submit </button>
          <Link to="/dashboard/profile" className="btn___adds" state={{ active: true }}> ADD New Payment Method </Link>
        </div>

      </Modal >


    </>
  )
}

export default P2p;
