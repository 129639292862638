import React, { useEffect, useState } from 'react'
import "react-tabs/style/react-tabs.css";
import { Link, useLocation, useParams } from "react-router-dom";
import _fetch from '../../config/api';
import { api_url, receipt_img, } from '../../config/config';
import ReactPaginate from 'react-paginate';
import toasted from '../../config/toast';
import { ClipLoader } from 'react-spinners';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


const ViewAdsPostData = (props: any) => {
    var storedNames = JSON.parse(localStorage.getItem("user") || "");
    const { order_type, id } = useParams()
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");

    const location = useLocation()
    const { allCoin } = location?.state


    // handle onChange event of the dropdown/////////////////////////////////////////////////
    //  const handleChange = (e) => {
    //     setSelectedOption(e);
    //     // setSearchedType('sell_currency');
    //     setsellCoinType(e.value)
    // }
    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allData, setallData] = useState([])
    async function getAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }

        if (order_type === 'buy') {
            var typeData = `search=buyer_order_id&value=${id}`
        } else {
            var typeData = `search=seller_order_id&value=${id}`

        }

        let d = await _fetch(`${api_url}trade/p2p-self-view-Transactons?${typeData}&page=${BuyItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallData(d?.data.data);
            setbuyRecords(d.total);
        }
    }
    const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '' });


    useEffect(() => {
        document.title = props.title;
        (async () => {
            const userData = await _fetch(`${api_url}user/userInfo/`, 'GET');
            setUserInfo(userData?.data?.data);

        })();
        getAllData()
    }, []);
    const [PassbtnDisable, setPassbtnDisable] = useState('');
    const [otp, setotp] = useState('');

    const [approveID, setapproveID] = useState('');


    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [open1, setOpen1] = useState(false);
    const onOpenModal1 = (id: any) => {
        setOpen1(true);
        setapproveID(id)
    }
    const onCloseModal1 = () => setOpen1(false);
    const [otpType, setotpType] = useState('')

    const ApproveData = async (e: any) => {
        // setPassbtnDisable(true)
        e.preventDefault()

        var data = {
            "id": approveID,
            "orderType": "sell",
            "otp": otp,
            "update":otpType,
        }
        var d = await _fetch(`${api_url}trade/p2p-approve-order`, 'POST', data, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            //  setOpenQR(false);
            setPassbtnDisable('')
            onCloseModal1()
            getAllData()

        } else {
            setPassbtnDisable('')

            toasted.error(d.message);

        }
    }

    const [trantionIMG, settrantionIMG] = useState('');

    const heandchange = (e: any) => {
        setOpen(true);
        settrantionIMG(e)
    }




    async function createOTP(e: any) {
        e.preventDefault()
        if (userInfo.phone !== '') {
            var data: any = `&sendTo=${userInfo.phone}&check=phone`;
            setotpType(userInfo.phone)
        }
        else {
            var data: any = `&sendTo=${userInfo.email}&check=email`;
            setotpType(userInfo.email)
        }

        var d = await _fetch(`${api_url}auth/generateAuthOTP?email_type=Update Profile${data}`, 'GET');
        // var d = await _fetch(`${api_url}auth/generateOTP?check=P2P&email_type=P2P`, 'GET');
        if (d?.status === 'success') {
            toasted.success(d?.message);

            //   if (timer === "00.00") {
            //     var seconds: any = '180';
            //     var intervalId = window.setInterval(() => {
            //       seconds--;

            //       let minutes: any = Math.floor(seconds / 60);
            //       let exterseconds: any = seconds % 60;
            //       minutes = minutes < 10 ? "0" + minutes : minutes;
            //       exterseconds = exterseconds < 10 ? "0" + exterseconds : exterseconds;
            //       var leftData = minutes + ':' + exterseconds;

            //       settimer(leftData);

            //       if (seconds == 0) {
            //         stop();
            //       }

            //     }, 1000);
            //   }
            //   let stop = () => clearInterval(intervalId);
        }
        else {
            localStorage.clear();
            toasted.error(d?.message);
        }
    }

    return (
        <>
            <div className='container-lg mt-5'>
                <div className='my-ads-post MY__NEW___ADS__POST' style={{ backgroundColor: "transparent" }}>
                    <div className="new_market__p2p">
                        <div className="card p2p_tab_card table__p2p__history_demo " style={{ border: '0' }}>

                            <div className="table__p2p__history p-2">
                                <table className="table">
                                    <thead>
                                        <tr className="">
                                            <th>Advertiser</th>
                                            <th>Price</th>
                                            <th>Coin Request</th>
                                            <th>Total Price (INR)</th>

                                            {/* <th>Available
                                                <span className="th__divider__span"></span>
                                                Limits
                                            </th> */}
                                            <th>Payment Method	</th>
                                            <th>Type	</th>
                                            <th>Buyer Order ID</th>
                                            <th> Receipt</th>
                                            <th>Tranaction ID</th>
                                            <th>Status</th>
                                            {order_type !== 'buy' && <th>Action</th>}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData?.map((val: any) => {
                                            return (
                                                <>
                                                    {order_type === 'buy' && <tr>
                                                        <td>
                                                            <div className="p2p-TD_1">
                                                                {/* <div className="Img__user__p2p">A</div> */}
                                                                <div className="user__info__p2p">
                                                                    <p>{val.buy_currency?.toUpperCase()}</p>
                                                                    {/* <h6>65 Order(s)| 93 % </h6>
                                                                                <h6>Active in the last 1 hour</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{val.price} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                        <td>
                                                            <div className="History___p2p__ad">
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Receivable</p>
                                                                    <p>{val.received_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                </div>
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Pending</p>
                                                                    <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> ---- </div>
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> BUY </div>
                                                        </td>
                                                        <td><div className='mad-btn'>{val.seller_order_id}</div></td>

                                                        <td><div className='mad-btn'>{val.order_status}</div></td>

                                                        {/* <td>
                                                                        <div className="btn_hit_p2p">
                                                                            <Link className="trade_btn active" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`}> View</Link>
                                                                        </div>
                                                                    </td> */}
                                                    </tr>}

                                                    {order_type !== 'buy' && <tr>
                                                        <td>
                                                            <div className="p2p-TD_1">
                                                                {/* <div className="Img__user__p2p">A</div> */}
                                                                <div className="user__info__p2p">
                                                                    <p>{val.sell_currency?.toUpperCase()}</p>
                                                                    {/* <h6>65 Order(s)| 93 % </h6>
                                                                                <h6>Active in the last 1 hour</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{val.price?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>
                                                        <td>{val.amount?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                        <td>{(val.amount * val.price)?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>

                                                        {/* <td>
                                                            <div className="History___p2p__ad">
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Available</p>
                                                                    <p>{val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                </div>
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Limits</p>
                                                                    <p>{val.minimum_limit} ~ {val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                </div>

                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            {val.payment_method?.map((method: any) => {
                                                                return (
                                                                    <div className="mad-btn"> {method.type} </div>

                                                                );
                                                            })}
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> Sell </div>
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> {val.buyer_order_id} </div>
                                                        </td>
                                                        <td>
                                                            {val.image ? <div className='mad-btn'>
                                                                <img src={receipt_img + val.image} alt="Tranaction Image" className='document_img' onClick={(e: any) => { heandchange(receipt_img + val.image) }} />
                                                            </div> : 'No Image'}
                                                        </td>
                                                        {/* <td><div className='mad-btn'>{val.receipt}</div></td> */}
                                                        <td><div className='mad-btn'>{val.trx_id}</div></td>
                                                        <td><div className='mad-btn'>{val.order_status === 'paired' ? 'Pending' : val.order_status}</div></td>


                                                        <td>
                                                            {/* <div className="btn_hit_p2p">
                                                                {val.order_status !== 'completed' && <button className="btn table-buy-btn" >
                                                                    {PassbtnDisable === val._id &&
                                                                        <div className="color-ring">
                                                                            <ClipLoader color="#36d7b7" />

                                                                            Please Wait...
                                                                        </div>
                                                                    }
                                                                    {PassbtnDisable === '' || PassbtnDisable !== val._id ? 'Approve' : ''}   </button>}
                                                                {val.order_status === 'completed' && <button className="btn table-buy-btn" style={{ background: 'green', color: '#fff' }} > Approved  </button>}
                                                            </div> */}
                                                            <div className="new_button mt-3">
                                                                <button className='btn table-buy-btn' onClick={() => { onOpenModal1(val._id) }} type='button' disabled={val.order_status === 'completed' ? true : false}>{val.order_status === 'completed' ? 'Approved' : 'Approve'}</button>
                                                            </div>
                                                            <Link className="btn table-buy-btn" to={`/dashboard/ChatP2p/sell/${val.buyer_order_id}`}> Chat</Link>
                                                        </td>
                                                    </tr>}
                                                </>
                                            );
                                        })}

                                    </tbody>
                                </table>

                                <div className="update_trending_coin_tabs">
                                    <div className="tabs_content active">
                                        <div className="content_inner_data"></div>
                                        {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={BuyhandlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={buyRecords / 10}
                                            previousLabel="<"

                                        // renderOnZeroPageCount={null}
                                        />}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </div>


            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: "Tranaction_image",
                }}>
                <h5 className='heading__document'>document preview</h5>
                <div className='document-preview'>
                    <img src={trantionIMG} alt='Tranaction_image' height="200px" className='document__img_modal' />
                </div>
            </Modal>

            <Modal open={open1} onClose={onCloseModal1} center
                classNames={{
                    modal: "NEW__MODAL_ADD",
                }}>
                <form onSubmit={ApproveData}>

                    <div className="inner_data">
                        <input type="text" placeholder='OTP' value={otp} onChange={(e: any) => { setotp(e.target.value) }} />
                        <button className='btn table-buy-btn' type='button' onClick={(e: any) => createOTP(e)}>Send OTP</button>
                    </div>

                    {/* onClick={() => { ApproveData(val._id); setPassbtnDisable(val._id) }} */}
                    <div className="button d-flex align-items-center justify-content-center">
                        <button className='btn table-buy-btn' type='submit' >Submit</button>
                    </div>
                </form>
            </Modal>




        </>
    )
}

export default ViewAdsPostData