import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import CancelIcon from '@mui/icons-material/CancelRounded';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from "../../config/toast";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LoginSide from './LoginSide';
import comet from "../../Assets/Img__v2/comet.png"
import star from "../../Assets/Img__v2/star.png"


const Login = () => {


    const Navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [password, setpassword] = useState('')
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [otp, setOTP] = useState('');
    const [btnDisable, setbtnDisable] = useState(false);

    const loginSubmit = async (e: any) => {
        setbtnDisable(true)
        e.preventDefault();


        const loginData = {
            email: email,
            password: password,
            otp: otp,
            login_type: "email",
        }
        var postLoginData = await _fetch(`${api_url}auth/login`, 'POST', loginData, {});

        if (postLoginData?.status === 'success') {

            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", postLoginData?.token);
            if (postLoginData?.status === 'success') {
                localStorage.setItem("user", JSON.stringify(postLoginData.data.user));
            }
            Navigate("/dashboard");
            toasted.success('Login Successfully!');
            setbtnDisable(false)

        }
        else {
            localStorage.clear();
            setbtnDisable(false)

            toasted.error(postLoginData?.message);
        }

    }

    const loginSubmitPhone = async (e: any) => {
        setbtnDisable(true)

        e.preventDefault();

        const loginData = {
            phone: phone,
            password: password,
            otp: otp,
            login_type: "phone",
        }
        var postLoginData = await _fetch(`${api_url}auth/login`, 'POST', loginData, {});

        if (postLoginData?.status === 'success') {

            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", postLoginData?.token);
            if (postLoginData?.status === 'success') {
                localStorage.setItem("user", JSON.stringify(postLoginData.data.user));
            }
            Navigate("/dashboard");
            toasted.success('Login SuccessFully!');
            setbtnDisable(false)

        }
        else {
            localStorage.clear();
            toasted.error(postLoginData?.message);
            setbtnDisable(false)

        }

    }
    const [timer,settimer] = useState('00:00');
    const [phoneTimer,setphoneTimer] = useState('00:00');


    async function generateSignUpOTP(val: any, data: any) {
        var passData: any = "";
        if (val === 'phone') {
            passData = `&sendTo=${data}&check=phone`;
        } else if (val === 'email') {
            passData = `&sendTo=${data}&check=email`;

        }

        var d = await _fetch(`${api_url}auth/generateAuthOTP?email_type=Login${passData}`, 'GET', {}, {});
        if (d?.status === 'success') {
            toasted.success(d?.message);

            if (timer === "00:00") {
                var seconds: any = '180';
                var intervalId = window.setInterval(function () {
                    seconds--;

                    // if (seconds > 1) {
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;

                    if (val === 'phone') {
                        setphoneTimer(leftData)
                    } else {
                        settimer(leftData)
                    }
                    if (seconds == 0) {
                        stop();

                    }

                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
        } else {
            localStorage.clear();
            toasted.error(d?.message);

        }
    }


    // document.addEventListener(“keydown”, e => {
    //     if(
    //         e.key === “k” && e.metaKey)
    //         {
    //             // onOpenModal()
    //         }
    // })

    return (
        <div>
            <div className="Login-sec only-margin">

                {/* <div className="stars">
                    <div id="stars-group-1"></div>
                    <div id="stars-group-2"></div>
                    <div id="stars-group-3"></div>
                    <div id="stars-group-4"></div>
                    <div id="stars-group-5"></div>
                    <div id="stars-group-6"></div>

                </div> */}
                <div className="coustom_container">

                    <div className="for-overflow">

                        <img className='comet1' src={comet} alt="" />
                        <img className='comet2' src={comet} alt="" />
                        <img className='comet3' src={comet} alt="" />
                        <img className='comet4' src={comet} alt="" />
                        <img className='comet5' src={comet} alt="" />
                        <img className='comet6' src={comet} alt="" />
                    </div>
                    <div className="Stater-sec">

                        <LoginSide />

                        <div className="left">
                            <div className="inner_star">
                                <img src={star} className='star1' alt="" />
                                <img src={star} className='star2' alt="" />
                                {/* <img src={star} className='star3' alt="" /> */}
                                {/* <img src={star} className='star4' alt="" /> */}
                            </div>
                            <div className="inner">
                                <h1>Account Login</h1>
                                <Tabs>
                                    <TabList>
                                        <Tab><EmailOutlinedIcon />Email</Tab>
                                        <Tab><PhoneAndroidOutlinedIcon />Phone Number</Tab>
                                    </TabList>


                                    <TabPanel>
                                        <form className="form" onSubmit={loginSubmit}>
                                            <div className="group">
                                                <label>Email</label>
                                                <div className='email-field-sec'>
                                                    <input type="text" className="form-control inputt" placeholder="Email" required value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                                    <button className='send-button' type="button" onClick={() => { generateSignUpOTP('email', email) }} > 
                                                    {timer === "00:00" ? 'Send OTP': timer }
                                                    

                                                    </button>
                                                </div>
                                            </div>

                                            <div className="group">
                                                <label>OTP</label>
                                                <input type="number" className="form-control inputt" placeholder="OTP" required value={otp} onChange={(e) => { setOTP(e.target.value) }} />
                                            </div>

                                            <div className="group">
                                                <label>Password</label>

                                                <div className='group-inner'>
                                                    <input type={showPassword ? "text" : "password"} className='form_control inputt11' placeholder='Password' name="Password" value={password} onChange={(e: any) => { setpassword(e.target.value) }} required />
                                                    <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                        {showPassword ?
                                                            <VisibilityTwoToneIcon />
                                                            :
                                                            <VisibilityOffTwoToneIcon />
                                                        }
                                                    </button>
                                                    {password !== '' && password !== undefined && <div className="cross" onClick={() => { setpassword('') }}>
                                                        <CancelIcon />
                                                    </div>}
                                                </div>

                                                {/* <div className="">
                                            <input type="Password" className="" placeholder="Login password" />

                                            </div> */}
                                            </div>



                                            <Link to="/forgot-password">Forgot password?</Link>
                                            <div className="login__button">

                                                <button className='button' type='submit'>
                                                    <div className="service service1">
                                                        <div className="front">
                                                            <h4>Login</h4>
                                                        </div>
                                                        <div className="back">
                                                            <h4>Submit</h4>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                            {/* <button type='submit' className='main-button submit-button'  disabled={btnDisable}>
                                                {btnDisable === true &&
                                                    <div className="color-ring">
                                                        <ClipLoader color="#36d7b7" />

                                                        Please Wait...
                                                    </div>
                                                }
                                                {btnDisable === false ? 'Login ' : ''}
                                                </button> */}
                                            <p>Don't have an account? <Link to="/sign-up"> Sign up</Link></p>
                                        </form>
                                    </TabPanel>
                                    <TabPanel>
                                        <form className="form" onSubmit={loginSubmitPhone}>
                                            <div className="group">
                                                <label>Phone Number</label>
                                                <div className='email-field-sec'>
                                                    <PhoneInput
                                                        // inputStyle={{ }}
                                                        inputClass={"inputt-ph"}
                                                        containerStyle={{}}
                                                        searchClass="search-class"
                                                        disableSearchIcon={false}
                                                        enableTerritories
                                                        countryCodeEditable={true}
                                                        placeholder='Phone Number'
                                                        buttonStyle={{ width: '47px' }}
                                                        dropdownStyle={{ height: '150px' }}
                                                        country={"in"}
                                                        enableSearch={true}
                                                        onChange={(e) => { setPhone(e) }}
                                                    // onChange={(newValue: any) => setsignupPhone(newValue)} 
                                                    />
                                                    <button type='button' className='send-button' onClick={() => { generateSignUpOTP('phone', phone) }}>                                             
                                                            {phoneTimer === "00:00" ? 'Send OTP': phoneTimer }


                                                    </button>
                                                </div>
                                            </div>

                                            <div className="group">
                                                <label>OTP</label>
                                                <input type="number" className="form-control inputt" placeholder="OTP" required value={otp} onChange={(e) => { setOTP(e.target.value) }} />
                                            </div>

                                            <div className="group">
                                                <label>Password</label>
                                                <div className='group-inner'>
                                                    <input type={showPassword ? "text" : "password"} className='form_control inputt11' placeholder=' Password' name="Password" value={password} onChange={(e: any) => { setpassword(e.target.value) }} required />
                                                    <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                        {showPassword ?
                                                            <VisibilityTwoToneIcon />
                                                            :
                                                            <VisibilityOffTwoToneIcon />
                                                        }
                                                    </button>
                                                    {password !== '' && password !== undefined && <div className="cross" onClick={() => { setpassword('') }}>
                                                        <CancelIcon />
                                                    </div>}
                                                </div>
                                            </div>
                                            <Link to="/forgot-password">Forgot password?</Link>
                                            <div className="login__button">

                                                <button className='button' type='submit'>
                                                    <div className="service service1">
                                                        <div className="front">
                                                            <h4>Login</h4>
                                                        </div>
                                                        <div className="back">
                                                            <h4>Submit</h4>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                            {/* <button type='submit' className='main-button submit-button' disabled={btnDisable}>
                                            {btnDisable === true &&
                                                <div className="color-ring">
                                                    <ClipLoader color="#36d7b7" />

                                                    Please Wait...
                                                </div>
                                            }
                                            {btnDisable === false ? 'Login ' : ''}
                                        </button> */}
                                            <p>Don't have an account? <Link to="/sign-up"> Sign up</Link></p>
                                        </form>
                                    </TabPanel>
                                </Tabs>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
