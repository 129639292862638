import React, { useEffect, useState } from 'react'
import Typography from "@mui/material/Typography";
import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import Table from 'react-bootstrap/esm/Table';
import { Link } from 'react-router-dom';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import ReactPaginate from 'react-paginate';
import _fetch from '../../config/api';
import { markit_url } from '../../config/config';
import { ArrowBackIosNew } from '@mui/icons-material';
import LoaderCom from '../../config/LoaderCom';



interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}
function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <div style={{ padding: "3px" }}>
               <Typography sx={{ fontFamily: "open sans" }}>{children}</Typography>
            </div>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

const Markets = () => {
   const [searchedValue, setSearchedValue] = useState("");
   const [allData, setAllData] = useState([]);
   const [value, setValue] = React.useState(0);
   const [isChecked, setIsChecked] = React.useState(false);
   const [searchValue, setSearchValue] = useState();
   const [emptyData, setemptyData] = useState([]);



   

   const auth = localStorage.getItem("auth");



   const [allMarket, setMarket] = useState([]);
   const [itemOffset, setItemOffset] = useState(1);
   const [records, setrecords] = useState(0);
   const [short, setshort] = useState('market_cap_rank');
   const [tab, setTab] = useState("Trending");
   const [pageCount, setPageCount] = useState(0);

   useEffect(() => {
      (async () => {
         setMarket([])
         setemptyData([])
         const passData = {
            currency: 'USD',

            // fields:'_id'
         }
         if (searchValue) {
            var newSrch = `&symbol=${searchValue}`;
         } else {
            var newSrch = '';
         }
         const data1 = await _fetch(`${markit_url}/market/getAllMarket?currency=USD&limit=10&sort=${short}&page=${itemOffset}${newSrch}`, 'GET', passData, {});
         // const dataaa = await JSON.stringify(data1);
         setemptyData(data1.data.data)
         setMarket(data1?.data?.data);
         setrecords(data1.total)

      })()
      setPageCount(Math.ceil(records / 10));

   }, [itemOffset, records, short, searchValue]);
   const handlePageClick = async (event: any) => {
      const newOffset = (event.selected + 1);
      setItemOffset(newOffset);
   };

   return (
      <div className="markets_page innercum_margin pt-5">

         <div className="inner-crum coustom_container">
            <div className="inner-crum_dc">
               <div className="inner-crum-left">
                  <span>  <Link to="/dashboard" className="arrow-links"><ArrowBackIosNew sx={{ fontSize: "20px" }} /></Link> </span>
                  <h3> Market </h3>
               </div>
               {/* <Link to="/dashboard" className="More__links"> Dashboard</Link> */}
            </div>
         </div>



         <div className="coustom_container market_baner pt-5">
            <h1 className='Heading__txt__seaf'>Markets</h1>

            <div style={{ width: "100%", boxShadow: "none" }} className="main-tab">
               <div className="tab-inner1">
                  <div className="tab-inner">

                     <div className='searchbar'>
                        <Form.Control type="search" placeholder="Search" onChange={(e: any) => setSearchValue(e.target.value)} />
                     </div>

                  </div>
                  <TabPanel value={value} index={0}>
                     <div className="data-table">
                        <Table hover>
                           <thead>
                              <tr className='table-row'>
                                 <th>COIN</th>
                                 <th>LAST PRICE</th>
                                 <th>24H CHANGE</th>
                                 <th>MARKETS</th>
                                 <th style={{ textAlign: "center" }}>TRADE</th>
                              </tr>
                           </thead>
                           <tbody>

                              <LoaderCom condition={emptyData} text={'Getting latest values...'} />

                              {allMarket?.map((val: any,key:any) => {
                                 return (
                                    <tr className='table-row' key={key}>
                                       <td>
                                          <div className="tab1">
                                             <div>
                                                <img src={val?.image} alt="" className="tab-img" width={30} height={30} />
                                             </div>
                                             <div>
                                                <h5>{val?.symbol?.toUpperCase()}/{val?.currency?.toUpperCase()}</h5>
                                                <span>{val?.name?.toUpperCase()}</span>
                                             </div>
                                          </div>
                                       </td>
                                       <td>${val.current_price?.toFixed(5)}</td>
                                       <td>
                                          <p>  <span style={{ color: val?.price_change_percentage_24h > 0 ? 'green' : 'red' }}>{val?.price_change_percentage_24h}%</span></p>
                                       </td>


                                       <td>

                                          <div className="sparkline-area">
                                             {(() => {
                                                if (val.price_change_percentage_24h > 0) {
                                                   return (
                                                      <div><Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                         <SparklinesLine color="green" />
                                                      </Sparklines></div>
                                                   )
                                                } else {
                                                   return (
                                                      <div><Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                         <SparklinesLine color="red" />
                                                      </Sparklines></div>
                                                   )
                                                }
                                             })()}
                                          </div>

                                       </td>
                                       <td>
                                          <div className="tab-btn trade-btn">
                                             <div>
                                                {val.symbol.toLowerCase() !== 'stacoin' &&
                                                   <li>
                                                      <Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items btn-items_2"> Trade </Link>

                                                   </li>}

                                                {val.symbol.toLowerCase() === 'stacoin' &&

                                                   <li>
                                                      <Link to='' className="btn-items btn-items_2"> Coming Soon </Link>

                                                   </li>
                                                }

                                             </div>

                                          </div>
                                       </td>
                                    </tr>
                                 )
                              })}


                           </tbody>
                        </Table>
                     </div>
                  </TabPanel>

                  <TabPanel value={value} index={1}>


                     <div className="data-table">
                        <Table hover>
                           <thead>
                              <tr>
                                 <th>COIN</th>
                                 <th>LAST PRICE</th>
                                 <th>BALANCE</th>
                                 <th>24H CHANGE</th>
                                 <th>MARKETS</th>
                                 <th style={{ textAlign: "center" }}>TRADE</th>
                              </tr>
                           </thead>
                           <tbody>

                              {allData?.filter((val: any, index: any) =>

                                 !searchedValue.length || val?.symbol.toString().toLowerCase().includes(searchedValue.toString().toLowerCase())
                              ).map((val: any, index: any) => {

                                 return (
                                    <>
                                       {val.total_coin > 0 && isChecked === true && <>

                                          <tr key={index}>
                                             <td>
                                                <div className="tab1">
                                                   <div>
                                                      <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                                   </div>
                                                   <div>
                                                      <h5>{val.symbol.toUpperCase()}</h5>
                                                      <span>{val.name}</span>
                                                   </div>
                                                </div>
                                             </td>
                                             <td>{'$' + val.current_price}</td>
                                             <td>
                                                <p> {val.total_coin?.toFixed(3)} {val.symbol.toUpperCase()}</p>
                                                <small>${val.usdt_income?.toFixed(3)} </small>
                                             </td>
                                             <td>
                                                <Link to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                                   {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                                                </Link>
                                             </td>

                                             <td>
                                                {(() => {
                                                   if (val.price_change_percentage_24h > 0) {
                                                      return (
                                                         <div className="sparkline-area">
                                                            {val.sparkline_in_7d !== null &&
                                                               <Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                                  <SparklinesLine color="green" />
                                                               </Sparklines>}
                                                         </div>
                                                      )
                                                   }
                                                   else {
                                                      return (
                                                         <div className="sparkline-area">
                                                            {val.sparkline_in_7d !== null &&

                                                               <Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                                  <SparklinesLine color="red" />
                                                               </Sparklines>
                                                            }
                                                         </div>
                                                      )
                                                   }
                                                })()}

                                             </td>
                                             <td>
                                                <div className="tab-btn">
                                                   <div>
                                                      <Button variant="link" className="btn-items btn-items_2">
                                                         Trade
                                                      </Button>
                                                   </div>

                                                </div>
                                             </td>
                                          </tr>
                                       </>}

                                       {isChecked === false && <>

                                          <tr key={index}>
                                             <td>
                                                <div className="tab1">
                                                   <div>
                                                      <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                                   </div>
                                                   <div>
                                                      <h5>{val.symbol.toUpperCase()}</h5>
                                                      <span>{val.name}</span>
                                                   </div>
                                                </div>
                                             </td>
                                             <td>{'$' + val.current_price}</td>
                                             <td>
                                                <p> {val.total_coin?.toFixed(3)} {val.symbol.toUpperCase()}</p>
                                                <small>${val.usdt_income?.toFixed(3)} </small>
                                             </td>
                                             <td>
                                                <Link to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                                   {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                                                </Link>
                                             </td>

                                             <td>
                                                {(() => {
                                                   if (val.price_change_percentage_24h > 0) {
                                                      return (
                                                         <div className="sparkline-area">
                                                            <Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                               <SparklinesLine color="green" />
                                                            </Sparklines>
                                                         </div>
                                                      )
                                                   }
                                                   else {
                                                      return (
                                                         <div className="sparkline-area">
                                                            <Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                               <SparklinesLine color="red" />
                                                            </Sparklines>
                                                         </div>
                                                      )
                                                   }
                                                })()}

                                             </td>
                                             <td>
                                                <div className="tab-btn">
                                                   <div>
                                                      <Button variant="link" className="btn-items btn-items_2">
                                                         Trade
                                                      </Button>
                                                   </div>

                                                </div>
                                             </td>
                                          </tr>
                                       </>}
                                    </>
                                 )

                              })
                              }


                           </tbody>
                        </Table>
                     </div>
                  </TabPanel>

                  <TabPanel value={value} index={2}>

                     <div className="data-table">
                        <Table hover>
                           <thead>
                              <tr>
                                 <th>COIN</th>
                                 <th>LAST PRICE</th>
                                 <th>BALANCE</th>
                                 <th>24H CHANGE</th>
                                 <th>MARKETS</th>
                                 <th style={{ textAlign: "center" }}>TRADE</th>
                              </tr>
                           </thead>
                           <tbody>

                              {allData?.filter((val: any, index: any) =>

                                 !searchedValue.length || val?.symbol.toString().toLowerCase().includes(searchedValue.toString().toLowerCase())
                              ).map((val: any, index: any) => {

                                 return (
                                    <>


                                       {isChecked === false && <>

                                          <tr key={index}>
                                             <td>
                                                <div className="tab1">
                                                   <div>
                                                      <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                                   </div>
                                                   <div>
                                                      <h5>{val.symbol.toUpperCase()}</h5>
                                                      <span>{val.name}</span>
                                                   </div>
                                                </div>
                                             </td>
                                             <td>{'$' + val.current_price}</td>
                                             <td>
                                                <p> {val.total_coin?.toFixed(3)} {val.symbol.toUpperCase()}</p>
                                                <small>${val.usdt_income?.toFixed(3)} </small>
                                             </td>
                                             <td>
                                                <Link to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                                   {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                                                </Link>
                                             </td>

                                             <td>
                                                {(() => {
                                                   if (val.price_change_percentage_24h > 0) {
                                                      return (
                                                         <div className="sparkline-area">
                                                            <Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                               <SparklinesLine color="green" />
                                                            </Sparklines>
                                                         </div>
                                                      )
                                                   }
                                                   else {
                                                      return (
                                                         <div className="sparkline-area">
                                                            <Sparklines data={val.sparkline_in_7d?.[0]?.price}>
                                                               <SparklinesLine color="red" />
                                                            </Sparklines>
                                                         </div>
                                                      )
                                                   }
                                                })()}

                                             </td>
                                             <td>
                                                <div className="tab-btn">
                                                   <div>


                                                      <Link to={val.token_type !== 'auto' ? `/trades/${val.symbol.toUpperCase()}` : `/trade/${val.symbol.toUpperCase()}`} className="btn-items btn-items_2"> Trade </Link>

                                                   </div>

                                                </div>
                                             </td>
                                          </tr>
                                       </>}
                                    </>
                                 )

                              })
                              }


                           </tbody>
                        </Table>
                     </div>
                  </TabPanel>

                  {records > 10 && <ReactPaginate className="market-pagination " activeClassName="active"

                     breakLabel="..."
                     nextLabel=">"
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={1}
                     pageCount={records / 10}
                     previousLabel="<"

                  />}
               </div>
            </div>

         </div>



      </div>
   )
}

export default Markets
