import React, { useEffect, useState } from "react";
import { VisibilityOffTwoTone, VisibilityTwoTone, East, AssignmentIndRounded, RocketLaunchRounded, BookRounded, GradeRounded, AssessmentRounded, Segment, ArrowDropDown, WbSunnyOutlined,  NightsStayOutlined, Person4TwoTone, NorthEastTwoTone, LogoutTwoTone, PublicTwoTone, CurrencyExchangeTwoTone } from "@mui/icons-material";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api_url, logo, public_site_url, title } from "../config/config";
import Modal from "react-responsive-modal";
import 'react-tabs/style/react-tabs.css';
import _fetch from "../config/api";
import toasted from "../config/toast";





const Header = () => {

  const navigate = useNavigate();
  // const [sidebar, setSidebar] = useState(false);
  // const showSidebar = () => setSidebar(!sidebar);


  const auth = localStorage.getItem("auth");


  var className = "header__data";
  var scrollTrigger = 400;

  window.onscroll = function () {
    // We add pageYOffset for compatibility with IE.
    if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      document.getElementsByTagName("header")[0].classList.add(className);
    } else {
      document.getElementsByTagName("header")[0].classList.remove(className);
    }
  };



  const [theme, setTheme] = useState('light');


  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      localStorage.setItem("mode", 'dark');
      localStorage.setItem("mode_chart", 'dark');

    } else {
      setTheme('light');
      localStorage.setItem("mode", 'light');
      localStorage.setItem("mode_chart", 'light');

    }
  };
  // modeTheme 
  let modeTheme = localStorage.getItem("mode");

  useEffect(() => {
    const body: HTMLElement | any = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);
  }, [theme]);

  var themeModeSave = localStorage.getItem("mode");

  useEffect(() => {
    setTheme(`${themeModeSave}`)
    if (themeModeSave) {

    } else {
      localStorage.setItem("mode", 'dark');
      localStorage.setItem("mode_chart", 'dark');
    }
  }, [theme]);



  const checkAuth = localStorage.getItem('auth')

  const location = useLocation();

  useEffect(() => {

    if (checkAuth === "true") {
      (async () => {
        const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", "", {});
        if (data1?.data?.data) {

        } else {
          localStorage.clear();
          navigate("/login");
        }
      })();
    }
  }, [location.pathname]);


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  });



  // (async () => {
  const logout = async () => {
    var subdata = await _fetch(`${api_url}auth/logout`, 'GET', {}, {});
    if (subdata.status === 'success') {
      toasted.success(subdata.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login')
    } else {
      toasted.error(subdata.message);
    }
  }
  // )}
  const [showAssets, setShowAssets] = useState(false);



  const [getSpotBalance, setGetSpotBalance] = useState({ main_account: 0, funding: 0, trading: 0 });

  async function getSpotTotalBalance() {
    let spotBalanceData = await _fetch(`${api_url}incomes/portfolio`, 'GET', {}, {});
    setGetSpotBalance({ main_account: spotBalanceData.data[1].total_amount, funding: spotBalanceData.data[2].total_amount, trading: spotBalanceData.data[0].total_amount });
  }

  useEffect(() => {
    getSpotTotalBalance();
  }, [])


  let dropdownToggleremove = () => {
    if (window.screen.width! <= 992) {
      // window.location.reload();
      const rbs: HTMLElement | any = document.getElementById("drophover_click");
      rbs.classList.remove("drophover_click");
    }
  };


  const authLogo = () => {


    if (auth !== "true") {
      return (
        <>
          <Link to={public_site_url + "/"} className="web-logo">
            <img src={logo} alt={title + "Logo"} />
          </Link>
        </>
      )
    }

    else {
      return (
        <>
          <Link to="/dashboard" className="web-logo">
            <img src={logo} alt={title + "Logo"} />
          </Link>


        </>
      )
    }
  }



  // NEW ADD STATE.....


  const [menuBar, setmenuBar] = useState(false);
  const onOpenmenuBar = () => setmenuBar(true);
  const onClosemenuBar = () => setmenuBar(false);

  return (
    <>

      <div className="header-add-bg" >
        <header className="h_header">
          <div className="h_container">
            <div className="h_wrapper">

              {/* HEADER LEFT SEC.. */}
              <div className="left_header">

                {/* HEADER LOGO.... */}

                <div className="h_logo">
                  {/* Auth fun... working...  */}
                  {authLogo()}
                </div>

                {/* HEADER LEFT SEC.. ALL LINKS... */}
                <ul className="nav">
                  <li className="nav-item">
                    <Link to={public_site_url + "/buy-crypto"} className="nav-link">   Buy Crypto </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/dashboard/markets" className="nav-link">
                      Markets </Link>
                  </li>


                  <li className="nav-item">
                    <Link to={public_site_url + "/referral-program"} className="nav-link"> Referrals </Link>
                  </li>

                  <li className={location.pathname === '/dashboard' ? "nav-item" : 'nav-item'} >
                    <Link to={''} className="nav-link"> Explore <ArrowDropDown className="drop__arrow" /> </Link>


                    {/* HEADER LEFT SIDE DROPDOWN...  */}
                    <div className="drophover">
                      <ul className="drop-nav ps-0">
                        <Row className="align-items-start">
                          <Col lg={6}>
                            <Row className="align-items-start">
                              <Col md={12}>
                                <Link to={public_site_url + "/launchpad"} className="drop-link">
                                  <AssignmentIndRounded />
                                  <div className="inner">
                                    <h3> LeaderBoard</h3>
                                    <p>Join in on our giveaways and promotions</p>
                                  </div>
                                </Link>
                              </Col>
                              <Col md={12}>
                                <Link to={public_site_url + "/launchbase"} className="drop-link">
                                  <RocketLaunchRounded />
                                  <div className="inner">
                                    <h3> Launchbase</h3>
                                    <p>Participate in our latest token sale</p>
                                  </div>
                                </Link>
                              </Col>
                              <Col md={12}>
                                <Link to={public_site_url + "/bonus"} className="drop-link">
                                  <GradeRounded />
                                  <div className="inner">
                                    <h3> Reward Center</h3>
                                    <p>Complete tasks and get rewards in one click</p>
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row className="promotions">
                              <Col md={12}>
                                <Link to={public_site_url + "/blog"} className="drop-link">
                                  <BookRounded />
                                  <div className="inner">
                                    <h3> {title} Blog</h3>
                                    <p>Learn and stay up-to-date on all things crypto!</p>
                                  </div>
                                </Link>
                              </Col>
                              <Col md={12}>
                                <Link to={public_site_url + "/promotions"} className="drop-link">
                                  <AssessmentRounded />
                                  <div className="inner">
                                    <h3> Promotions</h3>
                                    <p>Complete tasks and get rewards in one click</p>
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>



              {/* HEADER RIGHT SEC...  */}

              <div className="right_header">
                <ul className="nav">

                  <li className="nav-item nav-btn-item">
                    {auth === "true" ?
                      <Link className={location.pathname === '/dashboard/deposit' ? "header-common-button activeLink" : 'header-common-button'} to="/dashboard/deposit"> Deposit </Link>
                      :
                      <>
                        <Link className="header-common-button hide-sm-items" to="/login"> Login </Link>
                        <Link className="header-common-button" to="/sign-up"> Register </Link>
                      </>

                    }

                  </li>


                  {/* GO TO DOWNLOAD SEC... */}

                  <div className="divider_area">

                    {auth === "true" ?

                      <>
                        <li className="nav-item left-divider-line">

                          <span className="systemButton">
                            <Person4TwoTone />
                          </span>

                          <div className="drophover">
                            <div className="drophover-inner">

                              <div className="assets_content">
                                <div className="d_flex">
                                  <h6>Total Assets</h6>
                                  <button type="button" className='passwod_btn' onClick={() => { setShowAssets(!showAssets) }}>
                                    {showAssets ?
                                      <VisibilityOffTwoTone />
                                      :
                                      <VisibilityTwoTone />
                                    }
                                  </button>
                                </div>

                                <div className="text_botom">
                                  {showAssets ?
                                    <>
                                      <span className="blanace__ss">******  USDT </span>
                                    </>

                                    :
                                    <>
                                      <span className="blanace__ss">{(getSpotBalance?.main_account + getSpotBalance?.trading + getSpotBalance?.funding)?.toFixed(4)} USDT</span>
                                    </>
                                  }
                                </div>
                              </div>

                              <div className="btn_area">
                                <Link className='header-common-button' to="/dashboard/withdraw" onClick={() => dropdownToggleremove()}>withdraw</Link>
                                <Link className='header-common-button' to="/dashboard/transfer" onClick={() => dropdownToggleremove()} > Transfer</Link>
                              </div>

                              <div className="Nav_link__contauner">



                                <Link to="/dashboard" className={location.pathname === '/dashboard' ? "drop-inner-list activeLink" : 'drop-inner-list'} onClick={() => dropdownToggleremove()}>
                                  <div className="inner">
                                    <>Overview</>
                                  </div>
                                  <East className="arrow-rt" />
                                </Link>

                                <Link to="/dashboard/markets" className={location.pathname === '/dashboard/markets' ? "drop-inner-list activeLink" : 'drop-inner-list'} onClick={() => dropdownToggleremove()}>
                                  <div className="inner">
                                    <>Market</>
                                  </div>
                                  <East className="arrow-rt" />
                                </Link>

                                <Link to="/dashboard/kyc" className={location.pathname === '/dashboard/kyc' ? "drop-inner-list activeLink" : 'drop-inner-list'} onClick={() => dropdownToggleremove()}>
                                  <div className="inner">
                                    <>KYC</>
                                  </div>
                                  <East className="arrow-rt" />
                                </Link>

                                <Link to="/dashboard/profile" className={location.pathname === '/dashboard/profile' ? "drop-inner-list activeLink" : 'drop-inner-list'} onClick={() => dropdownToggleremove()}>
                                  <div className="inner">
                                    <>Profile</>
                                  </div>
                                  <East className="arrow-rt" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                      :
                      ""
                    }



                    <li className="nav-item left-divider-line hide-lg-items">
                      <span className="systemButton" onClick={() => toggleTheme()}>
                        {modeTheme === 'light' ? (<WbSunnyOutlined className="sys-icon" />) : modeTheme === 'dark' ? (<NightsStayOutlined className="sys-icon" />) : <NightsStayOutlined className="sys-icon" />}
                      </span>

                    </li>

                    <li className="nav-item left-divider-line ">
                      <span className="systemButton" onClick={() => { onOpenmenuBar() }}>
                        <Segment />
                      </span>
                    </li>
                  </div>




                </ul>

              </div>
            </div>
          </div>
        </header>
      </div>


      {/* This is from modal menubar */}
      <Modal
        open={menuBar}
        onClose={() => { onClosemenuBar(); }}
        classNames={{
          overlay: "menubar-overly",
          modal: "menuBar",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={800}
      >
        <div className="menuBar_inner">


          {auth === "true" ?
            ""  /* after login these sec is empty   */
            :
            <div className="menubtn">
              <Link className='header-common-button' to="/login" onClick={() => dropdownToggleremove()}> Login</Link>
              <Link className='header-common-button' to="/sign-up" onClick={() => dropdownToggleremove()}> Register</Link>
            </div>
          }



          <div className="menuLinks">

            <div className="mode_button" >
              <label className="inner" htmlFor="cb3-8">
                <p> Theme</p>
              </label>
              <div className="value-not-change">
                <div className="checkbox-wrapper-8" >


                  {modeTheme === 'light' ?

                    <input className="tgl tgl-skewed" id="cb3-8" type="checkbox" onClick={() => toggleTheme()} />

                    : modeTheme === 'dark' ?
                      <input className="tgl tgl-skewed" id="cb3-8" type="checkbox" onClick={() => toggleTheme()} checked />

                      :
                      <input className="tgl tgl-skewed" id="cb3-8" type="checkbox" onClick={() => toggleTheme()} checked />
                  }
                  <label className="tgl-btn" data-tg-off="Light" data-tg-on="Dark" htmlFor="cb3-8"></label>

                </div>
              </div>

            </div>

            {auth === "true" ?
              <>
                <Link to="/dashboard" className={location.pathname === '/dashboard' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                  <div className="inner">
                    <>Overview</>
                  </div>
                  <NorthEastTwoTone className="arrow-rt" />
                </Link>

                <Link to="/dashboard/markets" className={location.pathname === '/dashboard/markets' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                  <div className="inner">
                    <>Market</>
                  </div>
                  <NorthEastTwoTone className="arrow-rt" />
                </Link>

                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        P2P
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse p-0" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body ps-0 pe-0">
                        <Link to="/dashboard/p2p" className={location.pathname === '/dashboard/p2p' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                          <div className="inner">
                            <p>P2P</p>
                          </div>
                          <NorthEastTwoTone className="arrow-rt" />
                        </Link>

                        <Link to="/dashboard/p2p" state={{ active: true }} className={location.pathname === '/dashboard/p2p' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                          <div className="inner">
                            <p>AddPost</p>
                          </div>
                          <NorthEastTwoTone className="arrow-rt" />
                        </Link>

                        {/* <Link to="/dashboard/myadspost" className={location.pathname === '/dashboard/myadspost' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                          <div className="inner">
                            <p>My Ads Post</p>
                          </div>
                          <NorthEastTwoTone className="arrow-rt" />
                        </Link> */}
                      </div>
                    </div>
                  </div>


                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Accordion Item #2
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div> */}


                </div>
              </>

              :
              "" /*  before login these sec.. is empty... */

            }
            <div className="lg-hiddon-items">
              <Link to={public_site_url + "/buy-crypto"} className={location.pathname === '/markets' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                <div className="inner">
                  <>  Buy Crypto </>
                </div>
                <NorthEastTwoTone className="arrow-rt" />
              </Link>

              <Link to={public_site_url + "/referral-program"} className={location.pathname === '/markets' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                <div className="inner">
                  <>Referrals</>
                </div>
                <NorthEastTwoTone className="arrow-rt" />
              </Link>

              <Link to={public_site_url + "/bonus"} className={location.pathname === '/markets' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                <div className="inner">
                  <>Reward Center</>
                </div>
                <NorthEastTwoTone className="arrow-rt" />
              </Link>

              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#Explore" aria-expanded="true" aria-controls="collapseOne">
                      Explore
                    </button>
                  </h2>
                  <div id="Explore" className="accordion-collapse collapse p-0" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                    <div className="accordion-body ps-0 pe-0">
                      <Link to={public_site_url + "/launchpad"} className={location.pathname === '/dashboard/p2p' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                        <div className="inner">
                          <p>LeaderBoard</p>
                        </div>
                        <NorthEastTwoTone className="arrow-rt" />
                      </Link>

                      <Link to={public_site_url + "/launchbase"} className={location.pathname === '/dashboard/p2p' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                        <div className="inner">
                          <p>Launchbase</p>
                        </div>
                        <NorthEastTwoTone className="arrow-rt" />
                      </Link>

                      <Link to={public_site_url + "/blog"} className={location.pathname === '/dashboard/p2p' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                        <div className="inner">
                          <p> {title} Blog</p>
                        </div>
                        <NorthEastTwoTone className="arrow-rt" />
                      </Link>

                      <Link to={public_site_url + "/promotions"} className={location.pathname === '/dashboard/p2p' ? "menuBar_links activeLink" : 'menuBar_links'} onClick={() => dropdownToggleremove()}>
                        <div className="inner">
                          <p>Promotions</p>
                        </div>
                        <NorthEastTwoTone className="arrow-rt" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>







            {/* NOT CHANGES.. LINKS.. */}
            <div className="menuBar_links_notchange" onClick={() => dropdownToggleremove()} aria-disabled>
              <div className="inner">
                <PublicTwoTone />
                <p> Language</p>

              </div>
              <p className="value-not-change"> English</p>

            </div>

            <div className="menuBar_links_notchange" onClick={() => dropdownToggleremove()} aria-disabled>
              <div className="inner">
                <CurrencyExchangeTwoTone />
                <p> Currency</p>

              </div>
              <p className="value-not-change"> USD($)</p>

            </div>




            {/* LOGOUT BUTTON___ */}

            {auth === "true" ?
              <div className="logout__button">
                <Button type="button" className="btn" onClick={logout} > Logout <LogoutTwoTone /> </Button>
              </div>
              :
              "" /*  before login these sec.. is empty */
            }



          </div>

        </div>

      </Modal>
    </>
  );
};

export default Header;
