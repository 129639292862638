import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AdjustIcon from '@mui/icons-material/Adjust';
import ShieldIcon from '@mui/icons-material/Shield';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from 'react-router';
import _fetch from '../../config/api';
import { api_url, } from '../../config/config';
import toasted from '../../config/toast';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';



const TransactionCompleted = (props: any) => {
    const navigate = useNavigate()
    const [InrPrice, setInrPrice] = useState(87)

    const { id } = useParams();
    const Chat__Show = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("Chat_show");
    };
    const Chat__hide = (id: any) => {
        const ab: HTMLElement | any = document.getElementById(id);
        ab.classList.remove("Chat_show");
    };



    const showContent = (e: any) => {
        // console.log(e.currentTarget);
        var sibling = e.currentTarget.nextElementSibling;
        var siblingHeight = sibling.scrollHeight;

        let main = e.currentTarget.classList.toggle("show");
        // let main_2 = e.currentTarget.remove.toggle("show");

        if (main) {
            sibling.setAttribute("style", `height:${siblingHeight}px`);
            if (main != main) {
                sibling.removeAttribute("style");
            }


        } else {
            sibling.removeAttribute("style");
        }
    }
    // const [ActiveTab, setActiveTab] = useState(0);
    ////////////////////////////////////////////////all setup ////////////////////////
    const [allSellData, setallSellData] = useState({ 'price': 0, sell_currency: '', 'pending_amount': 0, 'payment_method': [], 'coin_id': '', 'seller_order_id': '', 'minimum_limit': '' })
    const [amount, setamount] = useState(0)
    const [amountReceive, setamountReceive] = useState(0)
    const [paymentMethod, setpaymentMethod] = useState('')
    const [getPaymentMethod, setgetPaymentMethod] = useState([{ 'type': '', '_id': '', 'account_holder_name': '', 'account_number': '', 'label': '', 'upi_id': '', 'account_ifsc': '', 'account_type': '' }])
    const [PassbtnDisable, setPassbtnDisable] = useState(false);

    const [image, setimage] = useState('');
    const [trxID, settrxID] = useState('');

    async function getSellData() {
        let d = await _fetch(`${api_url}trade/p2p-pending-sell-requests?search=_id&value=${id}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallSellData(d?.data.data?.[0]);
            setAutoReceiveAmountFun(d?.data.data?.[0]?.pending_amount, d?.data.data?.[0]?.price)
        }
    }

    const setAutoReceiveAmountFun = (val: any, amount: any) => {
        // if (val > allSellData?.minimum_limit && allSellData?.pending_amount >= val) {
        setamountReceive(val);
        var newData = (val * amount)?.toFixed(3);
        setamount(Number(newData));
        // }else{
        //     console.log('val',val);

        //     // setamountReceive(val)
        // }
    }

    // async function getInrData() {
    //     let d = await _fetch(`${markit_url}/market/getAllMarket?currency=USD&symbol=inr`, 'GET', {}, {});
    //     if (d?.status === 'success') {
    //         setallSellData(d?.data.data?.[0]);
    //     }
    // }

    async function getSellMethodData() {
        let d = await _fetch(`${api_url}trade/p2p-getPaymentInfo/${id}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setgetPaymentMethod(d?.data.data);
            // console.log('getPaymentMethod',getPaymentMethod);

        }
    }

    useEffect(() => {
        document.title = props.title;

        getSellMethodData()
        getSellData();
        // getInrData()
    }, [])

    const setAmountFun = (val: any) => {
        setamount(val);
        var newData = (val / allSellData?.price)?.toFixed(3);
        setamountReceive(Number(newData));
    }

    const setReceiveAmountFun = (val: any) => {
        // if (val > allSellData?.minimum_limit && allSellData?.pending_amount >= val) {
        setamountReceive(val);
        var newData = (val * allSellData?.price)?.toFixed(3);
        setamount(Number(newData));
        // }else{
        //     console.log('val',val);

        //     // setamountReceive(val)
        // }
    }

    const [timer, settimer] = useState("00:00");

    const openPaymetRequest = async () => {
        var formData = {
            coinID: allSellData?.coin_id,
            symbol: allSellData?.sell_currency,
            sellerOrderId: allSellData?.seller_order_id
        }
        var d = await _fetch(`${api_url}trade/p2p-request-make-payment`, 'POST', formData, {});
        if (d?.status === 'success') {
            toasted.success(d.message);

            if (timer === "00:00") {
                var seconds: any = '240';
                var intervalId = window.setInterval(function () {
                    seconds--;

                    // if (seconds > 1) {
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;

                    settimer(leftData)

                    if (seconds == 0) {
                        stop();

                    }

                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
        } else {
            toasted.error(d.message);
        }
    }
    const [countHit, setCountHit] = useState(0);
    const [checkTrxStatus, setcheckTrxStatus] = useState(false);

    useEffect(() => {
        if (countHit < 50) {
            const timer = setInterval(() => {
                (async () => {
                    var formData = {
                        coinID: allSellData?.coin_id,
                        symbol: allSellData?.sell_currency,
                        sellerOrderId: allSellData?.seller_order_id
                    }
                    var res = await _fetch(`${api_url}trade/p2p-check-payment-allow`, 'POST', formData);


                    if (res?.status === 'success') {
                        setcheckTrxStatus(res.data);
                        clearInterval(timer);
                    }

                })()
                var calHit = parseFloat(`${countHit + 1}`);
                setCountHit(calHit);
            }, 10000);
            return () => clearInterval(timer);
        }
    }, [countHit]);

    const SubmitForm = async (e: any) => {
        setPassbtnDisable(true)
        e.preventDefault();

        let formData = new FormData();
        formData.append('sellerOrderId', allSellData?.seller_order_id);
        formData.append('amount', `${amountReceive}`);
        formData.append('price', `${allSellData?.price}`);
        formData.append('coinID', allSellData?.coin_id);
        formData.append('currency', allSellData?.sell_currency);
        formData.append('paymentMethod', `${paymentMethod}`);
        formData.append('receipt', image);
        formData.append('tranaction_id', trxID);
        formData.append('symbol', allSellData.sell_currency);

        var data = {
            "sellerOrderId": allSellData?.seller_order_id,
            "amount": amountReceive,
            "price": allSellData?.price,
            "coinID": allSellData?.coin_id,
            "currency": allSellData?.sell_currency,
            "paymentMethod": paymentMethod,
            'receipt': image,
            'tranaction_id': trxID
        }

        var d = await _fetch(`${api_url}trade/p2p-buy-trade`, 'ImagePost', formData, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            //  setOpenQR(false);
            setPassbtnDisable(false)
            navigate('/dashboard/myadspost')

        } else {
            toasted.error(d.message);
            setPassbtnDisable(false)


        }
    }


    const handleFileRead = async (event: any) => {
        const file = event.target.files[0]
        // const base64:any = await convertBase64(file)
        console.log('base64', file);

        setimage(file)
    }
    const handleCopy = (data: any, msg: any) => {
        if (data) {
            navigator.clipboard.writeText(data);
            toasted.success(msg);
        }
    };

    return (
        <>
            <div className='my-ads-post___compelete'>
                <div className='my-ads-post' style={{ border: '1px solid transparent' }}>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='header___buy__section'>
                                <h4> BUY {allSellData?.sell_currency?.toUpperCase()} </h4>
                            </div>
                            <div className='card main-my-ads-card'>

                                <div className=''>
                                {timer === "00:00" ? "" :
                                    <div className="alert alert-danger d-flex justify-content-between align-items-center alert__custom">
                                        <span> <InfoOutlined /> Please <span className='new__space'>stay on page till you don't submit request.</span></span>
                                    </div>
                                }
                                </div>
                                {/* <div className='card__com'>
                                    <CheckCircleIcon />
                                    <div className='card__com_R'>
                                        <h3>transaction completed</h3>
                                        <p> received 10 {allSellData.sell_currency}</p>
                                        <button type='button' className='btn'> View My Assets</button>
                                        <button type='button' className='btn close__btn' onClick={() => Chat__Show('chat__section')}> <ChatIcon /> Chat</button>
                                    </div>
                                </div> */}
                                {/* <div className='checbox__area  check__box-1_lines'>

                                    <div className='check__box-1 check__box-22 veriify'>
                                        <CheckCircleOutlineIcon />
                                        <p> Complete Your payment</p>
                                    </div>
                                    <div className='check__box-1 check__box-22'>
                                        <CheckCircleOutlineIcon />
                                        <p>Coin release in progress</p>
                                    </div>
                                    <div className='check__box-1'>
                                        <CheckCircleOutlineIcon />
                                        <p> Transaction Completed</p>
                                    </div>
                                </div> */}
                                {/* ///// */}
                                <div className='order___info_T'>
                                    <div className='order___info_T__c'>
                                        <AdjustIcon />
                                        <div className='order___info_T__c___right'>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5>Order info</h5>
                                                {checkTrxStatus === false && <button className='button_add' type='button' onClick={() => { openPaymetRequest() }} disabled={timer === "00:00" ? false : true}>{timer === "00:00" ? "Payment request" : timer}</button>}
                                            </div>
                                            <p style={{ fontSize: '14px' }}>Available : {allSellData?.pending_amount} {allSellData?.sell_currency} | <span style={{ fontSize: '14px' }}>Limit : {allSellData?.minimum_limit} {allSellData?.sell_currency}</span></p>


                                            <div className='tran__details'>

                                                <div className='column__T pay__con'>
                                                    <p>Receive <span>( {allSellData?.sell_currency?.toUpperCase()} )</span></p>
                                                    <div className='input_edit'>
                                                        <input type="number" id='pay' placeholder='Receive' value={amountReceive} onChange={(e: any) => setReceiveAmountFun(e.target.value)} max={allSellData?.pending_amount} />
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                                <div className='column__T pay__con'>
                                                    <p>Price <span>( INR )</span></p>
                                                    <div className='input_edit'>
                                                        <input type="number" id='pay' value={allSellData?.price?.toFixed(3)} placeholder='Price ' readOnly />
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                                <div className='column__T pay__con'>
                                                    <p>Pay <span>( INR )</span></p>
                                                    <div className='input_edit'>
                                                        <input type="number" id='pay' placeholder='Pay' value={amount} onChange={(e: any) => { setAmountFun(e.target.value) }} />
                                                        <EditIcon />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>


                                    <div className='order___info_T__c'>
                                        <AdjustIcon />
                                        <div className='order___info_T__c___right'>
                                            <h5>Payment Method</h5>
                                            <div className='tran__details_A'>
                                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam quibusdam nihil excepturi id.</p>
                                            </div>

                                            {checkTrxStatus === true && <div className='check__box___new'>
                                                <form encType="multipart/form-data">
                                                    {getPaymentMethod?.map((val) => {
                                                        return (
                                                            <>
                                                                {val?.type !== 'bank' && <div className='form__group_UPI'>
                                                                    <input type="radio" className="form-check-input" value={val?._id} onClick={(e: any) => { setpaymentMethod(e.target.value) }} id='UpI_1' name='radio' />
                                                                    <div className='card__UPI_details' >
                                                                        <label htmlFor="UpI_1">
                                                                            <div className='card-header' onClick={(e) => { showContent(e) }} >
                                                                                <p>{val?.type?.toUpperCase()}</p>
                                                                                <p><KeyboardArrowDownIcon /></p>
                                                                            </div>
                                                                            <div className='acount__de__body'>
                                                                                <div className='acount__de__body_data'>
                                                                                    <div className='acount__de__T'>
                                                                                        <p> Name </p>
                                                                                        <h5> {val?.label}  </h5>
                                                                                    </div>
                                                                                    <div className='acount__de__T'>
                                                                                        <p>{val?.type?.toUpperCase()} ID   </p>
                                                                                        <h5> {val?.upi_id} <span onClick={() => { handleCopy(val?.upi_id, 'ID copied successfully!') }}> <ContentCopyIcon />  </span> </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>}
                                                                {val?.type === 'bank'




                                                                    && <div className='form__group_UPI'>



                                                                        <input type="radio" className="form-check-input" value={val?._id} onClick={(e: any) => { setpaymentMethod(e.target.value) }} id='UpI_1__ad' name='radio' />
                                                                        <div className='card__UPI_details' >
                                                                            <label htmlFor="UpI_1__ad">
                                                                                <div className='card-header' onClick={(e) => { showContent(e) }} >
                                                                                    <p>{val?.type?.toUpperCase()}</p>
                                                                                    <p><KeyboardArrowDownIcon /></p>
                                                                                </div>
                                                                                <div className='acount__de__body'>
                                                                                    <div className='acount__de__body_data'>
                                                                                        <div className='acount__de__T'>
                                                                                            <p>Bank Holder Name </p>
                                                                                            <h5> {val?.account_holder_name}  <span onClick={() => { handleCopy(val?.account_holder_name, 'Name copied successfully!') }}> <ContentCopyIcon />  </span> </h5>
                                                                                        </div>
                                                                                        <div className='acount__de__T'>
                                                                                            <p> Account Number   </p>
                                                                                            <h5> {val?.account_number}  <span onClick={() => { handleCopy(val?.account_number, 'Account number copied successfully!') }}> <ContentCopyIcon />  </span> </h5>
                                                                                        </div>
                                                                                        <div className='acount__de__T'>
                                                                                            <p> Account Type   </p>
                                                                                            <h5> {val?.account_type}   </h5>
                                                                                        </div>
                                                                                        <div className='acount__de__T'>
                                                                                            <p> IFSC CODE  </p>
                                                                                            <h5> {val?.account_ifsc}  <span onClick={() => { handleCopy(val?.account_ifsc, 'IFSC code copied successfully!') }}> <ContentCopyIcon />  </span> </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>}
                                                            </>
                                                        );
                                                    })
                                                    }

                                                    {/* <div className='form__group_UPI'>
                                                        <input type="radio" className="form-check-input" value="" id='UpI_2' name='radio' />
                                                        <div className='card__UPI_details' >
                                                            <label htmlFor="UpI_2">
                                                                <div className='card-header' onClick={(e) => { showContent(e) }} >
                                                                    <p>UPI</p>
                                                                    <p><KeyboardArrowDownIcon /></p>
                                                                </div>
                                                                <div className='acount__de__body'>
                                                                    <div className='acount__de__body_data'>
                                                                        <div className='acount__de__T'>
                                                                            <p> Name </p>
                                                                            <h5> Sunil Sharma  <span> <ContentCopyIcon />  </span> </h5>
                                                                        </div>
                                                                        <div className='acount__de__T'>
                                                                            <p> Bank Account Number   </p>
                                                                            <h5> 8928323@oksbi  <span> <ContentCopyIcon />  </span> </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className='form__group_UPI'>
                                                        <input type="radio" className="form-check-input" value="" id='UpI_3' name='radio' />

                                                        <div className='card__UPI_details' >
                                                            <label htmlFor="UpI_3">
                                                                <div className='card-header' onClick={(e) => { showContent(e) }} >
                                                                    <p>UPI</p>
                                                                    <p><KeyboardArrowDownIcon /></p>
                                                                </div>
                                                                <div className='acount__de__body'>
                                                                    <div className='acount__de__body_data'>
                                                                        <div className='acount__de__T'>
                                                                            <p> Name </p>
                                                                            <h5> Sunil Sharma  <span> <ContentCopyIcon />  </span> </h5>
                                                                        </div>
                                                                        <div className='acount__de__T'>
                                                                            <p> Bank Account Number   </p>
                                                                            <h5> 8928323@oksbi  <span> <ContentCopyIcon />  </span> </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div> */}

                                                </form>


                                            </div>}



                                        </div>

                                    </div>

                                    {checkTrxStatus === true && <div className='order___info_T__c proof__img'>
                                        <AdjustIcon />
                                        <div className='order___info_T__c___right'>
                                            <h5>Payment Proof</h5>
                                            <div className='payment___proofs'>
                                                <form>
                                                    <div className='payment_proof_a'>
                                                        <label htmlFor='trx_id'> Transaction ID</label>
                                                        <input type='text' className='form-control' onChange={(e: any) => { settrxID(e.target.value) }} placeholder='Enter Transaction ID' />
                                                    </div>

                                                    <div className='payment_proof_a'>
                                                        <label htmlFor='trx_id'> Upload proof</label>
                                                        <input type='file' className='form-control' onChange={(e: any) => { handleFileRead(e) }} placeholder='Enter Transaction ID' />
                                                    </div>
                                                </form>
                                            </div>


                                        </div>

                                    </div>}

                                    {checkTrxStatus === true && <div className='btn_trantions'>
                                        <div className='btn'>cancel</div>
                                        <button className='btn' type='submit' onClick={(e: any) => SubmitForm(e)} disabled={PassbtnDisable}>

                                            {PassbtnDisable === true &&
                                                <div className="color-ring">
                                                    <ClipLoader color="#36d7b7" />

                                                    Please Wait...
                                                </div>
                                            }
                                            {PassbtnDisable === false ? 'Submit' : ''}
                                        </button>
                                    </div>}
                                </div>

                            </div>
                        </div>
                        <div className='col-4 d-none' id='chat__section'>
                            <div className='card_chat'>
                                <div className='card_chat_header'>
                                    <h3>S</h3>
                                    <div className='card_chat_header__right'>
                                        <h4> Sunil</h4>
                                        <p><ShieldIcon /> verified Sunil sharma - </p>
                                    </div>
                                    <div className='close__chat' onClick={() => Chat__hide('chat__section')}><CloseIcon /></div>
                                </div>
                                <div className='card_chat_body'>
                                    <div className='Clint__M'>
                                        <h3>S</h3>
                                        <div className='Clint__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
                                        </div>
                                    </div>


                                    <div className='Clint__M'>
                                        <h3>S</h3>
                                        <div className='Clint__SMS'>
                                            <img src={'demo_img'} alt='chat__img' />
                                        </div>
                                    </div>

                                    <div className='time__sec_chat'> <span>2023-01-26 12:25:16</span></div>

                                    <div className='User__M'>
                                        <div className='User__SMS'>
                                            <img src={'demo_img'} alt='chat__img' />
                                        </div>
                                        <h3>S</h3>
                                    </div>

                                    <div className='User__M'>
                                        <div className='User__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae  dkfdfndsf
                                            ds dfs f f sdf sdfsfsf sdfsdfsdfdf dsf sdsdf sdf fwenm nsdfnsd fsd fnsd,fndsfnklssnn sn
                                        </div>
                                        <h3>S</h3>
                                    </div>

                                    <div className='Clint__M'>
                                        <h3>S</h3>
                                        <div className='Clint__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
                                        </div>
                                    </div>
                                    <div className='Clint__M'>
                                        <h3>S</h3>
                                        <div className='Clint__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
                                        </div>
                                    </div>
                                    <div className='Clint__M'>
                                        <h3>S</h3>
                                        <div className='Clint__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
                                        </div>
                                    </div>

                                    <div className='time__sec_chat'> <span>2023-01-26 12:25:16</span></div>

                                    <div className='User__M'>
                                        <div className='User__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae  dkfdfndsf
                                            ds dfs f f sdf sdfsfsf sdfsdfsdfdf dsf sdsdf sdf fwenm nsdfnsd fsd fnsd,fndsfnklssnn sn
                                        </div>
                                        <h3>S</h3>
                                    </div>
                                    <div className='Clint__M'>
                                        <h3>S</h3>
                                        <div className='Clint__SMS'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
                                        </div>
                                    </div>
                                </div>
                                <div className='card_chat_footer'>
                                    <input type="text" placeholder='Enter Your Message' />
                                    <div className='media_uplpad'>
                                        <label htmlFor='upload'><PermMediaIcon /></label>
                                        <input type="file" id='upload' />
                                    </div>
                                    <button type='submit' className='btn'><TelegramIcon /></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransactionCompleted;
