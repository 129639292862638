import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
import _fetch from '../../config/api';
import LoaderCom from '../../config/LoaderCom';
import { ClipLoader } from 'react-spinners'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function PaymentDetail() {
    // const [open, setOpen] = useState(false);
    const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '' });

    const [bankHolderName, setBankHolderName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [IFSCCode, setIFSCCode] = useState('');
    const [otp, setotp] = useState();
    const [bankAccountType, setBankAccountType] = useState('Saving');
    const [UPIAccountType, setUPIAccountType] = useState('upi');
    const [UPILevel, setUPILevel] = useState('');
    const [UPIAccountDetail, setUPIAccountDetail] = useState('');
    const [userKycInfo, setUserKycInfo] = useState({ first_name: '', last_name: '' });
    const [callFuntion, setcallFuntion] = useState(0);
    const [emptyData, setemptyData] = useState([]);
    const [btnDisable, setbtnDisable] = useState(false);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    async function getphone2faOtp() {
        var d = await _fetch(`${api_url}auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET', {}, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
        }
    }


    const OnSubmitBankDetail = async (e: any) => {
        setbtnDisable(true)
        e.preventDefault();


        const data = {
            "account_holder_name": bankHolderName,
            "account_number": accountNumber,
            "ifsc_code": IFSCCode,
            "type": "bank",
            "account_type": bankAccountType,
            "otp": otp
        }

        var d = await _fetch(`${api_url}payments/addBank/`, 'POST', data);
        if (d?.status === 'success') {
            toasted.success(d?.message);
            getAccountDetails();
            setOpen(false)
            setbtnDisable(false)
        }
        else {
            toasted.error(d?.message);
            setbtnDisable(false)

        }
    }

    // Function of UPI detail submission

    const OnUPISubmit = async (e: any) => {
        setbtnDisable(true)

        e.preventDefault();

        const data = {
            "type": UPIAccountType,
            "upi_id": UPIAccountDetail,
            "label": UPILevel,
            "otp": otp
        }
        var d = await _fetch(`${api_url}payments/addUPI/`, 'POST', data);
        if (d?.status === 'success') {
            toasted.success(d?.message);
            getAccountDetails();
            setOpen(false);
            setbtnDisable(false)
        }
        else {
            toasted.error(d?.message);
            setbtnDisable(false)
        }
    }

    const [allAccountdetail, setallAccountdetail] = useState([])
    const getAccountDetails = async () => {
        setemptyData([])
        let d = await _fetch(`${api_url}payments/paymentDetails`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallAccountdetail(d.data.data)
            setemptyData(d)

        }
    }


    const deleteDetail = async (value: any) => {
        var data = {
            "account_id": value,
        }
        var d = await _fetch(`${api_url}payments/removePayments/`, 'DELETE', data, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            getAccountDetails();
            setcallFuntion(callFuntion === 0 ? 1 : 0);

        } else {
            toasted.error(d.message);

        }
    }



    useEffect(() => {
        (async () => {
            const userData = await _fetch(`${api_url}user/userInfo/`, 'GET');
            setUserInfo(userData?.data?.data);
            const kycData = await _fetch(`${api_url}kyc/userKycInfo/`, "GET", "", {});
            setUserKycInfo(kycData?.data?.data);
        })();
        getAccountDetails()

    }, [callFuntion]);

    return (

        <>
            {/* profile.scss line number 439 start.. */}

            <Tabs>
                <div className="profile__settings">
                    <div className="profile_head d-flex justify-content-between align-items-center">
                        <h4>Payment Option</h4>


                    </div>
                    <div className="profile__body profile__payment_tabs">
                        <div className='profile__tab_header'>
                            <div className='tab__left_1'>
                                <TabList>
                                    <Tab>Bank Account</Tab>
                                    <Tab>UPI / OTHER</Tab>
                                </TabList>
                            </div>
                            <button onClick={() => { onOpenModal() }} className="btn move__next_button w-auto">ADD A NEW PAYMENT OPTION </button>
                        </div>

                        <div className='profile__tabs_body'>

                            <TabPanel>
                                <div className="accordion" id="accordionExample">
                                    <LoaderCom condition={emptyData} text={'Getting latest values...'} />


                                    {allAccountdetail?.map((val: any) => {
                                        // let id = '1';
                                        if (val.type === 'bank') {
                                            let target_id = '#sk' + val._id;
                                            return (
                                                <div className="accordion-item">
                                                    <span className="accordion-header" id="headingThree">
                                                        <button className="accordion-button collapsed aco-btn" type="button" data-bs-toggle="collapse" data-bs-target={target_id} aria-expanded="false" aria-controls="collapseThree">
                                                            BANK ACCOUNT -
                                                            {val.account_number}
                                                        </button>
                                                        <p className='status-btn'>Active</p>

                                                    </span>
                                                    <div id={'sk' + val._id} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className='holder-detail'>
                                                                <h4>Your bank account details for IMPS payments</h4>
                                                                <li><label>ACCOUNT Holder Name</label> <span className="ac-number"> {val.account_holder_name}</span></li>
                                                                <li><label>ACCOUNT NUMBER</label> <span className="ac-number"> {val.account_number} </span></li>
                                                                <li><label>IFSC CODE</label> <span className="ac-number">{val.account_ifsc}</span></li>
                                                                <li><label>ACCOUNT TYPE</label> <span className="ac-number">{val.type}</span></li>

                                                                <div className='button' onClick={() => deleteDetail(val._id)}>
                                                                    <button className="account-remove">
                                                                        <span className='left__top__border'></span>
                                                                        <span className='right__top__border'></span>
                                                                        <span className='left__bottom__border'></span>
                                                                        <span className='right__bottom__border'></span>
                                                                        Remove</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </TabPanel>


                            <TabPanel>

                                <div className="accordion" id="accordionExample">
                                    
                                    <LoaderCom condition={emptyData} text={'Getting latest values...'} />

                                    {allAccountdetail?.map((val: any) => {
                                        if (val.type !== 'bank') {
                                            let target_id = '#thedc' + val._id;
                                            return (
                                                <div className="accordion-item">
                                                    <span className="accordion-header" id="headingThree">
                                                        <button className="accordion-button collapsed aco-btn" type="button" data-bs-toggle="collapse" data-bs-target={target_id} aria-expanded="false" aria-controls="collapseThree">
                                                            Name - {val.label}

                                                        </button>
                                                        <p className='status-btn'>Active</p>

                                                    </span>
                                                    <div id={'thedc' + val._id} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className='holder-detail'>
                                                                <h4>Your UPI account details for IMPS payments</h4>
                                                                <li><label>UPI ID</label> <span className="ac-number">{val.upi_id}</span></li>
                                                                <div className='button' onClick={() => deleteDetail(val._id)}>
                                                                    <button className="account-remove">
                                                                        <span className='left__top__border'></span>
                                                                        <span className='right__top__border'></span>
                                                                        <span className='left__bottom__border'></span>
                                                                        <span className='right__bottom__border'></span>
                                                                        Remove</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>

                            </TabPanel>


                        </div>


                    </div>
                </div>
            </Tabs>


            <Modal open={open} onClose={onCloseModal}
                classNames={{
                    modal: "Payment__options",
                }}
                center>
                <div className='card__payment__add'>
                    <h4>add a new payment options </h4>

                    <div className='card__tabs_mobails_1'>
                        <Tabs>
                            <TabList>
                                <Tab>Bank Account</Tab>
                                <Tab>UPI / OTHER</Tab>
                            </TabList>

                            <TabPanel>
                                <div className='details___fill__a12'>
                                    <div className="fill-detail">
                                        <h3>Bank Account Verify</h3>

                                        <form className='account-form'>
                                            <div className='form-group'>
                                                <label className='mb-2'>Holder Name</label>
                                                <input type='text' className='form-control' placeholder='Holder Name' name='name' onChange={(e: any) => { setBankHolderName(e.target.value) }} value={bankHolderName} />
                                            </div>
                                            <div className='form-group mt-3'>
                                                <label className='mb-2'>Account Number</label>
                                                <input type='number' className='form-control' placeholder='0000-0000-0000' name="ac_num" onChange={(e: any) => { setAccountNumber(e.target.value) }} value={accountNumber} />
                                            </div>
                                            <div className='form-group mt-3'>
                                                <label className='mb-2'>IFSC Code</label>
                                                <input type='text' className='form-control' placeholder='IFSC Code' name="ifsc_code" onChange={(e: any) => { setIFSCCode(e.target.value) }} value={IFSCCode} />
                                            </div>

                                            {userInfo?.security_type === 'other' &&

                                                <div className='form-group mt-3 '>
                                                    <label className='mb-2'>Verify OTP</label>
                                                    <div className='verify-col'>
                                                        <input type='number' className='form-control' placeholder='Verify OTP' name="v_code" onChange={(e: any) => { setotp(e.target.value) }} />
                                                        <button className='btn otp-btn' type='button' onClick={getphone2faOtp}> Send OTP</button>
                                                    </div>
                                                </div>
                                            }
                                            {userInfo?.security_type === '2fa' &&

                                                <div className='form-group mt-3 '>
                                                    <label className='mb-2'>Verify OTP</label>
                                                    <div className='verify-col'>
                                                        <input type='number' className='form-control' placeholder='Verify OTP' name="v_code" onChange={(e: any) => { setotp(e.target.value) }} />
                                                    </div>
                                                </div>
                                            }
                                            <div className='form-group mt-3'>
                                                <label className='mb-2'>Account Type: </label>
                                                <div className='radio-col'>
                                                    <span className='d-flex'><input type='radio' value="Saving" className='me-2' name="ac-type" id="saving" onChange={(e: any) => { setBankAccountType(e.target.value) }} checked /><label >Savings</label>  </span>
                                                    <span className='d-flex ms-5'> <input type='radio' value="Current" className='me-2' name="ac-type" id="current" onChange={(e: any) => { setBankAccountType(e.target.value) }} /><label >Current</label> </span>
                                                </div>
                                            </div>
                                            <div className='form-group d-flex justify-content-center'>
                                                <button type='button' onClick={OnSubmitBankDetail} className='btn submit-btn' disabled={btnDisable}>
                                                    <span className='left__top__border'></span>
                                                    <span className='right__top__border'></span>
                                                    <span className='left__bottom__border'></span>
                                                    <span className='right__bottom__border'></span>
                                                    {btnDisable === true &&
                                                        <div className="color-ring">
                                                            <ClipLoader color="#36d7b7" />

                                                            Please Wait...
                                                        </div>
                                                    }
                                                    {btnDisable === false ? 'Submit ' : ''}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel>
                                <div className='details___fill__a12'>
                                    <div className="fill-detail">
                                        <h3>UPI / OTHER </h3>

                                        <form className='account-form'>
                                            <div className='form-group'>
                                                <label className='mb-2'>Select Account Type</label>
                                                <select className='form-control' name="ac_type" onChange={(e) => { setUPIAccountType(e.target.value) }}>
                                                    <option value='upi'>UPI</option>

                                                </select>
                                            </div>
                                            <div className='form_flex_group'>
                                                <div className='form-group mt-3'>
                                                    <label className='mb-2'>Name</label>
                                                    <input type='text' className='form-control' placeholder='Name' name='name' onChange={(e: any) => { setUPILevel(e.target.value) }} value={UPILevel} />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <label className='mb-2'>UPI/OTHER</label>
                                                    <input type='text' className='form-control' placeholder='UPI/OTHER' name='' onChange={(e: any) => { setUPIAccountDetail(e.target.value) }} value={UPIAccountDetail} />
                                                </div>

                                            </div>

                                            {userInfo?.security_type === 'other' &&

                                                <div className='form-group mt-3 '>
                                                    <label className='mb-2'>Verify OTP</label>
                                                    <div className='verify-col'>
                                                        <input type='number' className='form-control' placeholder='Verify OTP' name='v_code' onChange={(e: any) => { setotp(e.targte.value) }} value={otp} />
                                                        <button className='btn otp-btn' onClick={getphone2faOtp}> Send OTP</button>
                                                    </div>
                                                </div>
                                            }
                                            {userInfo?.security_type === '2fa' &&

                                                <div className='form-group mt-3 '>
                                                    <label className='mb-2'>Verify OTP</label>
                                                    <div className='verify-col'>
                                                        <input type='number' className='form-control' placeholder='Verify OTP' name='v_code' onChange={(e: any) => { setotp(e.targte.value) }} value={otp} />
                                                    </div>
                                                </div>
                                            }
                                            <div className='form-group d-flex justify-content-center mt-2'>
                                                <button type='button' onClick={OnUPISubmit} className='btn submit-btn' disabled={btnDisable}>
                                                    <span className='left__top__border'></span>
                                                    <span className='right__top__border'></span>
                                                    <span className='left__bottom__border'></span>
                                                    <span className='right__bottom__border'></span>
                                                    {btnDisable === true &&
                                                        <div className="color-ring">
                                                            <ClipLoader color="#36d7b7" />

                                                            Please Wait...
                                                        </div>
                                                    }
                                                    {btnDisable === false ? 'Submit ' : ''}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>

                </div>
            </Modal>

        </>
    )
}

export default PaymentDetail
