import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
// import { HistoricalChart } from '../../../config/api';

export const BarStyles = {
  BARS: '0',
  CANDLES: '1',
  HOLLOW_CANDLES: '9',
  HEIKIN_ASHI: '8',
  LINE: '2',
  AREA: '3',
  RENKO: '4',
  LINE_BREAK: '7',
  KAGI: '5',
  POINT_AND_FIGURE: '6'
};

export const IntervalTypes = {
  D: 'D',
  W: 'W'
};

export const RangeTypes = {
  YTD: 'ytd',
  ALL: 'all'
};

export const Themes = {
  LIGHT: 'light',
  DARK: 'dark'
};

const SCRIPT_ID = 'tradingview-widget-script';
const CONTAINER_ID = 'tradingview-widget';


let modeTheme = localStorage.getItem("mode_chart");
// console.log(modeTheme)
// },[]);
// useEffect(()=>{
// },[]);
export default class TradingViewWidget extends React.Component {

  // constructor(props) {
  //   super(props);
  //   console.log(this)
  //   // this.handleMouseMove = this.handleMouseMove.bind(this);
  //   // this.state = { x: 0, y: 0 };
  // }


  static propTypes = {
    allow_symbol_change: PropTypes.bool,
    autosize: PropTypes.bool,
    calendar: PropTypes.bool,
    details: PropTypes.bool,
    enable_publishing: PropTypes.bool,
    height: PropTypes.number,
    hideideas: PropTypes.bool,
    hide_legend: PropTypes.bool,
    hide_side_toolbar: PropTypes.bool,
    hide_top_toolbar: PropTypes.bool,
    hotlist: PropTypes.bool,
    interval: PropTypes.oneOf([
      1,
      3,
      5,
      15,
      30,
      60,
      120,
      180,
      '1',
      '3',
      '5',
      '15',
      '30',
      '60',
      '120',
      '180',
      IntervalTypes.D,
      IntervalTypes.W
    ]),

    locale: PropTypes.string,
    news: PropTypes.arrayOf(PropTypes.string),
    no_referral_id: PropTypes.bool,
    popup_height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    popup_width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    range: PropTypes.oneOf([
      '1d',
      '5d',
      '1m',
      '3m',
      '6m',
      RangeTypes.YTD,
      '12m',
      '60m',
      RangeTypes.ALL
    ]),
    referral_id: PropTypes.string,
    save_image: PropTypes.bool,
    show_popup_button: PropTypes.bool,
    studies: PropTypes.arrayOf(PropTypes.string),
    style: PropTypes.oneOf([
      BarStyles.BARS,
      BarStyles.CANDLES,
      BarStyles.HOLLOW_CANDLES,
      BarStyles.HEIKIN_ASHI,
      BarStyles.LINE,
      BarStyles.AREA,
      BarStyles.RENKO,
      BarStyles.LINE_BREAK,
      BarStyles.KAGI,
      BarStyles.POINT_AND_FIGURE
    ]),
    symbol: PropTypes.string.isRequired,
    // query: "USDT",
    theme: PropTypes.oneOf([Themes.LIGHT, Themes.DARK]),
    timezone: PropTypes.string,
    toolbar_bg: PropTypes.string,
    watchlist: PropTypes.arrayOf(PropTypes.string),
    widgetType: PropTypes.string,
    width: PropTypes.number,
    withdateranges: PropTypes.bool
  };

  static defaultProps = {
    allow_symbol_change: true,
    autosize: false,
    enable_publishing: false,
    height: 400,
    hideideas: true,
    hide_legend: false,
    hide_side_toolbar: false,
    hide_top_toolbar: false,
    interval: IntervalTypes.D,
    locale: 'en',
    save_image: true,
    show_popup_button: false,
    style: BarStyles.CANDLES,
    theme: modeTheme,
    timezone: 'Etc/UTC',
    toolbar_bg: '#F1F3F6',
    widgetType: 'widget',
    width: 'auto',
    withdateranges: true
  };

  containerId = `${CONTAINER_ID}-${(Math.random())}`;
  componentDidMount = () => this.appendScript(this.initWidget);

  componentDidUpdate = (e) => {
    this.themeUpdate();
    // useEffect(() => {
    // let modeTheme = localStorage.getItem("mode_chart");
    // console.log(modeTheme)
    // }, []);
    // this.cleanWidget();
    // this.initWidget();
  };

  canUseDOM = () => !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
    // window.location.reload( )
  );

  appendScript = (onload) => {
    if (!this.canUseDOM()) {
      onload();
      return;
    }

    if (this.scriptExists()) {
      /* global TradingView */
      if (typeof TradingView === 'undefined') {
        this.updateOnloadListener(onload);
        return;
      }
      onload();
      return;
    }




    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/tv.js';
    script.onload = onload;
    document.getElementsByTagName('head')[0].appendChild(script);


  };

  getScriptElement = () =>
    document.getElementById(SCRIPT_ID);

  scriptExists = () =>
    this.getScriptElement() !== null;

  updateOnloadListener = (onload) => {
    const script = this.getScriptElement();
    const oldOnload = script.onload;
    return script.onload = () => {
      oldOnload();
      onload();
    };
  };

  initWidget = () => {
    /* global TradingView */
    if (typeof TradingView === 'undefined' || !document.getElementById(this.containerId)) return;

    const { widgetType, ...widgetConfig } = this.props;
    if (modeTheme === null) {
      modeTheme = 'dark';
    }

    // if(symbol: this.props.symbol)

    const config = { ...widgetConfig, container_id: this.containerId, symbol: this.props.symbol + "USDT", theme: modeTheme };
    // console.log(config);
    if (config.autosize) {
      delete config.width;
      delete config.symbol;
      delete config.height;
    }
    // console.log(config);
    if (typeof config.interval === 'number') {
      config.interval = config.interval.toString();
    }

    if (config.popup_width && typeof config.popup_width === 'number') {
      config.popup_width = config.popup_width.toString();
    }

    if (config.popup_height && typeof config.popup_height === 'number') {
      config.popup_height = config.popup_height.toString();
    }

    /* global TradingView */
    new TradingView[widgetType](config);
  };

  cleanWidget = () => {
    if (!this.canUseDOM()) return;
    document.getElementById(this.containerId).innerHTML = '';
  };


  themeUpdate = () => {
    let modeTheme = localStorage.getItem("mode_chart");
    // console.log(modeTheme)
    // if (!this.canUseDOM()) return;
    // document.getElementById(this.containerId).innerHTML = '';
  };

  getStyle = () => {
    if (!this.props.autosize) return {};
    return {
      width: 'auto',
      height: '100%'
    };
  };
  render = () => <article id={this.containerId} style={this.getStyle()} />


}

// const a = fatchCoin();
//   a.then(data => console.log(data));
// (async () => { var data = await fatchCoin()
//   console.log('await',data.['0']['1'])
//   return data.['0']['1']
// })()

// export const  fatchCoin = async () =>{
// 	const { data } = await axios.get(HistoricalChart("bitcoin",'365'));
// 	const dataget = data.prices;

// 	return(data.prices)
//   }