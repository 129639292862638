import React, { useEffect, useState } from 'react'
import _fetch from '../../../config/api';
import { api_url } from '../../../config/config';
import toasted from '../../../config/toast';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Button } from 'react-bootstrap';



const BuyLimit = (props: any) => {

    const { id } = useParams()
    const [buyStopPriceAt, setbuyStopPriceAt] = useState(0);
    const [amount, setamount] = useState(0);
    const [usdAmount, setusdAmount] = useState(0);
    const [currentId, setCurrentId] = useState('')
    const [btnDisable, setbtnDisable] = useState(false);
    const [SellbtnDisable, setSellbtnDisable] = useState(false);

    async function BuyCoin(e: any) {

        setbtnDisable(true)
        e.preventDefault();
        const limit = {
            buy_coin_id: currentId,
            amount: amount,
            buyCurrency: id,
            limit_price: buyStopPriceAt,
            trade_type: (buyStopPriceAt > 0) ? 'limit' : 'market',
            // chain: 'bep20'
            symbol: id?.toLowerCase()

        }

        let d = await _fetch(`${api_url}trade/buy-trade`, 'POST', limit, {});
        if (d?.status === 'success') {
            toasted.success(d?.message);
            CoinInfo();
            props.setLoadApi(props.LoadApi === 0 ? 1 : 0)
            setbtnDisable(false);
        } else {
            toasted.error(d?.message);
            setbtnDisable(false);
        }
    }
    // ======= SELL Functions ======== //

    const [USDTBallanceShow, setUSDTBallanceShow] = useState(0)

    async function CoinInfo() {
        var d = await _fetch(`${api_url}markets/getSingleTradeMarket?type=trade_account&symbol=${id?.toLowerCase()}`, 'GET', {}, {});
        if (d?.status === 'success') {
            // setcoinCurrentPrice(d?.market[0].current_price);
            let f = Number(d?.market[0].total_coin).toFixed(3)
            // setCoinBallanceShow(parseFloat(f));
            setCurrentId(d?.market[0].coin_id);
        }
    }

    async function SaleCoinInfo() {
        var d = await _fetch(`${api_url}markets/getElpSingleMarketByType?type=trade_account&symbol=usdt`, 'GET', {}, {});
        if (d?.status === 'success') {
            let f = Number(d?.market[0].usdt_income).toFixed(3)
            setUSDTBallanceShow(parseFloat(f));
            // setSaleSymbol(d?.market[0].symbol);
        }
    }




    useEffect(() => {
        CoinInfo()
        SaleCoinInfo()
    }, [id])






    const sellLimitPercentage = (buyPercentage: any) => {
        const float = Number(parseFloat(buyPercentage).toFixed(3));
        const totalUSDPercentage = ((Number(USDTBallanceShow) / 100) * float).toFixed(3);
        const main2 = Number(parseFloat(totalUSDPercentage) / buyStopPriceAt).toFixed(3);
        setamount(parseFloat(main2));
        setusdAmount(parseFloat(totalUSDPercentage));
    }



    ///////set value  order book copy //////
    useEffect(() => {
        if (props?.copyData) {
            var getDataPrrop = Number((props?.copyData?.amount_total - props?.copyData?.total_receive)?.toFixed(3))
            setamount(getDataPrrop);
            setbuyStopPriceAt(props?.copyData?.coin_price);
            setusdAmount(Number(getDataPrrop * props?.copyData?.coin_price));
        }
    }, [props.copyData])

    const changePrice = (e: any, state: any) => {
        var second = (e); //
        const match: any = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                } else if (match[1]?.length === 2) {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                } else if (match[1]?.length === 1) {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if (second <= '0' || second <= '0.') {
                    state(second);
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                } else {
                    state(second.replace(/^0+/, '')); //
                    const val = Number((second * amount).toFixed(3));
                    setusdAmount(val);
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }


    const changeAmount = (e: any, state: any) => {
        var second = (e); //
        const match: any = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                } else if (match[1]?.length === 2) {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                } else if (match[1]?.length === 1) {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if (second <= '0' || second <= '0.') {
                    state(second);
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                } else {
                    state(second.replace(/^0+/, '')); //
                    const main2 = Number(second * buyStopPriceAt).toFixed(3);
                    setusdAmount(parseFloat(main2));
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }
    const totalAmount = (e: any, state: any) => {
        var second = (e); //
        const match: any = ('' + second).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        var asd = second.match(/^-?\d*\.?\d*$/);
        if (asd) {
            if (match[1]?.length > 0) {
                if (match[1]?.length === 3) {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                } else if (match[1]?.length === 2) {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                } else if (match[1]?.length === 1) {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                }
            } else {
                // if (second.match(/[0-9]./)) {
                if (second <= '0' || second <= '0.') {
                    state(second);
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                } else {
                    state(second.replace(/^0+/, '')); //
                    const main2 = Number(second / buyStopPriceAt).toFixed(3);
                    setamount(parseFloat(main2));
                }
                // }
            }
        } else {
            console.log('Wrong Method');
            // return false;
        }
    }

    return (
        <>
            <div className="data_right " style={{ display: props.buymarketTab }}>
                {/* <div className="heading only-for-lg"><p>Stop Limit</p></div> */}

                <div className='balance'>
                    <p className='profit__aaf'>Available <span> {USDTBallanceShow}</span> USDT </p>
                    <div className='input_price input-green'>
                        <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                        <input type='text' onChange={(e: any) => { changePrice(e.target.value, setbuyStopPriceAt) }} value={buyStopPriceAt} />
                        <span>STOP PRICE</span>
                        <span>USDT</span>
                    </div>
                    {/* <div className='input_price input-green'>
                            <span style={{ color: "rgb(132, 142, 156)" }}>Price</span>
                            <input type='text' onChange={(e: any) => { onCurrentPrice(e) }} value={coinCurrentPrice} />
                            <span>USDT</span>
                        </div> */}

                    <div className='input_price input-green'>
                        <span style={{ color: "rgb(132, 142, 156)" }}>Amount</span>
                        <input type='text' onChange={(e: any) => { changeAmount(e.target.value, setamount) }} value={amount} />
                        <span>{id?.toLocaleUpperCase()}</span>
                    </div>

                    <div className='range'>
                        <div className='percentage'>
                            {/* <div className='items'>0%</div> */}
                            <div className='items profit-green' onClick={() => sellLimitPercentage(25)}>25%</div>
                            <div className='items profit-green' onClick={() => sellLimitPercentage(50)}>50%</div>
                            <div className='items profit-green' onClick={() => sellLimitPercentage(75)}>75%</div>
                            <div className='items profit-green' onClick={() => sellLimitPercentage(100)}>100%</div>
                        </div>
                        <hr></hr>
                    </div>

                    <div className='input_price input-green'>
                        <span style={{ color: "rgb(132, 142, 156)" }}>Total</span>
                        <input type='text' value={usdAmount} onChange={(e: any) => { totalAmount(e.target.value, setusdAmount) }} />
                        <span>USDT</span>
                    </div>

                    <div className='buy-btn'>
                        <Button variant='primary' className='profit-btn' onClick={BuyCoin} disabled={btnDisable}>
                            {btnDisable === true &&
                                <div className="color-ring">
                                    <ClipLoader color="#36d7b7" />

                                    Please Wait...
                                </div>
                            }
                            {btnDisable === false ? 'Buy' : ''}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyLimit